<template>
    <!-- Single Blog page layout -->
    <blog-template
        v-if="blog"
        :key="blogKey">
        <template
            #blogTitle>
            {{ blog.title }}
        </template>
        <template
            #blogSubtitle
            v-if="notEmpty(blog.subtitle)">
            {{ blog.subtitle }}
        </template>

        <template #blogImage>
            <img
                :alt="blog.image.alternativeText"
                :src="strapiApiUrl + blog.image.url">
        </template>

        <template
            #blogAuthor
            v-if="notEmpty(blog.author)">
            {{ blog.author.name }}
        </template>

        <template #blogDate>
            {{ $date(blog.published).format('YYYY-MM-DD') }}
        </template>

        <!-- Main Blog Content -->
        <template #blogContent>
            <span
                class="is-draft"
                v-if="!blog.published_at">
                DRAFT
            </span>
            <vue-markdown-plus
                :anchor-attributes="{
                    target:'_blank'
                }"
                :scr="strapiApiUrl">
                {{ blog.body }}
            </vue-markdown-plus>
        </template>

        <!-- Author Bio -->
        <template
            #authorBio
            v-if="notEmpty(blog.author)">
            <author-bio>
                <template #authorImage>
                    <img
                        alt="Author photo"
                        class="is-rounded"
                        :src="strapiApiUrl + blog.author.image.url">
                </template>

                <template
                    #authorName>
                    {{ blog.author.name }}
                </template>

                <template #authorTagline>
                    {{ blog.author.tagline }}
                </template>

                <template #authorBlurb>
                    <vue-markdown-plus>
                        {{ blog.author.bio }}
                    </vue-markdown-plus>
                </template>

                <template #authorLinks>
                    <a
                        v-for="social in blog.author.social"
                        :key="social.icon"
                        :href="social.link"
                        :aria-label="social.icon"
                        target="_blank">
                        <i
                            class="fab"
                            :class="'fa-'+ social.icon" />
                    </a>
                </template>
            </author-bio>
        </template>
    </blog-template>
</template>

<script>
import BlogTemplate from '../components/blogTemplate.vue';
import authorBio from '../components/authorBio.vue';
import VueMarkdownPlus from 'vue-markdown-plus';

export default {
    name: 'BlogEntry',
    components: {
        'vue-markdown-plus': VueMarkdownPlus,
        BlogTemplate,
        authorBio
    },
    data() {
        return {
            blogKey: 0,
            routeParam: this.$route.params.id
        };
    },
    metaInfo() {
        return {
            // title will be appended into the titleTemplate in App.vue
            title: this.pageTitle,
            titleTemplate: 'PeBL Blog | %s'
        };
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        blog: {
            get: function() {
                var blogs = this.$store.state.blogs;
                var blogId = this.$route.params.id;
                // get blog by id
                var blog = blogs.filter(function(elem) {
                    if (elem.url === blogId) return elem;
                })[0];
                if (blog) {
                    // console.log(blog.body);
                    // Fix relative URLs for images
                    blog.body = blog.body.split('(/uploads/').join(`(${this.strapiApiUrl}/uploads/`);
                }
                return blog;
            }
        },
        pageTitle() {
            return this.blog.title;
        }
    },
    // watch: {
    //     blog: function(newBlog, oldBlog) {
    //         this.pageTitle = this.blog.title;
    //     }
    // },
    mounted() {
        if (this.$store.state.blogs.length < 1) this.$store.dispatch('getBlogs');
    },
    methods: {
        notEmpty(data) {
            if (!data) return false;
            return true;
        }
    }
};
</script>

