<template>
    <nav
        class="navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation">
        <alert-bar
            :logged-in="loggedIn" />

        <div class="container">
            <div class="navbar-brand">
                <router-link
                    class="navbar-item"
                    :to="{name:'Home'}">
                    <img
                        aria-label="PeBL Logo"
                        src="@/assets/PEBL-logo-50px.png">
                </router-link>
                <a
                    role="button"
                    class="navbar-burger"
                    :class="{ 'is-active': showMobileMenu}"
                    @click="showMobileMenu = !showMobileMenu"
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="peblNavbar">
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                </a>
            </div>

            <div
                id="peblNavbar"
                class="navbar-menu"
                :class="{ 'is-active': showMobileMenu}">
                <div class="navbar-start">
                    <router-link
                        :to="{name:'Features'}"
                        class="navbar-item"
                        :class="{'is-active':$route.path === '/features'}">
                        Features
                    </router-link>
                    <router-link
                        :to="{name:'Pricing'}"
                        class="navbar-item"
                        :class="{'is-active':$route.path === '/pricing'}">
                        Pricing
                    </router-link>

                    <div
                        class="navbar-item has-dropdown is-hoverable"
                        :key="$route.path"
                        tabindex="0">
                        <span
                            class="navbar-link flex">
                            Resources
                        </span>
                        <div
                            id="resouresDropdown"
                            class="navbar-dropdown is-mega">
                            <div class="columns is-desktop has-dividers">
                                <div class="column">
                                    <h5 class="title is-5 is-hidden-touch">
                                        Getting Started
                                    </h5>
                                    <router-link
                                        :to="{name:'Training'}"
                                        class="is-block">
                                        <h6 class="title is-6">
                                            Training
                                        </h6>
                                        <p class="is-hidden-touch">
                                            Learn how to take advantage of
                                            everything PeBL Pro has to offer
                                        </p>
                                    </router-link>
                                    <router-link
                                        :to="{name:'Trial Setup'}"
                                        class="is-block">
                                        <h6 class="title is-6">
                                            Free Trial
                                        </h6>
                                        <p class="is-hidden-touch">
                                            Try PeBL Pro for your yourself,
                                            free for 30 days
                                        </p>
                                    </router-link>
                                </div>
                                <div class="column">
                                    <h5 class="title is-5 is-hidden-touch">
                                        Inspiration
                                    </h5>
                                    <a
                                        href="https://demos.pebl.pro/"
                                        target="_blank"
                                        class="is-block">
                                        <h6 class="title is-6">
                                            Demos Library
                                        </h6>
                                        <p class="is-hidden-touch">
                                            See what others have created using PeBL Pro
                                        </p>
                                    </a>
                                    <router-link
                                        :to="{name:'Blog List'}"
                                        class="is-block">
                                        <h6 class="title is-6">
                                            Blog
                                        </h6>
                                        <p class="is-hidden-touch">
                                            Get in the know with news, tips,
                                            and instructional design ideas
                                        </p>
                                    </router-link>
                                </div>
                                <div class="column">
                                    <h5 class="title is-5 is-hidden-touch">
                                        Questions
                                    </h5>
                                    <router-link
                                        :to="{name:'FAQ'}"
                                        class="is-block">
                                        <h6 class="title is-6">
                                            FAQ
                                        </h6>
                                        <p class="is-hidden-touch">
                                            Frequently asked questions
                                        </p>
                                    </router-link>
                                    <a
                                        href="https://community.pebl.pro/"
                                        target="_blank"
                                        class="is-block">
                                        <h6 class="title is-6">
                                            Community
                                        </h6>
                                        <p class="is-hidden-touch">
                                            Get help from the PeBL Pro team
                                            and other PeBL Pro creators
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="navbar-end">
                    <!-- Logged out -->
                    <div
                        v-if="!loggedIn"
                        class="navbar-item is-hidden-touch">
                        <router-link
                            :to="{name:'Trial Setup'}"
                            class="button is-primary is-rounded">
                            Try it now
                        </router-link>
                    </div>
                    <router-link
                        v-if="!loggedIn"
                        :to="{name:'Start Screen'}"
                        class="navbar-item">
                        Login
                    </router-link>
                    <!-- Logged in -->
                    <div
                        v-if="loggedIn"
                        class="navbar-item">
                        <router-link
                            :to="{name: 'Start Screen'}"
                            class="button is-rounded"
                            :class="{'is-primary':$route.name != 'Start Screen'}">
                            <span class="icon">
                                <i class="fas fa-home" />
                            </span>
                            <span>Home</span>
                        </router-link>
                    </div>
                    <div
                        v-if="loggedIn"
                        class="navbar-item has-dropdown is-hoverable"
                        :key="$route.path">
                        <a class="navbar-link">
                            <i class="fas fa-user mr-2" /> Account
                        </a>

                        <div class="navbar-dropdown is-right">
                            <a
                                @click="navbarShowSection('account');"
                                class="navbar-item">
                                <span><i class="fa-fw fas fa-cog" /></span>
                                <span>Account Settings</span>
                            </a>
                            <a
                                @click="navbarShowSection('library');"
                                class="navbar-item">
                                <span>
                                    <i class="fa-fw fas fa-book" /></span>
                                <span>Manage Libraries</span>
                            </a>
                            <a
                                @click="navbarShowSection('learner');"
                                class="navbar-item">
                                <span>
                                    <i class="fa-fw fas fa-users" /></span>
                                <span>Manage Learners</span>
                            </a>
                            <div v-if="isAdmin">
                                <hr class="navbar-divider">
                                <a
                                    @click="navbarShowSection('admin');"
                                    class="navbar-item">
                                    <span>
                                        <i class="fa-fw fas fa-users-cog" /></span>
                                    <span>Subscription Administration</span>
                                </a>
                            </div>
                            <hr class="navbar-divider">
                            <a
                                @click="logout"
                                class="navbar-item">
                                <span><i class="fa-fw fas fa-sign-out-alt" /></span>
                                <span>Log out</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>

import {purchaseApi} from '../mixins/purchaseApi';
import alertBar from './alertBar.vue';
import EventBus from '@/mixins/event-bus';

export default {
    name: 'Navbar',
    mixins: [purchaseApi],
    components: {alertBar},
    data: () => {
        return {
            showMobileMenu: false
        };
    },
    computed: {
        loggedIn() {
            return this.$store.getters.getLoggedIn;
        },
        profileData() {
            return this.$store.getters.getCustomerProfile;
        },
        isAdmin() {
            return this.loggedIn && this.profileData.isAdmin;
        }
    },
    watch: {
        '$route'() {
            this.hideMobileMenu();
        }
    },
    methods: {
        hideMobileMenu: function() {
            this.showMobileMenu = false;
        },
        navbarShowSection: function(section) {
            if (section === 'admin') {
                this.$router.push({name: 'Subscription Administration'});
            } else if (this.$route.name === 'Account Settings') {
                // update current page
                EventBus.$emit('navbarShowSection', section);
            } else {
                // navigate to Account Settings and show section
                this.$router.push({name: 'Account Settings', params: {section: section}});
            }
        },
        logout: function() {
            this.$store.commit('setLoggedIn', false);
            this.$store.commit('setCustomerProfile', null);
            this.performExternalLogout(this.LOGOUT_REDIRECT_URL_HOME);
            // TODO May be able to uncomment this once logout is changed to a service
            // if (this.$route.meta.requiresAuth) {
            //     this.$router.push({name: 'Home'});
            // }
        }
    }
};
</script>

<style>
.navbar {
    outline:none;
}
</style>
