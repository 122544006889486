<template>
    <!--
    Example Usage:

        <faq-block
            :topic="'pricing'"
            :title="'Pricing Questions'" />

    'topic' string must match a 'faq_category' from Strapi
    'title' (optional) Overrides the default header text
    -->

    <div v-if="showFaqs">
        <p class="title">
            <span v-if="title">{{ title }}</span>
            <span v-else>Frequenly Asked Questions</span>
        </p>
        <div
            v-for="(faq,index) in filteredFaqs"
            :key="index +'_'+ faq.id"
            class="faq-block"
            :class="{'is-selected':expandedFaqs.includes(index +'_'+ faq.id),'is-draft':faq.published_at === null}">
            <label
                class="faq-question"
                :for="index +'_'+ faq.id">
                <i
                    v-if="expandedFaqs.includes(index +'_'+ faq.id)"
                    class="fa-fw fas fa-caret-down" />
                <i
                    v-else
                    class="fa-fw fas fa-caret-right" />
                {{ faq.question }}
            </label>
            <input
                v-model="expandedFaqs"
                :value="index +'_'+ faq.id"
                class="faq-question-toggle"
                type="checkbox"
                :id="index +'_'+ faq.id">
            <div class="faq-answer content ml-5">
                <vue-markdown-plus
                    :anchor-attributes="{
                        target:'_blank'
                    }"
                    :scr="strapiApiUrl">
                    {{ faq.answer }}
                </vue-markdown-plus>
            </div>
        </div>
    </div>
</template>


<script>
import VueMarkdownPlus from 'vue-markdown-plus';

export default {
    name: 'FaqSection',
    components: {
        'vue-markdown-plus': VueMarkdownPlus
    },
    props: ['topic', 'title'],
    data: () => {
        return {
            expandedFaqs: []
        };
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        filteredFaqs: {
            get: function() {
                var filteredFaqs = [];
                var faqs = this.$store.state.faqs;
                var topic = this.topic.toLowerCase().trim();

                faqs.forEach((faq) => {
                    if (faq.faq_categories) {
                        if (faq.faq_categories.length > 0) {
                            faq.faq_categories.forEach(obj => {
                                if (topic === obj.category.toLowerCase().trim()) {
                                    filteredFaqs.push(faq);
                                }
                            });
                        }
                    }
                });
                return filteredFaqs;
            }
        },
        showFaqs() {
            return this.filteredFaqs.length > 0;
        }
    },
    mounted() {
        if (this.$store.state.faqs.length < 1) this.$store.dispatch('getFaqs');
    }
};
</script>


