<template>
    <section class="hero has-background has-background-light is-link is-fullheight-with-navbar">
        <img
            class="hero-background hero-fit-image is-ghost"
            src="../assets/success-group.png">

        <div class="hero-body">
            <div class="container">
                <div class="columns is-mobile is-centered">
                    <div class="column is-half-desktop is-two-thirds-tablet">
                        <div class="box p-6">
                            <div class="content has-text-centered">
                                <h3 class="title is-size-2 has-text-centered has-text-dark">
                                    Thank you for subscribing!
                                </h3>
                                <p class="subtitle has-text-grey-darker">
                                    Your subscription has been confirmed.
                                </p>

                                <router-link
                                    class="button is-rounded is-danger"
                                    :to="{name:'Home'}">
                                    Go to PeBL Pro website
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

};
</script>
