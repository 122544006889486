<template>
    <section
        v-if="showInteractions"
        id="interactions"
        class="hero is-medium is-dark">
        <div class="container">
            <div class="hero-body">
                <p class="title has-text-centered">
                    <slot name="title">
                        Interactions
                    </slot>
                </p>
                <div class="columns is-mobile is-multiline is-centered is-gapless">
                    <div
                        v-for="(interaction,index) in interactions"
                        :key="index"
                        class="column is-one-third-desktop is-half-tablet is-full-mobile">
                        <interaction-block :id="interaction.id" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InteractionBlock from './interactionBlock.vue';

export default {
    name: 'InteractionHeroSection',
    components: {
        InteractionBlock
    },
    data: () => {
        return {
            interactions: []
        };
    },
    computed: {
        showInteractions() {
            return this.interactions.length > 0;
        }
    },
    watch: {
        // refresh if data loads
        '$store.state.interactionDocs': function() {
            this.init();
        }
    },
    mounted() {
        // get strapi data as needed
        if (this.$store.state.interactionDocs.length < 1) {
            this.$store.dispatch('getInteractionDocs');
        }
        this.init();
    },
    methods: {
        init() {
            if (this.interactions.length < 1) {
                this.interactions = this.$store.state.interactionDocs;
            }
        }
    }
};
</script>

