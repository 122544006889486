<template>
    <div id="blog">
        <script type="application/ld+json">
            {{ jsonld }}
        </script>
        <!-- <breadcrumbs /> -->
        <header>
            <div class="container">
                <div class="columns is-desktop">
                    <div class="column is-8 is-offset-2 has-text-centered">
                        <figure class="image">
                            <slot name="blogImage" />
                        </figure>
                    </div>
                </div>
            </div>

            <div class="mx-4">
                <div class="container">
                    <div class="columns is-mobile is-centered">
                        <div class="column column is-fullwidth-mobile is-three-quarters-tablet is-three-fifths-desktop is-half-widescreen is-half-fullhd">
                            <h1 class="title">
                                <slot name="blogTitle" />
                            </h1>
                            <h3 class="subtitle">
                                <slot name="blogSubtitle" />
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <section
            role="main">
            <div class="m-4">
                <div class="container blog-body content">
                    <div class="columns is-mobile is-centered">
                        <div class="column column is-fullwidth-mobile is-three-quarters-tablet is-three-fifths-desktop is-half-widescreen is-half-fullhd">
                            <p class="is-size-7">
                                <slot name="blogAuthor" /> | <slot name="blogDate" />
                            </p>
                            <slot name="blogContent" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="has-background-light is-fullwidth">
                <div class="container">
                    <div class="columns is-mobile is-centered">
                        <div class="column column is-fullwidth-mobile is-three-quarters-tablet is-three-fifths-desktop is-half-widescreen is-half-fullhd">
                            <slot name="authorBio" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// import breadcrumbs from '@/components/breadcrumbs.vue';

export default {
    components: {
        // breadcrumbs
    },
    data() {
        return {

        };
    },
    computed: {
        jsonld() {
            let obj = '';
            obj = `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                    "@type": "www.pebl.pro",
                    "@id": "https://google.com/article"
                },
                "headline": "${this.$slots.blogTitle[0].text}",
                "image": [
                    "${this.$slots.blogImage[0].data.attrs.src.toString()}"
                ],
                "datePublished": "${this.$slots.blogDate[0].text}",
                "author": {
                    "@type": "Person",
                    "name": "${this.$slots.blogAuthor[0].text}"
                },
                "publisher": {
                    "@type": "Organization",
                    "name": "PeBL Pro",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "${this.$slots.blogImage[0].data.attrs.src.toString()}"
                    }
                }
            }`;
            return obj;
        }
    }
};
</script>
