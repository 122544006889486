// Pass events between distant components

// Add mixin to both sender and reciever:
// import EventBus from '@/mixins/event-bus';

// Send
// EventBus.$emit('myEvent', true);

// Recieve inside the component's Mounted function:
// EventBus.$on('myEvent', payload => {
//     this.doSomething(payload);
// });

import Vue from 'vue';
// export const EventBus = new Vue();
const EventBus = new Vue();
export default EventBus;
