import Vue from 'vue';
import dayjs from 'dayjs';
// import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// dayjs.extend(duration);
dayjs.extend(relativeTime);

// NOTE: Setting timezones did not work as expected,
// possibly due to the server's own timezone and how Strapi stores date-times.
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault('America/Los_Angeles');

Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs;
        }
    }
});
