<template>
    <div role="main">
        <section
            id="priceTable"
            class="my-6">
            <div class="container has-text-centered">
                <div class="columns is-centered">
                    <div class="column is-6 is-flex is-flex-direction-column">
                        <article class="message is-success mt-3">
                            <div class="message-header">
                                <p><i class="fas fa-exclamation-triangle" /> Checkout Success</p>
                            </div>
                            <div class="message-body">
                                Your PeBL Pro subscription should available in your Account Settings momentarily.
                                Don't forget to finish setting it up.
                            </div>
                        </article>
                        <div class="columns is-centered">
                            <div class="column is-6 is-flex is-flex-direction-column">
                                <button
                                    class="button"
                                    type="submit"
                                    @click="goToAccountSettings">
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import {purchaseApi} from '../mixins/purchaseApi';

export default {
    name: 'CheckoutSuccess',
    mixins: [purchaseApi],
    data: () => {
        return {
        };
    },
    metaInfo: {
        title: 'Checkout Success'
    },
    mounted: async function() {
        // reconnect customer profile.  Vuex is getting emptied on a return
        const profile = await this.getSimpleUserProfileFromApi();
        if (profile) {
            this.$store.commit('setLoggedIn', true);
            this.$store.commit('setCustomerProfile', profile);
            // this.$router.push({name: 'Checkout Success'});
        } else {
            this.performExternalLogin(this.LOGIN_REDIRECT_URL_CHECKOUT_SUCCESS);
        }
    },
    created() {
    },
    methods: {
        goToAccountSettings() {
            this.$router.push({name: 'Account Settings'});
        }
    }
};
</script>

<style scoped>

</style>
