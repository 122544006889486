<template>
    <div role="main">
        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <!-- Termly Tracking Code -->
                    <div
                        name="termly-embed"
                        data-id="3a2bed3f-628f-4ba5-8c27-5d5312fd1651"
                        data-type="iframe" />
                    <div id="termlyScriptContainer" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'CookiePolicy',
    data: () => {
        return {
            name: 'CookiePolicy'
        };
    },
    metaInfo: {
        title: 'Cookie Policy'
    },
    mounted: function() {
        const tscDiv = document.getElementById('termlyScriptContainer');
        const tscScript = document.createElement('script');
        tscScript.id = 'termlyScript';
        tscScript.type = 'text/javascript';
        tscDiv.appendChild(tscScript);
        const js = document.createElement('script');
        js.id = 'termly-jssdk';
        js.src = 'https://app.termly.io/embed-policy.min.js';
        document.head.appendChild(js);
    }
};
</script>

<style scoped>

</style>
