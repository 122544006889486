<template>
    <!-- Note: each 'level-item' that is a direct child of 'level' will center horizontally -->
    <!-- https://bulma.io/documentation/layout/level/ -->

    <transition name="fade">
        <div
            id="alertBar"
            v-if="showAlerts && showTrialDaysAlert"
            class="notification"
            :class="alertColor">
            <div class="container">
                <!-- TRIAL expires soon -->
                <div
                    v-if="showTrialDaysAlert"
                    class="level">
                    <div class="level-item">
                        <p class="mr-3">
                            <i class="fas fa-crown" />
                            Your trial ends in <span
                                class="big-text animate-text effect-grow"
                                style="--animation-delay: 1s; --animation-speed: 1s;"
                                :animate="numDaysLeftOnTrial">{{ numDaysLeftOnTrial }}</span>
                            <span v-if="numDaysLeftOnTrial === 1"> day</span>
                            <span v-else> days</span>
                        </p>
                        <button
                            @click="
                                showTrialDaysAlert = false;
                                goToRoute('Pricing');"
                            class="button is-light is-outlined">
                            <span class="icon"><i class="fas fa-hand-point-right" /></span>
                            <span>Get PeBL Pro</span>
                        </button>
                    </div>
                    <button
                        class="delete"
                        title="Got it! Close this."
                        @click="showTrialDaysAlert = false" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
// TODO: Randomized trial messages based on days left: 15, 10, 5-1, 0?

export default {
    name: 'AlertBar',
    props: ['loggedIn'],
    components: {},
    data: () => {
        return {
            showAlerts: false, // master off switch
            showTrialDaysAlert: false,
            // change alertcolor as needed:
            // 'is-warning' (yellow), 'is-danger' (red), 'is-success' (green), 'is-info' (blue)
            alertColor: 'is-warning'
        };
    },
    computed: {
        numDaysLeftOnTrial: function() {
            // TODO: Get trial days
            return 4;
        }
    },
    mounted() {
        if (this.loggedIn &&
            this.numDaysLeftOnTrial >= 1 &&
            this.numDaysLeftOnTrial <= 10
        ) {
            this.showTrialDaysAlert = true;
            this.showAlerts = true;
        }
    },
    methods: {
        goToRoute: function(route) {
            this.$router.push({name: route});
        }
    }
};
</script>
