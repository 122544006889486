<template>
    <!-- Single blog preview block.
    Requires a Strapi blog ID, other props are optional -->

    <div
        v-if="showBlog"
        class="blog-preview-block link-box"
        :class="{'featured-blog': featured}">
        <router-link
            :to="{ path: '/blog/' + blog.url }"
            class="blog">
            <div class="blog-image-container">
                <div
                    class="blog-image"
                    :style="{ 'background-image': 'url('+ image +')'}" />
            </div>
            <p
                class="link-title"
                :class="{'is-draft':blog.published_at === null}">
                <span>{{ blog.title }}</span>
            </p>

            <div
                v-if="showFooter"
                class="blog-footer">
                <p
                    v-if="showSubtitle"
                    class="mt-2">
                    {{ blog.subtitle }}
                </p>
                <p
                    v-if="showBlurb"
                    class="mt-2">
                    {{ blurb }}
                </p>
                <div
                    v-if="showAuthor && blog.author"
                    class="blog-author-block">
                    {{ blog.author.name }}
                </div>
                <a class="link read-blog-link">Read now</a>
            </div>
        </router-link>
    </div>
</template>


<script>

export default {
    name: 'BlogBlock',
    props: {
        id: {type: Number, required: true},
        featured: Boolean,
        showSubtitle: Boolean,
        showFooter: Boolean,
        showBlurb: Boolean,
        showAuthor: Boolean
    },
    components: {
    },
    data: () => {
        return {
        };
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        showBlog() {
            if (this.$store.state.blogs.length > 0 && this.blog) return true;
            return false;
        },
        blog() {
            if (this.$store.state.blogs.length < 1) return false;
            return this.$store.state.blogs.filter(obj => {
                return obj.id === this.id;
            })[0];
        },
        image() {
            // Get smaller image if available
            if (this.blog.image.formats && this.blog.image.formats.small) {
                return this.strapiApiUrl + this.blog.image.formats.small.url;
            }
            return this.strapiApiUrl + this.blog.image.url;
        },
        blurb() {
            const wordCount = 25;
            if (this.blog.subtitle) return this.blog.subtitle;

            var blurb = this.blog.body;
            blurb = blurb.replaceAll('*', '');
            blurb = blurb.replaceAll('#', '');
            blurb = blurb.split(/\s+/).slice(0, wordCount).join(' ') + '...';
            return blurb;
        }
    },
    mounted() {
        if (this.$store.state.blogs.length < 1) this.$store.dispatch('getBlogs');
    }
    // methods: {
    //     loadBlogs: function() {
    //     }
    // }
};
</script>


