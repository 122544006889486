<template>
    <section class="hero has-background has-background-light is-link is-fullheight-with-navbar">
        <img
            class="hero-background hero-fit-image hero-image-left is-ghost"
            :src="getImgUrl(splashImage1)"
            :key="splashImage1">
        <img
            class="hero-background hero-fit-image hero-image-right is-ghost"
            :src="getImgUrl(splashImage2)"
            :key="splashImage2">

        <div class="hero-body">
            <div class="container">
                <div class="columns is-mobile is-centered">
                    <div class="column is-half-desktop is-two-thirds-tablet">
                        <div class="box">
                            <div class="content has-text-centered">
                                <h3 class="title is-size-2 has-text-centered has-text-dark">
                                    Join the evolution
                                </h3>
                                <!-- <p class="subtitle has-text-grey-darker">
                                    Tell us what you want
                                </p> -->

                                <!-- Begin Mailchimp Signup Form -->
                                <div class="columns is-mobile is-centered">
                                    <div class="column is-half-tablet">
                                        <div id="mc_embed_signup">
                                            <form
                                                action="https://peblsolutions.us3.list-manage.com/subscribe/post?u=2a159a265677cf0b1196402da&amp;id=254a1fd68a"
                                                method="post"
                                                id="mc-embedded-subscribe-form"
                                                name="mc-embedded-subscribe-form"
                                                class="validate has-text-left"
                                                target="_blank"
                                                novalidate>
                                                <div id="mc_embed_signup_scroll">
                                                    <div class="field mc-field-group">
                                                        <label
                                                            for="mce-EMAIL"
                                                            class="label">
                                                            Email Address
                                                        </label>
                                                        <div class="control is-expanded has-icons-left">
                                                            <input
                                                                type="email"
                                                                placeholder=""
                                                                name="EMAIL"
                                                                value=""
                                                                class="input required email"
                                                                id="mce-EMAIL">
                                                            <span class="icon is-small is-left">
                                                                <i class="fas fa-envelope" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="field mc-field-group">
                                                        <label
                                                            for="mce-FNAME"
                                                            class="label">
                                                            First Name
                                                        </label>
                                                        <div class="control">
                                                            <input
                                                                type="text"
                                                                value=""
                                                                name="FNAME"
                                                                class="input"
                                                                id="mce-FNAME">
                                                        </div>
                                                    </div>
                                                    <div class="field mc-field-group">
                                                        <label
                                                            for="mce-LNAME"
                                                            class="label">
                                                            Last Name
                                                        </label>
                                                        <div class="control">
                                                            <input
                                                                type="text"
                                                                value=""
                                                                name="LNAME"
                                                                class="input"
                                                                id="mce-LNAME">
                                                        </div>
                                                    </div>
                                                    <div
                                                        id="mce-responses"
                                                        class="clear">
                                                        <div
                                                            class="response has-text-danger"
                                                            id="mce-error-response"
                                                            style="display:none" />
                                                        <div
                                                            class="response has-text-dark"
                                                            id="mce-success-response"
                                                            style="display:none" />
                                                    </div>
                                                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                                    <div
                                                        style="position: absolute; left: -5000px;"
                                                        aria-hidden="true">
                                                        <input
                                                            type="text"
                                                            name="b_2a159a265677cf0b1196402da_254a1fd68a"
                                                            tabindex="-1"
                                                            value="">
                                                    </div>
                                                    <!-- <p class="subtitle has-text-grey-darker has-text-centered">
                                                        Tell us what you want.
                                                    </p> -->
                                                    <div class="clear has-text-centered">
                                                        <input
                                                            type="submit"
                                                            value="Join in!"
                                                            name="subscribe"
                                                            id="mc-embedded-subscribe"
                                                            class="button is-link is-rounded">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--End mc_embed_signup-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'NewsletterSignup',
    data: () => {
        return {
            name: 'NewsletterSignup',
            splashImage1: null,
            splashImage2: null
        };
    },
    metaInfo: {
        title: 'Join the evolution'
    },
    mounted() {
        var splash1 = Math.floor(Math.random() * 9) + 1;
        var splash2 = null;
        do {
            splash2 = Math.floor(Math.random() * 9) + 1;
        }
        while (splash1 === splash2);
        this.splashImage1 = 'splash-image-' + splash1 + '.png';
        this.splashImage2 = 'splash-image-' + splash2 + '.png';
    },
    methods: {
        getImgUrl(pic) {
            if (!pic) return;
            return require('../assets/' + pic);
        }
    }
};
</script>

<style scoped>

</style>
