import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import VueAnalytics from 'vue-analytics';
import VueGtag from 'vue-gtag';
import '@/styles.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import VueTypedJs from 'vue-typed-js';
import '@/plugins/Dayjs';
import store from './store';
import Zendesk from '@dansmaculotte/vue-zendesk';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';

Vue.config.productionTip = false;
Vue.use(VueTypedJs);
const isProd = process.env.NODE_ENV === 'production';

Vue.use(VueGtag, {
    config: {
        id: 'UA-181728025-3',
        params: {
            sendPageView: true
        },
        pageTrackerScreenviewEnabled: true,
        appName: 'PeBL Pro Website'
    },
    includes: [
        {
            id: 'UA-181728025-5',
            params: {
                sendPageView: true
            },
            pageTrackerScreenviewEnabled: true,
            appName: 'PeBL Pro All'

        }
    ]
}, router);

// https://github.com/dansmaculotte/vue-zendesk#readme
// settings - https://developer.zendesk.com/embeddables/docs/widget/settings
Vue.use(Zendesk, {
    key: 'a2364dea-4458-4ea5-9475-5168d2dda258',
    disabled: false,
    hideOnLoad: true,
    settings: {
        webWidget: {
            color: {
                theme: '#bb4b51',
                launcher: '#ffffff',
                launcherText: '#bb4b51'
            }
        }
    }
});

Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline);

new Vue({
    store,
    router: router,
    render: h => h(App)
}).$mount('#app');
