<template>
    <div
        id="features"
        role="main">
        <!-- Google doc: https://docs.google.com/document/d/1I4s4-EbtpP8OOYE9Hs5DfO-PFBXSAM0d3-MjRyKRWNs/edit -->
        <!-- Images source: https://drive.google.com/file/d/1TeDP7aSPQPs5Fb4O69elpYrKCb2akOeZ/view?usp=sharing -->

        <!-- Inspiration samples: -->
        <!-- https://frontapp.com/why-front -->
        <!-- https://learn.g2.com/website-features -->
        <!-- https://saaswebsites.com/page-categories/features-page/ -->

        <!-- <section class="hero is-medium">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <h1 class="title">
                        Features
                    </h1>
                    <p class="subtitle">
                        Designed for results
                    </p>
                </div>
            </div>
        </section> -->

        <section
            v-for="(feature, index) in mainFeatures"
            :key="index"
            class="hero is-medium"
            :class="{'is-light':index % 2 != 0}">
            <div class="hero-body">
                <div class="container">
                    <h3 class="title is-3 pb-4 mb-6 has-text-centered">
                        {{ feature.title }}
                    </h3>
                    <div class="columns is-mobile is-centered">
                        <!-- image on Left if even section -->
                        <div
                            v-if="index % 2 != 0"
                            class="column is-one-third-desktop is-hidden-touch">
                            <div
                                v-if="feature.topics[feature.activeTopic].url != ''"
                                class="has-text-centered">
                                <!-- topic has a link -->
                                <optional-router-link
                                    :disabled="feature.topics[feature.activeTopic].url == ''"
                                    :to="{path: '/features/' + feature.topics[feature.activeTopic].url}">
                                    <img
                                        class="featured-image"
                                        :src="getImgUrl(feature.topics[feature.activeTopic].image)">
                                </optional-router-link>
                                <optional-router-link
                                    :disabled="feature.topics[feature.activeTopic].url == ''"
                                    :to="{path: '/features/' + feature.topics[feature.activeTopic].url}">
                                    Click to learn more
                                </optional-router-link>
                            </div>
                            <div v-else>
                                <!-- topic has no link, image only -->
                                <img
                                    class="featured-image"
                                    :src="getImgUrl(feature.topics[feature.activeTopic].image)">
                            </div>
                        </div>

                        <!-- Topic info  -->
                        <div class="column is-one-third-desktop is-two-thirds-tablet">
                            <aside class="menu">
                                <ul class="menu-list">
                                    <li
                                        v-for="(topic,index2) in feature.topics"
                                        :key="index2">
                                        <a
                                            @click="feature.activeTopic = index2"
                                            class="topic-content"
                                            :class="{'is-active':feature.activeTopic === index2,
                                                     'is-even-block':index % 2 != 0}">
                                            <div>
                                                <p class="topic-title">
                                                    {{ topic.title }}
                                                    <span class="menu-icon">
                                                        <i class="fa-fw fas fa-angle-down" />
                                                    </span>
                                                </p>
                                                <img
                                                    class="featured-image is-hidden-desktop"
                                                    :src="getImgUrl(topic.image)">

                                                <p class="topic-description">
                                                    {{ topic.description }}
                                                    <optional-router-link
                                                        v-if="feature.topics[feature.activeTopic].url != ''"
                                                        class="link is-hidden-desktop"
                                                        :to="{path: '/features/' + feature.topics[feature.activeTopic].url}">
                                                        Learn more
                                                    </optional-router-link>
                                                </p>
                                            </div>

                                        </a>
                                    </li>
                                </ul>
                            </aside>
                        </div>

                        <!-- image on Right for Odd numbered sections -->
                        <div
                            v-if="index % 2 == 0"
                            class="column is-one-third-desktop is-hidden-touch">
                            <div
                                v-if="feature.topics[feature.activeTopic].url != ''"
                                class="has-text-centered">
                                <!-- topic has a link -->
                                <optional-router-link
                                    :disabled="feature.topics[feature.activeTopic].url == ''"
                                    :to="{path: '/features/' + feature.topics[feature.activeTopic].url}">
                                    <img
                                        class="featured-image"
                                        :src="getImgUrl(feature.topics[feature.activeTopic].image)">
                                </optional-router-link>
                                <optional-router-link
                                    :disabled="feature.topics[feature.activeTopic].url == ''"
                                    :to="{path: '/features/' + feature.topics[feature.activeTopic].url}">
                                    Click to learn more
                                </optional-router-link>
                            </div>
                            <div v-else>
                                <!-- topic has no link, image only -->
                                <img
                                    class="featured-image"
                                    :src="getImgUrl(feature.topics[feature.activeTopic].image)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="hero is-medium is-black has-text-light has-spotlights">
            <div
                class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-two-thirds has-text-centered">
                            <h3 class="title has-text-shadow">
                                Feature Spotlights
                            </h3>
                            <p>
                                <router-link
                                    v-for="feature in features"
                                    :key="feature.id"
                                    :to="{ path: '/features/' + feature.url }"
                                    class="feature-list-item">
                                    {{ feature.name }}
                                </router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import OptionalRouterLink from '../components/optionalRouterLink.vue';

export default {
    name: 'Features',
    components: {
        OptionalRouterLink
    },
    data: () => {
        return {
            mainFeatures: [
                {
                    title: 'What do you want in an authoring tool?',
                    activeTopic: 0,
                    topics: [
                        {
                            title: 'Social Interactions',
                            description: 'Create discussions, polls, and feedback right in the learning experience. It\'s about time.',
                            image: 'features_social interactions.png',
                            url: 'social-learning'
                        },
                        {
                            title: 'Single-Source Authoring',
                            description: 'Why choose between authoring that\'s easy and authoring that\'s powerful? Responsive content from a single source is one way PeBL Pro gives you both.',
                            image: 'features_why not both.png',
                            url: 'single-source-authoring'
                        },
                        {
                            title: 'Learner Practice',
                            description: 'Want to help your learners do more than recall facts? PeBL Pro helps you facilitate learner practice... and feedback.',
                            image: 'features_learner practice.png',
                            url: 'learner-practice'
                        }
                    ]
                },
                {
                    title: 'Where is your content coming from?',
                    activeTopic: 0,
                    topics: [
                        {
                            title: 'HTML and SCORM',
                            description: 'Easily import from HTML and SCORM courses created in other authoring tools.',
                            image: 'html_scorm.png',
                            url: 'import'
                        },
                        {
                            title: 'Word or Google Docs',
                            description: 'Storyboard and collaborate in your favorite tools, move to PeBL Pro in a flash.',
                            image: 'word_google_docs.png',
                            url: 'import'
                        },
                        {
                            title: 'EPUBs',
                            description: 'Add interactivity and tracking to any existing EPUB.',
                            image: 'epubs.png',
                            url: 'import'
                        }
                    ]
                },
                {
                    title: 'What’s essential in delivering your learning experiences?',
                    activeTopic: 0,
                    topics: [
                        {
                            title: 'Responsive Content',
                            description: 'Deliver responsive content on any device with a modern browser.',
                            image: 'features_responsive content.png',
                            url: 'responsive-mobile-delivery'
                        },
                        {
                            title: 'xAPI and SCORM',
                            description: 'Produce learning experiences that report to SCORM and emit xAPI at the same time... no coding necessary.',
                            image: 'features_xapi scorm.png',
                            url: 'scorm'
                        },
                        {
                            title: 'Admin Dashboards',
                            description: 'Moderate social interactions and monitor learner activity.',
                            image: 'features_learner dashboards.png',
                            url: 'moderation-and-activity-dashboards'
                        }
                    ]
                }
            ]
        };
    },
    metaInfo: {
        title: 'Features'
    },
    computed: {
        features() {
            return this.$store.state.featureSpotlights;
        }
    },
    mounted() {
        if (this.$store.state.featureSpotlights.length < 1) this.$store.dispatch('getFeatureSpotlights');
    },
    watch: {
        // '$store.state.featureSpotlights': function() {
        // }
    },
    methods: {
        notEmpty(data) {
            // check if optional strapi data item has a value
            if (!data) return false;
            return true;
        },
        getImgUrl(pic) {
            if (!pic) return;
            return require('../assets/' + pic);
        }
    }
};
</script>

<style scoped>

</style>
