import Vue from 'vue';
import Vuex from 'vuex';
import strapi from '../../api/strapi.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // Global values used by components
        strapiApiUrl: 'https://cms.pebl.pro',
        blogs: [],
        webinars: [],
        peblSamples: [],
        videos: [],
        faqs: [],
        events: [],
        featureSpotlights: [],
        interactionDocs: [],
        products: null,
        productsWithPrices: null,
        chosenProduct: {},
        trialAttributes: null,
        auth: {
            loggedIn: false,
            profile: null,
            route: null // Redirect after logging in
        }
    },
    getters: {
        // Getters are computed properties for the store state

        strapiApiUrl: state => {
            return state.strapiApiUrl;
        },
        blogs: state => {
            return state.blogs;
        },
        webinars: state => {
            return state.webinars;
        },
        peblSamples: state => {
            return state.peblSamples;
        },
        videos: state => {
            return state.videos;
        },
        faqs: state => {
            return state.faqs;
        },
        events: state => {
            return state.events;
        },
        featureSpotlights: state => {
            return state.featureSpotlights;
        },
        interactionDocs: state => {
            return state.interactionDocs;
        },

        getLoggedIn: state => {
            return state.auth.loggedIn;
        },
        getAfterLoginRoute: state => {
            return state.auth.route;
        },
        getCustomerProfile: state => {
            return state.auth.profile;
        },
        getProducts: state => {
            return state.products;
        },
        getProductsWithPrices: state => {
            return state.productsWithPrices;
        },
        getChosenProduct: state => {
            return state.chosenProduct;
        },
        getTrialAttributes: state => {
            return state.trialAttributes;
        }
    },
    mutations: {
        // Mutations are functions that changes the state
        // with a minimum amount of logic, must be synchroneous

        setBlogs(state, val) {
            state.blogs = val;
        },
        setWebinars(state, val) {
            state.webinars = val;
        },
        setPeblSamples(state, val) {
            state.peblSamples = val;
        },
        setVideos(state, val) {
            state.videos = val;
        },
        setFaqs(state, val) {
            state.faqs = val;
        },
        setEvents(state, val) {
            state.events = val;
        },
        setFeatureSpotlights(state, val) {
            state.featureSpotlights = val;
        },
        setInteractionDocs(state, val) {
            state.interactionDocs = val;
        },

        setLoggedIn(state, val) {
            state.auth.loggedIn = val;
        },
        setAfterLoginRoute(state, val) {
            state.auth.route = val;
        },
        setCustomerProfile(state, val) {
            state.auth.profile = val;
        },
        setProducts(state, val) {
            state.products = val;
        },
        setProductsWithPrices(state, val) {
            state.productsWithPrices = val;
        },
        setChosenProduct(state, val) {
            state.chosenProduct = val;
        },
        setTrialAttributes(state, val) {
            state.trialAttributes = val;
        }
    },
    actions: {
        // Actions set value in state (committing one or more mutations)
        // or set and get values using a rest api

        getBlogs({commit}) {
            var collection = 'blogs';
            var options = '_sort=published:DESC';
            strapi.getStrapiData(collection, options, function(data) {
                commit('setBlogs', JSON.parse(data));
            });
        },
        getWebinars({commit}) {
            var collection = 'webinars';
            // Get webinars with a date >= right now
            // and sort by date
            var today = encodeURI(new Date().toISOString());
            var options = 'date_gte=' + today;
            options += '&_sort=date:ASC';
            strapi.getStrapiData(collection, options, function(data) {
                commit('setWebinars', JSON.parse(data));
            });
        },
        getPeblSamples({commit}) {
            var collection = 'pe-bl-samples';
            var options = '_sort=updated_at:DESC';
            strapi.getStrapiData(collection, options, function(data) {
                commit('setPeblSamples', JSON.parse(data));
            });
        },
        getVideos({commit}) {
            var collection = 'training-videos';
            var options = '_sort=updated_at:DESC';
            strapi.getStrapiData(collection, options, function(data) {
                commit('setVideos', JSON.parse(data));
            });
        },
        getFaqs({commit, state}) {
            var collection = 'faqs';
            var options = '';
            strapi.getStrapiData(collection, options, function(data) {
                var faqs = JSON.parse(data);
                faqs.forEach((faq) => {
                    // Clean urls from rich text: 'answer'
                    if (faq.answer) faq.answer = faq.answer.split('(/uploads/').join('(' + state.strapiApiUrl + '/uploads/');
                });
                commit('setFaqs', faqs);
            });
        },
        getEvents({commit}) {
            var collection = 'events';
            var options = '_sort=date:DESC';
            strapi.getStrapiData(collection, options, function(data) {
                commit('setEvents', JSON.parse(data));
            });
        },
        getFeatureSpotlights({commit, state}) {
            var collection = 'features';
            var options = '';
            strapi.getStrapiData(collection, options, function(data) {
                var features = JSON.parse(data);
                features.forEach((feature) => {
                    // Clean urls from rich text: 'body'
                    feature.body = feature.body.split('(/uploads/').join('(' + state.strapiApiUrl + '/uploads/');
                });
                commit('setFeatureSpotlights', features);
            });
        },
        getInteractionDocs({commit, state}) {
            var collection = 'interactions';
            var options = '_sort=name:ASC';
            strapi.getStrapiData(collection, options, function(data) {
                var interactions = JSON.parse(data);
                interactions.forEach((interaction) => {
                    // Clean urls from rich text: 'body'
                    if (interaction.icon && interaction.icon.url) interaction.icon.url = state.strapiApiUrl + interaction.icon.url;
                    interaction.body = interaction.body.split('(/uploads/').join('(' + state.strapiApiUrl + '/uploads/');
                });
                commit('setInteractionDocs', interactions);
            });
        }
    },
    modules: {
    }
});
