<template>
    <div
        id="pricingScreen"
        role="main">
        <section
            v-if="amBusy"
            class="hero is-fullheight-with-navbar">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <i class="fa-fw fas fa-circle-notch fa-spin" /> Loading PeBL Pro product data...
                </div>
            </div>
        </section>

        <section
            v-if="!amBusy && !productListLoaded"
            class="hero is-fullheight-with-navbar">
            <div class="hero-body">
                <div class="container has-text-centered">
                    Unable to load product list
                </div>
            </div>
        </section>

        <div v-if="!amBusy && productListLoaded">
            <section class="hero">
                <div class="hero-body">
                    <div class="container has-text-centered">
                        <h1 class="title mb-6">
                            PeBL Pro Pricing Plans
                        </h1>

                        <!-- TODO pre-load product list when site loads, so it's cached and ready when users open this page -->
                        <!-- TODO Disable the "Subscribe" button on products the signed-in user cannot buy, add tooltip explaining why -->

                        <div class="columns is-centered">
                            <div
                                v-for="(product, index) in filteredMainProductList"
                                :key="product.productId"
                                class="column is-one-quarter">
                                <div
                                    class="box product-card"
                                    :class="{'has-coupon has-ribbon':product.hasCoupon}">
                                    <div
                                        v-if="product.hasCoupon"
                                        class="ribbon">
                                        {{ product.coupon.metadata['Ribbon Label'] || product.coupon.name }}
                                    </div>
                                    <div>
                                        <p class="product-name">
                                            {{ product.name }}
                                        </p>
                                        <div class="product-price">
                                            <span
                                                v-if="product.hasCoupon"
                                                class="product-price-amount"
                                                :class="{'has-text-warning':index === 0,'has-text-danger is-active':index === 1}">
                                                <span class="old-price"><span class="strikethrough"><span class="product-price-currency">$</span>{{ product.amount/100 }}</span></span>
                                                <span class="product-price-currency">$</span>{{ getPriceWithCouponForProduct(product) }}
                                            </span>
                                            <span
                                                v-else
                                                class="product-price-amount"
                                                :class="{'has-text-warning':index === 0,'has-text-danger is-active':index === 1}">
                                                <span class="product-price-currency">$</span>{{ product.amount/100 }}
                                            </span>
                                            <span v-if="product.priceType === 'recurring'">/{{ product.interval }}</span>
                                        </div>
                                        <p class="product-description">
                                            {{ product.description }}
                                        </p>
                                        <p
                                            v-if="product.hasCoupon"
                                            class="product-description">
                                            {{ product.coupon.metadata['Description'] }}
                                        </p>
                                    </div>

                                    <ul class="product-list">
                                        <li>
                                            Up to
                                            <span v-if="index === 0">{{ trialAttributes.numMaxLearners }}</span>
                                            <span v-else>{{ product[PRD_LEARNERS_METADATA_KEY] }}</span>
                                            learners
                                        </li>
                                        <li>
                                            <span v-if="index === 0">{{ trialAttributes.numMaxPebls }}</span>
                                            <span v-else>{{ product[PRD_NUM_PEBLS_METADATA_KEY] }}</span>
                                            published PeBLs
                                        </li>
                                    </ul>

                                    <div class="product-footer">
                                        <button
                                            v-if="product.amount/100 === 0"
                                            @click="goToTrialSetup"
                                            :disabled="disablePurchaseTrial"
                                            class="button is-warning">
                                            Start Your Trial
                                        </button>
                                        <button
                                            v-else
                                            @click="goToCheckout(product)"
                                            :disabled="disablePurchaseMain"
                                            class="button"
                                            :class="{'is-danger is-active':index === 1}">
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="columns is-centered">
                            <div class="column is-half has-text-centered">
                                <p class="block">
                                    <strong>All plans</strong> include the authoring studio, 1 library,
                                    1 subdomain at yoursubdomain.pebl.pro, and the moderation dashboard.
                                    PeBL Pro Advanced includes the activity dashboard.
                                    <br>More details below.
                                </p>
                                <p class="block">
                                    To upgrade your plan from Basic to Advanced, contact <a
                                        class="link"
                                        target="_blank"
                                        href="mailto:support@pebl.pro?subject=PeBL%20Pro%20Upgrade">support@pebl.pro</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="hero is-white">
                <div class="hero-body">
                    <div class="container">
                        <div class="columns is-centered">
                            <div class="column is-9">
                                <table class="product-table table is-fullwidth">
                                    <thead>
                                        <tr class="sticky">
                                            <th />
                                            <th
                                                v-for="product in filteredMainProductList"
                                                :key="product.productId">
                                                <p class="product-name">
                                                    {{ product.name }}
                                                </p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th />
                                            <th
                                                v-for="(product, index) in filteredMainProductList"
                                                :key="product.productId">
                                                <p class="product-name">
                                                    {{ product.name }}
                                                </p>
                                                <div class="product-price">
                                                    <span
                                                        v-if="product.hasCoupon"
                                                        class="product-price-amount"
                                                        :class="{'has-text-warning':index === 0,'has-text-danger is-active':index === 1}">
                                                        <span class="old-price"><span class="strikethrough"><span class="product-price-currency">$</span>{{ product.amount/100 }}</span></span>
                                                        <span class="product-price-currency">$</span>{{ getPriceWithCouponForProduct(product) }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="product-price-amount"
                                                        :class="{'has-text-warning':index === 0,'has-text-danger is-active':index === 1}">
                                                        <span class="product-price-currency">$</span>{{ product.amount/100 }}
                                                    </span>
                                                    <span v-if="product.priceType === 'recurring'">/{{ product.interval }}</span>
                                                </div>
                                                <button
                                                    v-if="product.amount/100 === 0"
                                                    @click="goToTrialSetup"
                                                    :disabled="disablePurchaseTrial"
                                                    class="button is-warning">
                                                    Start Your Trial
                                                </button>
                                                <button
                                                    v-else
                                                    @click="goToCheckout(product)"
                                                    :disabled="disablePurchaseMain"
                                                    class="button"
                                                    :class="{'is-danger is-active':index === 1}">
                                                    Subscribe
                                                </button>
                                            </th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr>
                                            <th>Learner access</th>
                                            <td
                                                v-for="product in filteredMainProductList"
                                                :key="product.productId">
                                                {{ product[PRD_LEARNERS_METADATA_KEY] }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Published PeBLs</th>
                                            <td
                                                v-for="product in filteredMainProductList"
                                                :key="product.productId">
                                                {{ product[PRD_NUM_PEBLS_METADATA_KEY] }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Media storage for authoring</th>
                                            <td
                                                v-for="product in filteredMainProductList"
                                                :key="product.productId">
                                                {{ product[PRD_AUTHORING_STORAGE_METADATA_KEY] }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Bandwidth for downloads</th>
                                            <td
                                                v-for="product in filteredMainProductList"
                                                :key="product.productId">
                                                {{ product[PRD_BANDWIDTH_METADATA_KEY] }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Unlimited PeBL projects in the authoring studio</th>
                                            <td
                                                v-for="product in filteredMainProductList"
                                                :key="product.productId">
                                                <i class="fas fa-check" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Connection to any LRS</th>
                                            <td
                                                v-for="product in filteredMainProductList"
                                                :key="product.productId">
                                                <i class="fas fa-check" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Connection to any LMS</th>
                                            <td
                                                v-for="product in filteredMainProductList"
                                                :key="product.productId">
                                                <i class="fas fa-check" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <router-link
                                                    :to="{ path: '/features/moderation-and-activity-dashboards'}"
                                                    class="link">
                                                    Moderation dashboard
                                                </router-link>
                                            </th>
                                            <td
                                                v-for="product in filteredMainProductList"
                                                :key="product.productId">
                                                <i class="fas fa-check" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <router-link
                                                    :to="{ path: '/features/moderation-and-activity-dashboards'}"
                                                    class="link">
                                                    Activity dashboard
                                                </router-link>
                                            </th>
                                            <td
                                                v-for="product in filteredMainProductList"
                                                :key="product.productId">
                                                <i
                                                    v-if="product[PRD_ACT_DASH_METADATA_KEY]"
                                                    class="fas fa-check has-text-danger" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                id="addons"
                class="hero is-white">
                <div class="hero-body">
                    <div class="container">
                        <div class="columns is-centered">
                            <div class="column is-half">
                                <div
                                    v-for="addOn in addOnProductsSimple"
                                    :key="addOn.productId"
                                    class="box">
                                    <!-- TODO: Make this block pull from Stripe data for products were add-on = true -->
                                    <router-link
                                        v-if="!loggedIn"
                                        :to="{name: 'Account Settings', params: { section: 'library' } }"
                                        class="link is-pulled-right">
                                        Login to purchase
                                    </router-link>
                                    <p class="product-name">
                                        {{ addOn.name }}
                                    </p>
                                    <div class="product-price">
                                        <span
                                            v-if="addOn.hasCoupon"
                                            class="product-price-amount">
                                            <span class="old-price"><span class="strikethrough"><span class="product-price-currency">$</span>{{ addOn.amount/100 }}</span></span>
                                            <!--<span class="strike pr-1"><span class="product-price-currency">$</span>{{ addOn.amount/100 }}</span>-->
                                            <span class="product-price-currency">$</span>{{ getPriceWithCouponForProduct(addOn) }}
                                        </span>
                                        <span
                                            v-else
                                            class="product-price-amount">
                                            <span class="product-price-currency">$</span>{{ addOn.amount/100 }}
                                        </span>
                                        <span v-if="addOn.priceType === 'recurring'">/library/{{ addOn.interval }}</span><span />
                                    </div>
                                    <p class="product-description">
                                        {{ addOn.description }}
                                    </p>
                                    <div class="product-footer">
                                        <span
                                            v-if="disablePurchaseAddon"
                                            class="">Available for paid subscriptions.
                                        </span>
                                        <button
                                            v-else
                                            class="button"
                                            @click="goToCheckout(addOn)"
                                            :disabled="disablePurchaseAddon">
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="column is-half"> -->
                        <p class="block has-text-centered">
                            Need more? Contact us at <a
                                class="link"
                                target="_blank"
                                href="mailto:hello@pebl.pro?subject=PeBL%20Pro%20Requirements">hello@pebl.pro</a>
                        </p>
                    <!-- </div> -->
                    </div>
                </div>
            </section>

            <section
                class="hero is-white">
                <div class="hero-body">
                    <div class="container">
                        <div class="columns is-centered">
                            <div class="column is-half">
                                <faq-block
                                    :topic="'pricing'"
                                    :title="'Frequently Asked Questions'" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import faqBlock from '../components/faqBlock.vue';
import {purchaseApi} from '../mixins/purchaseApi';

export default {
    components: {faqBlock},
    name: 'Pricing',
    mixins: [purchaseApi],
    data: () => {
        return {
            amBusy: false,
            productListLoaded: false,
            productsWithPricesRaw: [],
            productsWithPricesSimple: [],
            trialAttributes: {},
            disablePurchaseTrial: false,
            disablePurchaseMain: false,
            disablePurchaseAddon: true,
            addOnProductsSimple: [],
            showFaqs: false
        };
    },
    metaInfo: {
        title: 'Pricing'
    },
    mounted: async function() {
        this.$store.commit('setChosenProduct', null); // clear stored route
        this.amBusy = true;
        // TODO differentiate between main and sub products
        // TODO don't show trial if already has used one
        await this.buildProductsAndPriceData();
        if (this.loggedIn) {
            const custEnvSummary = await this.performCustomerEnvironmentSummary();
            this.setUpCustomerProductOptions(custEnvSummary);
        }
    },
    created() {
    },
    computed: {
        loggedIn() {
            return this.$store.getters.getLoggedIn;
        },
        filteredMainProductList() {
            const filteredList = [];
            for (const p of this.productsWithPricesSimple) {
                // var isAddon = false;
                // p.metadata.forEach(obj => {
                //     if (obj.key === 'Add-on' && obj.value === 'Yes') isAddon = true;
                // });
                if (p.productType && p.productType.toLowerCase() === 'main') filteredList.push(p);
                // if (p.productType && p.productType.toLowerCase() === 'main' && this.showMainProducts) filteredList.push(p);
                // else if (p.productType && p.productType.toLowerCase() === 'sub' && this.showSubProducts) filteredList.push(p);
            }
            filteredList.sort((a, b) => (a.amount > b.amount) ? 1 : -1);
            return filteredList;
        }
    },
    methods: {
        determineDisablePurchaseMain(ces) {
            this.disablePurchaseMain = false;
            if (ces.mainTokenExists || ces.activeNonTrialMainsExist) {
                this.disablePurchaseMain = true;
            }
        },
        setUpCustomerProductOptions(ces) {
            if (ces && ces.status && ces.status === 'ok') {
                this.disablePurchaseTrial = ces.trialExists || ces.mainTokenExists || ces.activeNonTrialMainsExist || ces.expiredNonTrialMainsExist;
                this.determineDisablePurchaseMain(ces);
                this.disablePurchaseAddon = !ces.activeNonTrialMainsExist;
            } else {
                this.disablePurchaseTrial = false;
                this.disablePurchaseMain = false;
                this.disablePurchaseAddon = true;
            }
        },
        filterProductMetadata(productMetadata) {
            const filteredList = [];
            for (const pmd of productMetadata) {
                if (pmd.key !== this.PRD_TYPE_METADATA_KEY) filteredList.push(pmd);
            }
            return filteredList;
        },
        goToTrialSetup() {
            this.$router.push({name: 'Trial Setup'});
        },
        goToCheckout(simpleProduct) {
            this.$store.commit('setChosenProduct', simpleProduct);
            this.$router.push({name: 'Purchase'});
        },
        stringToCamelCase(s) {
            return s.replace(/([-_][a-z])/ig, ($1) => {
                return $1.toUpperCase()
                    .replace('-', '')
                    .replace('_', '');
            });
        },
        simplifyProductMetadata(rawProductMetadata) {
            const simpleProdMd = [];
            if (rawProductMetadata) {
                const mdKeys = Object.keys(rawProductMetadata);
                for (const mdk of mdKeys) {
                    const spm = {};
                    spm.key = mdk;
                    spm.value = rawProductMetadata[mdk];
                    simpleProdMd.push(spm);
                }
            }
            return simpleProdMd;
        },
        productHasRequiredMetadata(rawProductMetadata) {
            const mdKeys = Object.keys(rawProductMetadata);
            if (!mdKeys.includes(this.PRD_LEARNERS_METADATA_KEY)) return false;
            else if (!mdKeys.includes(this.PRD_AUTHORING_STORAGE_METADATA_KEY)) return false;
            else if (!mdKeys.includes(this.PRD_BOOKSHELF_STORAGE_METADATA_KEY)) return false;
            else if (!mdKeys.includes(this.PRD_TYPE_METADATA_KEY)) return false;
            else if (!mdKeys.includes(this.PRD_NUM_PEBLS_METADATA_KEY)) return false;
            else if (!mdKeys.includes(this.PRD_BANDWIDTH_METADATA_KEY)) return false;
            else if (!mdKeys.includes(this.PRD_MOD_DASH_METADATA_KEY)) return false;
            else if (!mdKeys.includes(this.PRD_ACT_DASH_METADATA_KEY)) return false;
            else return true;
        },
        getProductTypeFromMetadata(simpleProductMetadata) {
            let productType = 'unknown';
            for (const spm of simpleProductMetadata) {
                if (spm.key === this.PRD_TYPE_METADATA_KEY) {
                    productType = spm.value;
                    break;
                }
            }
            return productType;
        },
        getPriceWithCouponForProduct(prod) {
            if (prod.hasCoupon) {
                const coupon = prod.coupon;
                if (coupon.amount_off) {
                    return (prod.amount - coupon.amount_off) / 100;
                } else if (coupon.percent_off) {
                    return (prod.amount * ((coupon.percent_off - 100) / 100)) / 100;
                } else return prod.amount / 100;
            } else return prod.amount / 100;
        },
        addCouponDataToSimpleProductPrice(rawProduct, simpleProductPrice) {
            if (rawProduct.coupons && rawProduct.coupons.length > 0) {
                simpleProductPrice.hasCoupon = true;
                simpleProductPrice.couponId = rawProduct.coupons[0].id;
                simpleProductPrice.coupon = rawProduct.coupons[0];
            } else {
                simpleProductPrice.hasCoupon = false;
                simpleProductPrice.couponId = null;
                simpleProductPrice.coupon = null;
            }
        },
        buildSimpleProductsWithPrices() {
            this.productsWithPricesSimple = [];
            this.addOnProductsSimple = [];
            for (const rawProduct of this.productsWithPricesRaw) {
                if (rawProduct.prices && rawProduct.prices.length > 0 && this.productHasRequiredMetadata(rawProduct.metadata)) {
                    const rawPrice = rawProduct.prices[0];
                    const simpleProductPrice = {};
                    simpleProductPrice.productId = rawProduct.id;
                    simpleProductPrice.priceId = rawPrice.id;
                    simpleProductPrice.name = rawProduct.name;
                    simpleProductPrice.description = rawProduct.description;
                    simpleProductPrice.amount = rawPrice.unit_amount;
                    simpleProductPrice.metadata = this.simplifyProductMetadata(rawProduct.metadata);
                    simpleProductPrice.metadata.forEach(function(item) {
                        var checkValue = item.value;
                        if (item.value.toLowerCase() === 'yes') checkValue = true;
                        if (item.value.toLowerCase() === 'no') checkValue = false;
                        simpleProductPrice[item.key] = checkValue;
                    });
                    simpleProductPrice.priceType = rawPrice.type;
                    simpleProductPrice.productType = this.getProductTypeFromMetadata(simpleProductPrice.metadata);
                    if (rawPrice.type === 'recurring') {
                        simpleProductPrice.checkoutMode = 'subscription';
                        simpleProductPrice.interval = rawPrice.recurring.interval;
                    } else simpleProductPrice.checkoutMode = 'payment';
                    this.addCouponDataToSimpleProductPrice(rawProduct, simpleProductPrice);
                    this.productsWithPricesSimple.push(simpleProductPrice);
                    if (simpleProductPrice.productType && simpleProductPrice.productType.toLowerCase() === 'sub') {
                        this.addOnProductsSimple.push(simpleProductPrice);
                    }
                }
            }
        },
        buildProductPricingView() {
            if (this.productsWithPricesRaw && this.productsWithPricesRaw.length > 0) {
                this.buildSimpleProductsWithPrices();
                this.productListLoaded = true;
                this.amBusy = false;
            } else {
                this.productListLoaded = false;
                this.amBusy = false;
            }
        },
        async buildTrialAttributes() {
            const ta = this.$store.getters.getTrialAttributes;
            if (ta) this.trialAttributes = ta;
            else {
                this.trialAttributes = await this.performTrialAttributesSearch();
                this.$store.commit('setTrialAttributes', this.trialAttributes);
            }
        },
        async buildProductWithPrices() {
            const pwp = this.$store.getters.getProductsWithPrices;
            if (pwp) this.productsWithPricesRaw = pwp;
            else {
                this.productsWithPricesRaw = await this.performProductWithPricesSearch();
                this.$store.commit('setProductsWithPrices', this.productsWithPricesRaw);
            }
        },
        async buildProductsAndPriceData() {
            await this.buildTrialAttributes();
            await this.buildProductWithPrices();
            this.buildProductPricingView();
        }
    }
};
</script>

<style scoped>

</style>
