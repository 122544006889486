<template>
    <section
        v-if="showCallToAction"
        class="my-6 py-6">
        <div
            v-if="showActionNum === 1"
            class="container">
            <div class="columns is-centered">
                <div class="column is-two-thirds has-text-centered">
                    <h3 class="title">
                        Ready to start your free PeBL Pro trial?
                    </h3>
                    <router-link
                        :to="{name:'Trial Setup'}"
                        class="button is-large is-primary is-outlined is-rounded">
                        Try it now
                    </router-link>
                </div>
            </div>
        </div>

        <div
            v-if="showActionNum === 2"
            class="container">
            <div class="columns is-centered">
                <div class="column is-two-thirds has-text-centered">
                    <h3 class="title">
                        Ready to start your free PeBL Pro trial?
                    </h3>
                    <router-link
                        :to="{name:'Trial Setup'}"
                        class="button is-large is-primary is-outlined is-rounded">
                        Try it now
                    </router-link>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="hero is-large">
        <div class="hero-body">
            <div class="container has-text-centered">
                <div class="notification is-warning p-6">
                    <nav class="level mx-6">
                        <div class="level-left">
                            <div class="level-item">
                                <p class="subtitle is-4">
                                    <strong>Are you ready to take the next step?</strong>
                                </p>
                            </div>
                        </div>
                        <div class="level-right">
                            <p class="level-item">
                                <router-link
                                    :to="{name:'Login'}"
                                    class="button is-rounded ">
                                    Get Started
                                </router-link>
                            </p>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </section> -->
</template>

<script>
export default {
    name: 'BigCallToAction',
    data: () => {
        return {
            showActionNum: 1
            // showCallToAction: true
        };
    },
    computed: {
        showCallToAction() {
            // Check if logged-in or route says to hide it
            if (this.$store.getters.getLoggedIn) return false;
            if (this.$route.meta.hideCallToAction) return false;
            return true;
        }
    }
};
</script>
