import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import StartScreen from '../views/StartScreen.vue';
// import AccountSetup from '../views/AccountSetup.vue';
import AccountSettings from '../views/AccountSettings.vue';
import SubscriptionAdmin from '../views/SubscriptionAdmin.vue';
import Features from '../views/Features.vue';
import Training from '../views/Training.vue';
import AboutUs from '../views/AboutUs.vue';
import ContactUs from '../views/ContactUs.vue';
// import News from '../views/News.vue';
import FAQ from '../views/FAQ.vue';
import BlogList from '../views/BlogList.vue';
import Pricing from '../views/Pricing.vue';
import PricingTEST from '../views/PricingTEST.vue';
import Purchase from '../views/Purchase.vue';
import TrialSetup from '../views/TrialSetup.vue';
import CheckoutSuccess from '../views/CheckoutSuccess.vue';
import CheckoutCanceled from '../views/CheckoutCanceled.vue';
import Newsletter from '../views/Newsletter.vue';
import NewsletterSuccess from '../views/NewsletterSuccess.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import CookiePolicy from '../views/CookiePolicy.vue';
import TermsOfUse from '../views/TermsOfUse.vue';
import PageNotFound from '../views/PageNotFound.vue';
import Meta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(Meta);

// META NOTES:
// requiresAuth - pages that can only be viewed by a logged in user
// breadcrumb - (not used at this time) Component to show a breadcrumb links

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            breadcrumb: [{name: 'Home'}]
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            breadcrumb: [{name: 'Login'}]
        }
    },
    {
        path: '/home',
        name: 'Start Screen',
        component: StartScreen,
        meta: {
            requiresAuth: true,
            breadcrumb: [{name: 'Start Screen'}]
        }
    },
    // {
    //     path: '/setup',
    //     name: 'Account Setup',
    //     component: AccountSetup,
    //     meta: {
    //         requiresAuth: true,
    //         breadcrumb: [{name: 'Account Setup'}]
    //     }
    // },
    {
        path: '/settings',
        name: 'Account Settings',
        component: AccountSettings,
        meta: {
            requiresAuth: true,
            breadcrumb: [{name: 'Account Settings'}]
        }
    },
    {
        path: '/subadmin',
        name: 'Subscription Administration',
        component: SubscriptionAdmin,
        meta: {
            requiresAuth: true,
            breadcrumb: [{name: 'Subscription Administration'}]
        }
    },
    {
        path: '/training',
        name: 'Training',
        component: Training,
        meta: {
            breadcrumb: [{name: 'Training'}]
        }
    },
    {
        path: '/training/:id',
        components: require('@/views/Interaction.vue'),
        meta: {
            breadcrumb: [
                {name: 'training', link: '/training'},
                {name: 'Interaction'}
            ]
        }
    },
    {
        path: '/about',
        name: 'About Us',
        component: AboutUs,
        meta: {
            breadcrumb: [{name: 'About Us'}]
        }
    },
    {
        path: '/about/contact',
        name: 'Contact Us',
        component: ContactUs,
        meta: {
            breadcrumb: [{name: 'Contact Us'}]
        }
    },
    {
        path: '/features',
        name: 'Features',
        component: Features,
        meta: {
            breadcrumb: [{name: 'Features'}]
        }
    },
    {
        path: '/features/:id',
        components: require('@/views/FeatureSpotlight.vue'),
        meta: {
            breadcrumb: [
                {name: 'features', link: '/features'},
                {name: 'Feature Spotlight'}
            ]
        }
    },
    {
        path: '/blog',
        name: 'Blog List',
        component: BlogList,
        meta: {
            breadcrumb: [{name: 'Blogs'}]
        }
    },
    {
        path: '/blog/:id',
        components: require('@/views/Blog.vue'),
        meta: {
            breadcrumb: [
                {name: 'Blogs', link: '/blog'},
                {name: 'Current Article'}
            ]
        }
    },
    // {
    //     path: '/news',
    //     name: 'News',
    //     component: News,
    //     meta: {
    //         breadcrumb: [{name: 'News'}]
    //     }
    // },
    {
        path: '/newsletter',
        name: 'Newsletter',
        component: Newsletter,
        meta: {
            breadcrumb: [
                {name: 'News', link: '/news'},
                {name: 'Newsletter Signup'}
            ]
        }
    },
    {
        path: '/newsletter-success',
        name: 'Newsletter success',
        component: NewsletterSuccess,
        meta: {
            breadcrumb: [
                {name: 'News', link: '/news'},
                {name: 'Signup Successful'}
            ]
        }
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ,
        meta: {
            hideCallToAction: true,
            breadcrumb: [{name: 'FAQ'}]
        }
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: Pricing,
        meta: {
            hideCallToAction: true,
            breadcrumb: [{name: 'Pricing'}]
        }
    },
    {
        path: '/pricingTEST',
        name: 'Pricing Test',
        component: PricingTEST,
        meta: {
            hideCallToAction: true,
            breadcrumb: [{name: 'Pricing'}]
        }
    },
    {
        path: '/trialSetup',
        name: 'Trial Setup',
        component: TrialSetup,
        meta: {
            requiresAuth: true,
            breadcrumb: [{name: 'Trial Setup'}]
        }
    },
    {
        path: '/purchase',
        name: 'Purchase',
        component: Purchase,
        meta: {
            requiresAuth: true,
            breadcrumb: [{name: 'Purchase'}]
        }
    },
    {
        path: '/checkoutSuccess',
        name: 'Checkout Success',
        component: CheckoutSuccess,
        meta: {
            breadcrumb: [{name: 'Checkout Success'}]
        }
    },
    {
        path: '/checkoutCanceled',
        name: 'Checkout Canceled',
        component: CheckoutCanceled,
        meta: {
            breadcrumb: [{name: 'Checkout Canceled'}]
        }
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: PrivacyPolicy,
        meta: {
            hideCallToAction: true,
            breadcrumb: [
                {name: 'About', link: '/about'},
                {name: 'Privacy Policy'}
            ]
        }
    },
    {
        path: '/cookie-policy',
        name: 'Cookie Policy',
        component: CookiePolicy,
        meta: {
            hideCallToAction: true,
            breadcrumb: [
                {name: 'About', link: '/about'},
                {name: 'Cookie Policy'}
            ]
        }
    },
    {
        path: '/terms-of-use',
        name: 'Terms of Use',
        component: TermsOfUse,
        meta: {
            hideCallToAction: true,
            breadcrumb: [
                {name: 'About', link: '/about'},
                {name: 'Terms of Use'}
            ]
        }
    },

    // REDIRECT from old pages
    {
        path: '/news/newsletter',
        redirect: '/newsletter'
    },
    {
        path: '/join-the-evolution',
        redirect: '/newsletter'
    },
    {
        path: '/blog-why-does-ereading-get-a-bad-rap',
        redirect: '/blog/why-does-ereading-get-a-bad-rap'
    },
    {
        path: '/blog-elevating-multiple-choice-sucky-to-solid',
        redirect: '/blog/elevating-multiple-choice-from-sucky-to-solid'
    },
    {
        path: '/blog-better-than-being-there-why-i-love-virtual-user-testing',
        redirect: '/blog/better-than-being-there-why-i-love-virtual-user-testing'
    },
    // Sample PeBLs Redirects
    {
        path: '/wwww-sample',
        beforeEnter(to, from, next) {
            window.location.replace('https://samples.pebl.pro/?epub=epub_content%2Fbookshelf%2Furn%3Auuid%3A6415934f-1ee3-4d4d-a09a-2f9c2ea32359&goto=epubcfi(/6/2!/0)');
        }
    },
    {
        path: '/marketing-ld-sample',
        beforeEnter(to, from, next) {
            window.location.replace('https://samples.pebl.pro/?epub=epub_content%2Fbookshelf%2Furn%3Auuid%3A2fb25b0c-879a-437f-9708-d8b2ccde9800&goto=epubcfi(/6/2!/0)');
        }
    },
    {
        path: '/learning-science-sample',
        beforeEnter(to, from, next) {
            window.location.replace('https://samples.pebl.pro/?epub=epub_content%2Fbookshelf%2Furn%3Auuid%3A82b4fe34-f1ca-457d-a9c3-8f61a9090d21&goto=epubcfi(/6/2!/0)');
        }
    },
    {
        // match everything not listed above
        path: '*',
        name: 'Page Not Found',
        component: PageNotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            // Scroll to anchor.
            // Delay to allow page to load, specifically Pricing page
            setTimeout(() => {
                const element = document.getElementById(to.hash.replace(/#/, ''));
                if (element && element.scrollIntoView) {
                    element.scrollIntoView({block: 'end', behavior: 'smooth'});
                }
            }, 1000);
            // return {
            //     selector: to.hash,
            //     behavior: 'smooth'
            // };
        } else {
            return {x: 0, y: 0};
        }
    }
});

router.beforeEach(async(to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login
        // Remember the blocked route to go there after login
        const amLoggedIn = store.getters.getLoggedIn;
        if (!amLoggedIn) {
            store.commit('setAfterLoginRoute', to.name);
            router.push({name: 'Login'});
            // This was throwing an ugly error so I changed it to router.push
            // next({
            //     path: '/login',
            //     query: {redirect: to.fullPath}
            // });
        } else {
            next();
        }
    } else {
        next(); // always call next()!
    }
});

export default router;
