<template>
    <div role="main">
        <h1 class="title has-text-centered mt-6">
            Training
        </h1>

        <div class="page-navbar">
            <!-- Must be at root level of body content -->
            <a
                v-for="(section, index) in pageSections"
                :key="index"
                class="navbar-item"
                @click="scrollTo(section.href)">
                <i
                    class="fa-fw is-hidden-touch"
                    :class="section.icon" />
                {{ section.name }}
            </a>
        </div>

        <section
            v-if="showWebinars"
            ref="webinars"
            class="hero is-light">
            <div class="container">
                <div class="hero-body">
                    <p class="title has-text-centered">
                        Webinars
                    </p>
                    <webinars :grid-view="true" />
                    <p class="has-text-centered">
                        All times listed are your local time. Click the register link for details.
                    </p>
                </div>
            </div>
        </section>

        <section
            v-if="showSamples"
            ref="samples"
            class="hero is-medium">
            <div class="container">
                <div class="hero-body">
                    <a
                        href="https://demos.pebl.pro/"
                        target="_blank"
                        class="is-pulled-right">See all <i class="fas fa-arrow-right" /></a>
                    <p class="title has-text-centered">
                        Demos
                    </p>
                    <div
                        id="trainingSamples"
                        class="columns is-multiline is-mobile is-centered">
                        <div
                            v-for="(sample, index) in samples"
                            :key="index"
                            class="column is-narrow">
                            <div class="cover-wrapper">
                                <a
                                    :href="sample.url"
                                    target="_blank">
                                    <div class="cover-description">
                                        <p class="mb-2"><span class="tag">{{ sample.category }}</span></p>
                                        <p>{{ sample.description }}</p>
                                    </div>
                                    <img
                                        class="cover-image"
                                        :src="sample.image"
                                        :alt="sample.title">
                                </a>
                                <p
                                    class="cover-title"
                                    :class="{'is-draft':!sample.published}">
                                    {{ sample.title }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <coverflow
            v-if="showSamples"
            :height="'15rem'"
            :width="'12rem'"
            :covers="samples" /> -->

        <section
            v-if="showVideos"
            ref="videos"
            class="hero is-medium">
            <div class="container">
                <div class="hero-body">
                    <!-- href="https://vimeo.com/peblpro" -->
                    <a
                        href="https://vimeo.com/showcase/8555086"
                        target="_blank"
                        class="is-pulled-right">See all <i class="fas fa-arrow-right" /></a>
                    <p class="title has-text-centered">
                        Videos
                    </p>

                    <div class="columns is-multiline">
                        <div
                            v-for="(video, index) in videos"
                            :key="index"
                            class="column is-half-tablet is-one-third-desktop">
                            <div class="video-wrapper">
                                <iframe
                                    :src="'https://player.vimeo.com/video/'+video.vimeoID+'?color=FFFFFf&title=0&byline=0&portrait=0'"
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowfullscreen />
                            </div>
                            <p :class="{'is-draft':!video.published_at}">
                                <a
                                    :href="'https://vimeo.com/'+video.vimeoID"
                                    class="ml-3 is-pulled-right"
                                    title="Open in new window"
                                    target="_blank"><i class="fa-fw fas fa-external-link-alt" /></a>
                                {{ video.title }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div
            ref="interactions"
            v-if="showInteractions">
            <interaction-hero-section />
        </div>

        <section
            ref="links"
            class="hero is-medium is-light">
            <div class="container">
                <div class="hero-body">
                    <p class="title has-text-centered">
                        Links
                    </p>
                    <div class="columns is-multiline is-mobile is-vcentered">
                        <div class="column">
                            <div class="link-box">
                                <a
                                    href="https://community.pebl.pro/"
                                    target="_blank">
                                    <div class="columns is-mobile is-vcentered">
                                        <div class="column is-narrow">
                                            <span class="link-icon"><i class="fas fa-users fa-4x" /></span>
                                        </div>
                                        <div class="column">
                                            <p class="link-title">
                                                Community
                                            </p>
                                            <p class="link-body">
                                                Search for solutions and chat with other PeBL authors
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="column">
                            <div class="link-box">
                                <router-link
                                    :to="{name:'FAQ'}">
                                    <div class="columns is-mobile">
                                        <div class="column is-narrow">
                                            <span class="link-icon"><i class="far fa-question-circle fa-4x" /></span>
                                        </div>
                                        <div class="column">
                                            <p class="link-title">
                                                FAQ
                                            </p>
                                            <p class="link-body">
                                                Frequently asked questions
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Webinars from '../components/webinars.vue';
import InteractionHeroSection from '../components/interactionHeroSection.vue';

export default {
    name: 'Training',
    components: {
        Webinars,
        InteractionHeroSection
    },
    data: () => {
        return {
            name: 'Training'
        };
    },
    metaInfo: {
        title: 'Training Resources'
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        showWebinars() {
            return this.$store.state.webinars.length > 0;
        },
        rawSamples() {
            return this.$store.state.peblSamples;
        },
        samples() {
            var simpleList = [];
            this.$store.state.peblSamples.forEach((item) => {
                // get medium sized image, if it exists
                // fix relative strapi urls
                simpleList.push({
                    // TODO: URL cleanup should probably be moved to Vuex store
                    title: item.title,
                    alternativeText: item.cover.alternativeText,
                    description: item.Description,
                    category: item.category,
                    url: item.url,
                    published: item.published_at,

                    ...item.cover.formats && item.cover.formats.small ? {
                        image: this.strapiApiUrl + item.cover.formats.small.url
                    } : {
                        image: this.strapiApiUrl + item.cover.url
                    }
                });
            });
            return simpleList;
        },
        showSamples() {
            return this.samples.length > 0;
        },
        videos() {
            return this.$store.state.videos;
        },
        showVideos() {
            return this.videos.length > 0;
        },
        interactions() {
            return this.$store.state.interactionDocs;
        },
        showInteractions() {
            return this.interactions.length > 0;
        },
        pageSections() {
            var sections = [];
            // Generate navbar shortcuts. Hide empty sections.
            // Order should match page layout order
            if (this.showWebinars) sections.push({name: 'Webinars', icon: 'fas fa-chalkboard-teacher', href: 'webinars'});
            if (this.showSamples) sections.push({name: 'Demos', icon: 'fas fa-book-open', href: 'samples'});
            if (this.showVideos) sections.push({name: 'Videos', icon: 'fas fa-tv', href: 'videos'});
            if (this.showInteractions) sections.push({name: 'Interactions', icon: 'fas fa-mouse-pointer', href: 'interactions'});
            sections.push({name: 'Links', icon: 'fas fa-link', href: 'links'});
            return sections;
        }
    },
    mounted() {
        this.redirectHelpTopic();

        if (this.$store.state.webinars.length < 1) this.$store.dispatch('getWebinars');
        if (this.$store.state.peblSamples.length < 1) this.$store.dispatch('getPeblSamples');
        if (this.$store.state.videos.length < 1) this.$store.dispatch('getVideos');
        if (this.$store.state.interactionDocs.length < 1) this.$store.dispatch('getInteractionDocs');
    },
    methods: {
        redirectHelpTopic(refName) {
            const path = this.getRouteName(this.$route.query.topic);
            // console.log('>>>>>>>>>>>>>>>>>>>>', path);
            if (path) this.$router.push(path);
        },
        scrollTo(refName) {
            const menuHeight = 190;
            const element = this.$refs[refName];
            console.log(element);
            const section = element.offsetTop - menuHeight;
            window.scrollTo({top: section, behavior: 'smooth'});
        },
        getRouteName(topic) {
            // Return route name if page exists
            switch (topic) {
            case 'metadata':
                return '';
            case 'contents':
                return '';
            case 'preview':
                return '';
            case 'themes':
                return 'features/themes';

            case 'media':
                return '';
            case 'images':
                return '';
            case 'audio':
                return '';
            case 'video':
                return '';

            case 'interactions':
                return 'training/';
            case 'variables':
            case 'triggers':
                return 'features/variables-and-triggers';
            case 'discussion':
                return 'training/discussions';
            case 'poll':
                return 'training/polls';
            case 'feedback':
                return 'training/feedback';
            case 'content-morphing':
                return 'training/content-morphing';
            case 'show-hide':
                return 'training/show-hide';
            case 'hotword':
                return 'training/glossary-hotword';
            case 'webobject':
                return '/training/web-objects';
            case 'short-answer':
                return 'training/short-answer';
            case 'file-upload':
                return 'training/file-upload';
            case 'open-ended-response':
                return 'training/open-ended-response';
            case 'guided-evaluation':
                return 'training/guided-evaluation';

            case 'multiple-choice':
            case 'multiple-response':
            case 'assessment':
                return 'training/multiple-choice-multiple-response-and-assessment';

            case 'button':
            case 'checkbox':
            case 'radio-button':
                return 'training/buttons-checkboxes-and-radio-buttons';

            case 'variable-input':
            case 'variable-display':
                return 'training/variable-input-and-variable-display';

            case 'internal-link':
                return 'training/internal-link';

            default:
                return '';
            }
        }
    }
};
</script>

<style scoped>

</style>
