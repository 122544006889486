<template>
    <div role="main">
        <section class="hero">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <h1 class="title">
                        Contact Us
                    </h1>
                    <p class="subtitle">
                        Subtitle text.
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: 'ContactUs',
    data: () => {
        return {
            name: 'ContactUs'
        };
    },
    metaInfo: {
        title: 'Contact Us'
    }
};
</script>

<style scoped>

</style>
