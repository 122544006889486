<template>
    <div id="webinars">
        <!-- Webinars -->
        <!-- See https://day.js.org/docs/en/parse/string-format for date formatting -->

        <!-- Special formatting for Home Page -->
        <a
            v-if="homeScreen"
            :href="webinars[0].url"
            target="_blank">
            <div class="home-screen link-box">
                <p class="webinar-title link-title">
                    <span>
                        {{ webinars[0].name }}<br>
                        {{ $date(webinars[0].date).format('MMM D, h:mm a') }}
                    </span>
                </p>
            </div>
        </a>

        <div
            v-else
            :class="{'columns is-multiline is-centered':gridView}">
            <!-- :class="{'is-half': index < webinars.length - 1}" -->
            <div
                v-for="(webinar,index) in webinars"
                :key="webinar.id"
                :class="{
                    'column':gridView,
                    'is-half':webinars.length === 2,
                    'is-one-third':webinars.length > 2,
                    'is-draft':!webinar.published_at}">
                <div
                    class="webinar-container">
                    <a
                        :href="webinar.url"
                        target="_blank">
                        <div class="columns is-8 is-mobile">
                            <div class="column is-narrow has-text-centered">
                                <div class="date-block">
                                    <div class="month">
                                        {{ $date(webinar.date).format('MMM') }}
                                    </div>
                                    <div class="date">
                                        {{ $date(webinar.date).format('D') }}
                                    </div>
                                </div>
                            <!-- <p>{{ $date(webinar.date).format('h:MM a') }}</p> -->
                            </div>
                            <div class="column">
                                <p class="webinar-title">
                                    {{ webinar.name }}
                                </p>
                                <p class="webinar-time">
                                    {{ $date(webinar.date).format('dddd, h:mm a') }}
                                    <span
                                        v-if="index === 0 "
                                        class="ml-1 tag is-warning">
                                        {{ $date(webinar.date).fromNow() }}
                                    </span>
                                </p>
                                <a class="register-link">
                                    <span class="icon"><i class="fa-fw far fa-calendar-check" /></span>
                                    <span>Register</span>
                                </a>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Webinar',
    props: {
        count: Number, // optional, limit number shown
        gridView: Boolean,
        homeScreen: Boolean // optional, display with image
    },
    data: () => {
        return {
            name: 'Webinar'
        };
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        webinars() {
            var webinars = this.$store.state.webinars;
            if (this.count && this.count <= webinars.length) {
                return webinars.slice(0, this.count);
            }
            return webinars;
        },
        now() {
            return this.$date();
        }
    },
    mounted() {
        if (this.$store.state.webinars.length < 1) this.$store.dispatch('getWebinars');
    },
    methods: {
        openUrl: function(url) {
            if (url) {
                window.open(url, '_blank');
            }
        }
    }
};
</script>
