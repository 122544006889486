

<template>
    <!-- layout for interaction page content -->
    <div role="main">
        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-mobile is-centered">
                        <div class="column column is-fullwidth-mobile is-three-quarters-tablet is-three-fifths-desktop is-half-widescreen is-half-fullhd">
                            <div class="interaction-icon box ml-2 mb-4 is-pulled-right">
                                <slot name="interactionIcon" />
                            </div>

                            <h1 class="title">
                                <slot name="interactionTitle" />
                            </h1>

                            <div class="content">
                                <slot name="interactionBody" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section
            v-if="hasInteractionLinks || hasFeatureLinks"
            class="hero is-light">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-mobile is-centered">
                        <div class="column is-fullwidth-mobile is-three-quarters-tablet is-three-fifths-desktop is-half-widescreen is-half-fullhd">
                            <div
                                v-if="hasFeatureLinks"
                                class="block has-text-centered">
                                <p class="title is-5">
                                    Further Reading
                                </p>
                                <slot name="featureLinks" />
                            </div>
                            <!-- <div
                                v-if="hasInteractionLinks"
                                class="block">
                                <p class="title is-5">
                                    Related Interactions
                                </p>
                                <div class="columns is-mobile is-multiline">
                                    <slot name="interactionLinks" />
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <interaction-hero-section>
            <template #title>
                Explore All Interactions
            </template>
        </interaction-hero-section>
    </div>
</template>

<script>
import interactionHeroSection from './interactionHeroSection.vue';

export default {
    components: {interactionHeroSection},
    computed: {
        hasInteractionLinks() {
            return !!this.$slots.interactionLinks;
        },
        hasFeatureLinks() {
            return !!this.$slots.featureLinks;
        }
    }
};
</script>
