<template>
    <div
        role="main">
        <section class="hero">
            <div class="hero-body">
                <div class="container content">
                    <div class="columns is-centered">
                        <div class="column is-6">
                            <!-- <h2>The History</h2>
                            <p class="has-text-weight-bold">
                                The <a
                                    href="https://adlnet.gov/projects/pebl/"
                                    target="_blank">Personalized eBooks for Learning project</a>
                                began at the <a
                                    href="https://adlnet.gov/"
                                    target="_blank">Advanced Distributed Learning</a> Initiative
                                as a way to create highly interactive, personalized ebooks that were instrumented with xAPI.
                            </p><p>
                                From 2017 through 2019, <a
                                    href="https://eduworks.com/"
                                    target="_blank">Eduworks Corporation</a>
                                and industry partners wrote the specification, developed PeBL interactions and the web reader,
                                and created an authoring option: an
                                <a
                                    href="https://exchange.adobe.com/creativecloud.details.103598.pebl-authoring.html"
                                    target="_blank">Adobe plugin</a>
                                that takes advantage of InDesign’s ability to export EPUBs and incorporates
                                PeBL’s interactive libraries and xAPI functionality.
                            </p><p>
                                The work completed under ADL contract lives on at <a
                                    href="https://peblproject.org/"
                                    target="_blank">peblproject.org</a>,
                                where users can download the PeBL libraries (interactive components) and server components as open source software.
                            </p><p>
                                Eduworks has continued to contribute additional code to the open source effort.
                            </p> -->
                            <h1 class="title">
                                Introducing PeBL Pro
                            </h1>
                            <p class="has-text-weight-bold">
                                In 2020, Eduworks began work on PeBL Pro, a web-based app that simplifies the authoring experience and removes it from dependence on other platforms.
                            </p>
                            <p>
                                The result is an easy to use, powerful authoring experience that produces PeBLs.
                                These are interactive learning experiences that…
                            </p>
                            <ul>
                                <li>integrate social and collaborative tools</li>
                                <li>incorporate interactions for learner practice and evaluation</li>
                                <li>deliver mobile-responsive across devices, and</li>
                                <li>simultaneously report SCORM and xAPI</li>
                            </ul>
                            <p>
                                For more information about PeBL Pro, see the <a href="/features">Features</a> page,
                                jump right in to a free trial, join the <a
                                    href="https://community.pebl.pro/"
                                    target="_blank">community</a>,
                                or explore any of the other options on this site.
                            </p>
                            <h2>The Team</h2>
                            <p class="has-text-weight-bold">
                                Much of the Eduworks staff, past and present, has had some stake in PeBL over the years,
                                and without their contributions, PeBL Pro would not be the innovation it is.
                            </p>
                            <p>
                                We’d like to particularly recognize the team that brought PeBL Pro to fruition:
                            </p>
                            <ul>
                                <li>Judy Katz, product manager</li>
                                <li>Julliard Lin, project manager</li>
                                <li>Andy Purviance, creative director</li>
                                <li>Aaron Veden, director of engineering</li>
                                <li>Mile Divovic, lead software engineer</li>
                                <li>Eddie Davies, quality engineer</li>
                                <li>
                                    Tom Buskirk, senior software engineer, who we remember with grateful hearts.
                                    Every day we miss his dedication, humor, and warmth.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'AboutUs',
    data: () => {
        return {
            name: 'AboutUs'
        };
    },
    metaInfo: {
        title: 'About Us'
    }
};
</script>

<style scoped>

</style>
