<template>
    <div role="main">
        <section class="hero is-light is-fullheight-with-navbar">
            <div class="hero-body">
                <div class="container">
                    <setup-steps
                        :account-type="'purchase'"
                        :step-number="2" />

                    <div class="columns is-centered">
                        <div class="column is-half">
                            <div class="box">
                                <!-- Reserve Domain -->
                                <div v-if="!purchasingSubWithoutMain && !purchasingAnotherMain">
                                    <!-- <div class="column is-6 is-flex is-flex-direction-column"> -->
                                    <!-- <div> -->
                                    <div class="block has-text-centered">
                                        <!-- <p class="priceAmount is-pulled-right">
                                            ${{ product.amount/100 }}
                                            <span v-if="product.priceType === 'recurring'">/ {{ product.interval }}</span>
                                        </p> -->
                                        <p class="title">
                                            <!-- <h5 class="title is-5 is-family-sans-serif has-text-weight-bold"> -->
                                            {{ product.name }}
                                            <!-- </h5> -->
                                        </p>
                                        <p class="subtitle">
                                            <span v-if="product.hasCoupon">${{ getPriceWithCouponForProduct() }}</span>
                                            <span v-else>${{ product.amount/100 }}</span>
                                            <span v-if="product.priceType === 'recurring'">/ {{ product.interval }}</span>
                                        </p>
                                        <div
                                            class="block"
                                            v-if="product.metadata && product.metadata.length > 0">
                                            <!-- <ul class="pl-5 mt-2"> -->
                                            <!-- <li -->
                                            <p class="tags">
                                                <span
                                                    class="tag"
                                                    v-for="pmd in filterProductMetadata(product.metadata)"
                                                    :key="pmd.key">
                                                    {{ pmd.key }}:{{ '\xa0' }}
                                                    <span class="has-text-weight-bold"> {{ pmd.value }}</span>
                                                </span>
                                            </p>
                                        <!-- </li> -->
                                        <!-- </ul> -->
                                        </div>
                                    </div>

                                    <div v-if="canReserveSubdomain">
                                        <p class="mb-4">
                                            <strong>Claim your subdomain for 30 minutes.</strong>
                                            Use the form below to check that the subdomain you want is available and reserve it.
                                            After checkout go to your Account Settings to set it up.
                                        </p>
                                        <choose-subdomain
                                            :is-for-reservation="true"
                                            :disabled="reservingSubdomain"
                                            @preReserving="setAmReservingSubdomain"
                                            @postReserving="setDoneReservingSubdomain" />
                                    </div>
                                    <div class="field mt-3">
                                        <div class="control">
                                            <label class="checkbox">
                                                <input
                                                    type="checkbox"
                                                    v-model="acceptTerms"
                                                    :disabled="reservingSubdomain">
                                                I accept the <router-link
                                                    :to="{name:'Terms of Use'}"
                                                    target="_blank"
                                                    class="has-text-danger">Terms of Use</router-link>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="buttons">
                                        <button
                                            type="submit"
                                            class="button is-danger"
                                            :class="{'is-loading':reservingSubdomain}"
                                            :disabled="reservingSubdomain || checkoutDisabled || !acceptTerms"
                                            :title="getCheckoutTitle()"
                                            @click="purchaseProduct">
                                            Checkout
                                        </button>
                                        <button
                                            v-if="!reservingSubdomain"
                                            class="button"
                                            type="submit"
                                            @click="cancelCheckout">
                                            Cancel
                                        </button>
                                    </div>
                                </div>

                                <!-- Message: Cannot purchase library addition -->

                                <div v-if="purchasingSubWithoutMain">
                                    <div class="block">
                                        <p class="subtitle has-text-danger has-text-centered">
                                            <i class="fas fa-exclamation-triangle" />
                                            Library additions require a PeBL Pro subscription
                                        </p>
                                        <p>
                                            You don't appear to have have an active, non-trial
                                            subscription associated with this account.
                                            You can renew an expired subscription in your
                                            <router-link :to="{name:'Account Settings'}">
                                                Account Settings
                                            </router-link> or
                                            <router-link :to="{name:'Pricing'}">
                                                purchase
                                            </router-link> a new subscription.
                                        </p>
                                    </div>
                                    <div class="has-text-centered">
                                        <button
                                            class="button is-primary"
                                            type="submit"
                                            @click="cancelCheckout">
                                            OK
                                        </button>
                                    </div>
                                </div>

                                <!-- Message: Already have a main account -->

                                <div v-if="purchasingAnotherMain">
                                    <div class="block">
                                        <p class="subtitle has-text-danger has-text-centered">
                                            <i class="fas fa-exclamation-triangle" />
                                            You already have an authoring subscription
                                        </p>
                                        <p>
                                            <!-- You already have an active main authoring subscription
                                            (or subscription redemption token) associated with your account. -->
                                            Currently, only one authoring subscription is available per account.
                                            If you need additional resources you can
                                            add a <router-link :to="{name:'Pricing'}">
                                                library addition
                                            </router-link> to this account.
                                        </p>
                                    </div>
                                    <div class="has-text-centered">
                                        <button
                                            class="button is-primary"
                                            type="submit"
                                            @click="cancelCheckout">
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import {purchaseApi} from '../mixins/purchaseApi';
import {appUtil} from '../mixins/appUtil';
import SetupSteps from '../components/setupSteps';
import chooseSubdomain from '../components/chooseSubdomain';

export default {
    name: 'Purchase',
    mixins: [purchaseApi, appUtil],
    components: {
        SetupSteps,
        chooseSubdomain
    },
    data: () => {
        return {
            acceptTerms: false,
            subdomainInvalid: false,
            subdomainUnavailable: false,
            subdomainReserved: false,
            checkoutDisabled: false,
            reservingSubdomain: false,
            requestedSubdomain: '',
            appReservedSubdomains: [],
            subdomainReservedExpires: '',
            purchasingSubWithoutMain: false,
            purchasingAnotherMain: false,
            canReserveSubdomain: false,
            product: {},
            customerEnvironmentSummary: {}
        };
    },
    metaInfo: {
        title: 'Purchase'
    },
    mounted: async function() {
        // TODO validate still logged in
        if (!this.product.productType) this.cancelCheckout();
        else {
            await this.checkForPurchasingIssues();
            await this.determineIfCanReserveSubdomain();
        }
    },
    created() {
        this.product = this.$store.getters.getChosenProduct;
    },
    methods: {
        setAmReservingSubdomain() {
            this.reservingSubdomain = true;
        },
        setDoneReservingSubdomain() {
            this.reservingSubdomain = false;
        },
        async determineIfCanReserveSubdomain() {
            if (!this.purchasingSubWithoutMain && this.product.productType.toLowerCase() === 'sub') {
                this.canReserveSubdomain = true;
            } else {
                if (this.purchasingAnotherMain) {
                    this.canReserveSubdomain = false;
                } else if (this.customerEnvironmentSummary.trialExists ||
                    this.customerEnvironmentSummary.activeNonTrialMainsExist ||
                    this.customerEnvironmentSummary.expiredNonTrialMainsExist) {
                    this.canReserveSubdomain = false;
                } else {
                    this.canReserveSubdomain = true;
                }
            }
        },
        async checkForPurchasingIssues() {
            this.customerEnvironmentSummary = await this.performCustomerEnvironmentSummary();
            if (this.product.productType.toLowerCase() === 'sub') {
                if (!this.customerEnvironmentSummary.activeNonTrialMainsExist) {
                    this.purchasingSubWithoutMain = true;
                }
            } else if (this.customerEnvironmentSummary.mainTokenExists || this.customerEnvironmentSummary.activeNonTrialMainsExist) {
                this.purchasingAnotherMain = true;
            }
        },
        getPriceWithCouponForProduct() {
            if (this.product.hasCoupon) {
                const coupon = this.product.coupon;
                if (coupon.amount_off) {
                    return (this.product.amount - coupon.amount_off) / 100;
                } else if (coupon.percent_off) {
                    return (this.product.amount * (coupon.percent_off / 100)) / 100;
                } else return this.product.amount / 100;
            } else return this.product.amount / 100;
        },
        filterProductMetadata(productMetadata) {
            const filteredList = [];
            for (const pmd of productMetadata) {
                if (pmd.key !== this.PRD_TYPE_METADATA_KEY) filteredList.push(pmd);
            }
            return filteredList;
        },
        getCheckoutTitle() {
            if (!this.acceptTerms) return 'You must accept the terms and conditions';
            else return '';
        },
        cancelCheckout() {
            this.$router.push({name: 'Pricing'});
        },
        async verifyStillLoggedIn() {
            const profile = await this.getSimpleUserProfileFromApi();
            if (profile) return true;
            else return false;
        },
        forceLogin() {
            this.$store.commit('setAfterLoginRoute', 'Purchase');
            this.$router.push({name: 'Login'});
        },
        async purchaseProduct() {
            const stillLoggedIn = await this.verifyStillLoggedIn();
            if (stillLoggedIn) {
                this.checkoutDisabled = true;
                this.performCheckout(this.product);
            } else this.forceLogin();
        }
    }
};
</script>

<style scoped>

</style>
