/* eslint-disable */
export const localStorageManager = {
    name: 'localStorageManager',
    data: () => ({
        LS_CHOSEN_PRODUCT_KEY: 'peblProChosenProduct',
        LS_AFTER_LOGIN_ROUTE_KEY: 'peblProAfterLoginRoute'
    }),
    methods: {
        setAfterLoginRouteInLocalStorage(routeName) {
            localStorage.setItem(this.LS_AFTER_LOGIN_ROUTE_KEY, routeName);
        },
        removeAfterLoginRouteFromLocalStorage() {
            localStorage.removeItem(this.LS_AFTER_LOGIN_ROUTE_KEY);
        },
        getAfterLoginRouteFromLocalStorage() {
            return localStorage.getItem(this.LS_AFTER_LOGIN_ROUTE_KEY);
        },
        setChosenProductInLocalStorage(chosenProduct) {
            localStorage.setItem(this.LS_CHOSEN_PRODUCT_KEY, JSON.stringify(chosenProduct));
        },
        removeChosenProductFromLocalStorage() {
            localStorage.removeItem(this.LS_CHOSEN_PRODUCT_KEY);
        },
        getChosenProductFromLocalStorage() {
            let cps = localStorage.getItem(this.LS_CHOSEN_PRODUCT_KEY);
            if (!cps) return null;
            else {
                try {
                    return JSON.parse(cps);
                } catch (e) {
                    return null;
                }
            }
        }
    },
    computed: {
    }
};
