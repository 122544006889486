<template>
    <div class="columns is-centered">
        <div class="column">
            <div class="steps">
                <div
                    v-for="(step, index) in steps"
                    :key="index"
                    class="step-item"
                    :class="{
                        'is-danger':index+1 <= stepNumber,
                        'is-completed':index+1 < stepNumber,
                        'is-active':index+1 == stepNumber
                    }">
                    <div class="step-marker">
                        <span
                            v-if="step.completed"
                            class="icon">
                            <i class="fa fa-check" />
                        </span>
                    </div>
                    <div class="step-details">
                        <p class="step-title">
                            {{ step.title }}
                        </p>
                        <p>{{ step.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Steps',
    props: ['accountType', 'stepNumber'],
    data: () => {
        return {
            steps: []
        };
    },
    mounted() {
        if (this.accountType === 'trial') this.steps = this.trialSteps();
        if (this.accountType === 'purchase') this.steps = this.purchaseSteps();
    },
    methods: {
        trialSteps: function() {
            return [
                {
                    title: 'Step 1',
                    description: 'Sign up & login'
                },
                {
                    title: 'Step 2',
                    description: 'Set up your account'
                },
                {
                    title: 'Step 3',
                    description: 'Begin authoring'
                }
            ];
        },
        purchaseSteps: function() {
            return [
                {
                    title: 'Step 1',
                    description: 'Sign up & login'
                },
                {
                    title: 'Step 2',
                    description: 'Confirm your purchase'
                },
                {
                    title: 'Step 3',
                    description: 'Checkout'
                },
                {
                    title: 'Step 4',
                    description: 'Begin authoring'
                }
            ];
        }
    }
};

</script>
