<template>
    <div id="skip">
        <a
            href="#main-content"
            ref="skip">Skip to Main Content</a>
    </div>
</template>

<script>
export default {
    watch: {
        $route() {
            // this.$refs.skipLink.focus();
        }
    }
};
</script>

<style lang="scss" scoped>
#skip a {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

#skip a:focus
{
    position:static;
    width:auto;
    height:auto;
}
</style>
