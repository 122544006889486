<template>
    <div id="inviteLearners">
        <div v-if="status==='input'">
            <div class="block">
                <p class="has-text-weight-bold">
                    Invite people to your PeBL Pro library
                </p>
                <p>
                    Separate multiple addresses with commas, spaces, or new lines.
                    Or import a text file or spreadsheet to search it for email addresses.
                </p>
            </div>

            <div
                v-if="showLibrarySelector && availableEnvs.length > 1"
                class="field">
                <label class="label">Library</label>
                <div

                    class="control is-expanded">
                    <div class="select">
                        <select
                            v-model="selectedAvailableEnvLocal"
                            @change="changeSelectedAvailableEnv">
                            <option
                                v-for="ae in availableEnvs"
                                :key="ae.value"
                                :value="ae.value">
                                {{ ae.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- <p v-else>
                {{ selectedEnvSubdomain }}
            </p> -->

            <div class="field">
                <label class="label ">Learner email(s)</label>
                <div class="control">
                    <textarea
                        v-model="emails"
                        class="textarea"
                        placeholder="Add emails" />
                </div>
                <p
                    v-if="invalidEmails.length > 0"
                    class="help is-danger">
                    The following address<span v-if="invalidEmails.length > 1">es are</span><span v-if="invalidEmails.length === 1"> is</span> invalid:
                    <span
                        v-for="(invalidEmail, index) in invalidEmails"
                        :key="index">
                        <strong class="has-text-danger">{{ invalidEmail }}</strong><span v-if="index < invalidEmails.length -1">, </span>
                    </span>
                </p>
                <p
                    v-if="duplicateEmails.length > 0"
                    class="help is-danger">
                    The following address<span v-if="duplicateEmails.length > 1">es are</span><span v-if="duplicateEmails.length === 1"> is</span> duplicated:
                    <span
                        v-for="(duplicateEmail, index) in duplicateEmails"
                        :key="index">
                        <strong class="has-text-danger">{{ duplicateEmail }}</strong><span v-if="index < duplicateEmails.length -1">, </span>
                    </span>
                </p>
            </div>
            <div class="field is-grouped">
                <div class="control">
                    <button
                        @click="processInviteLearners"
                        :disabled="isInviteButtonDisabled"
                        :title="inviteButtonTooltip"
                        class="button is-primary">
                        <span class="icon">
                            <i class="fas fa-paper-plane" />
                        </span>
                        <span v-if="emailList.length < 2">Send invite</span>
                        <span v-else>Send {{ emailList.length }} invites</span>
                    </button>
                </div>
                <div class="control">
                    <input
                        hidden
                        id="uploadFile"
                        ref="newfile"
                        type="file"
                        :accept="acceptedFileTypes"
                        @change="onFilePicked">
                    <button
                        @click="importFile"
                        class="button"
                        :class="{'is-loading':isLoading}"
                        title="Search text files for addresses">
                        Import...
                    </button>
                </div>
                <div class="control">
                    <button
                        @click="resetEmailInput"
                        class="button">
                        Reset
                    </button>
                </div>
            </div>
        </div>
        <div v-if="status === 'sending'">
            <p class="my-6 has-text-centered">
                <i class="mr-2 fas fa-circle-notch fa-spin" />
                <span v-if="emailList.length === 1">Sending an invite to {{ emailList[0] }} ...</span>
                <span v-else>Sending {{ emailList.length }} invites ...</span>
            </p>
            <!--Debug: <a @click="status='error'">error</a>/<a @click="status='success'">success</a>-->
        </div>
        <div v-if="status === 'success'">
            <div class="my-6 has-text-centered">
                <p class="block">
                    <i class="fas fa-paper-plane" />
                    {{ numInvitesCreated }}
                    <span v-if="numInvitesCreated == 1">invitation sent!</span>
                    <span v-else>invitations sent!</span>
                </p>
                <div class="field is-grouped is-grouped-centered">
                    <div class="control">
                        <button
                            @click="resetEmailInput"
                            class="button">
                            Invite more
                        </button>
                    </div>
                    <div class="control">
                        <button
                            @click="doClose"
                            class="button is-primary">
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="status === 'error'">
            <div class="my-6 has-text-danger has-text-centered">
                <i class="fas fa-exclamation-triangle" />
                <p>Something went wrong. No invitations sent.</p>
                <p>{{ inviteErrorMsg }}</p>
                <div class="field is-grouped is-grouped-centered">
                    <div class="control">
                        <button
                            @click="doTryAgain"
                            class="button">
                            Try Again
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {appUtil} from '../mixins/appUtil';
import {purchaseApi} from '../mixins/purchaseApi';

export default {
    name: 'InviteLearners',
    mixins: [appUtil, purchaseApi],
    props: [
        'showLibrarySelector',
        'availableEnvs',
        'selectedAvailableEnv'
    ],
    data: () => {
        return {
            status: 'input',
            selectedInvLearnerEnv: null,
            selectedAvailableEnvLocal: '',
            emails: '',
            invalidEmails: [],
            duplicateEmails: [],
            inviteButtonTooltip: 'Choose a library',
            isInviteButtonDisabled: true,
            inviteErrorMsg: '',
            numInvitesCreated: 0,
            acceptedFileTypes: 'text/plain, .txt, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
            acceptedFileTypesAlert: '.txt, .csv, and excel',
            isLoading: false
        };
    },
    computed: {
        selectedEnvSubdomain: function() {
            for (const e of this.availableEnvs) {
                if (e.value === this.selectedAvailableEnvLocal) return e.name;
            }
            return 'Unknown...';
        },
        emailList: function() {
            const emailString = this.emails.trim();
            if (this.emailString === '') return [];
            let emails = emailString.replace(/,\s+/g, ',').split(/[\n,\s+]/);
            // Remove blanks
            // emails = emails.filter(Boolean); //I wasn't sure what this was supposed to be doing?
            emails = this.removeBlanksAndTrimEmailList(emails);
            if (emails.length === 1 && emails[0] === '') return [];
            return emails;
        }
    },
    watch: {
        'emailList'() {
            this.invalidEmails = [];
            this.duplicateEmails = [];
            this.emailList.forEach(element => {
                if (!this.isValidEmail(element)) {
                    this.invalidEmails.push(element);
                }
            });
            this.duplicateEmails = this.getDuplicateItems(this.emailList);
            this.determineInviteButtonStatus();
        }
    },
    mounted: function() {
        this.selectedAvailableEnvLocal = this.selectedAvailableEnv;
        this.changeSelectedAvailableEnv();
    },
    methods: {
        removeBlanksAndTrimEmailList(emailList) {
            const retList = [];
            for (const e of emailList) {
                if (e && e.trim().length > 0) retList.push(e.trim());
            }
            return retList;
        },
        getSelectedInvLearnerEnvByValue(value) {
            for (const e of this.availableEnvs) {
                if (e.value === value) return e;
            }
            return null;
        },
        changeSelectedAvailableEnv: function() {
            this.selectedInvLearnerEnv = this.getSelectedInvLearnerEnvByValue(this.selectedAvailableEnvLocal);
            this.determineInviteButtonStatus();
        },
        determineInviteButtonStatus: function() {
            if (!this.selectedAvailableEnvLocal && !this.selectedInvLearnerEnv) {
                this.inviteButtonTooltip = 'Choose a library';
                this.isInviteButtonDisabled = true;
            } else if (this.emailList.length < 1) {
                this.inviteButtonTooltip = 'Add one or more emails';
                this.isInviteButtonDisabled = true;
            } else if (this.invalidEmails.length >= 1) {
                this.inviteButtonTooltip = 'Correct invalid emails';
                this.isInviteButtonDisabled = true;
            } else if (this.duplicateEmails.length >= 1) {
                this.inviteButtonTooltip = 'Correct duplicate emails';
                this.isInviteButtonDisabled = true;
            } else {
                this.inviteButtonTooltip = '';
                this.isInviteButtonDisabled = false;
            }
        },
        doClose: function() {
            this.$emit('close');
        },
        doTryAgain: function() {
            this.status = 'input';
            this.$emit('postSendInvites');
        },
        handleInviteError(errorMsg) {
            this.status = 'error';
            this.inviteErrorMsg = errorMsg;
        },
        processInviteLearners: async function() {
            this.$emit('preSendInvites');
            this.inviteErrorMsg = '';
            this.numInvitesCreated = 0;
            this.status = 'sending';
            let invLearnerRet = null;
            if (this.selectedInvLearnerEnv.value === 'main' || this.selectedInvLearnerEnv.value === 'trial') {
                invLearnerRet = await this.performInviteLearnersToMainEnvironment(this.selectedInvLearnerEnv.envId, this.emailList);
            } else if (this.selectedInvLearnerEnv.value.startsWith('sub')) {
                invLearnerRet = await this.performInviteLearnersToSubEnvironment(this.selectedInvLearnerEnv.envId, this.emailList);
            }
            if (invLearnerRet && invLearnerRet.status && invLearnerRet.status === 'ok') {
                if (invLearnerRet.failureReason && invLearnerRet.failureReason.length > 0) {
                    this.handleInviteError(invLearnerRet.failureReason);
                } else {
                    this.numInvitesCreated = invLearnerRet.numInvitationsCreated;
                    await this.$emit('inviteSendSuccess');
                    this.status = 'success';
                }
            } else {
                this.handleInviteError('Could not connect to the server');
            }
            this.$emit('postSendInvites');
        },
        resetEmailInput: function() {
            this.status = 'input';
            this.emails = '';
            // this.$emit('postSendInvites');
        },
        importFile: function() {
            this.$refs.newfile.click();
        },
        onFilePicked: function(event) {
            if (this.isLoading) { return; }

            this.files = event.target.files;
            console.log('Uploading file(s): ', this.files);
            if (!this.files.length > 0) {
                return;
            }
            for (const file of this.files) {
                if (file.size > 10 * 1024 * 1024) {
                    this.files = [];
                    this.$refs.newfile.value = '';
                    alert('File size must be less than 10MB');
                    return;
                }
            }
            if (this.acceptedFileTypes.includes(this.files[0].type)) {
                this.isLoading = true;
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.onload = e => (this.importEmails(e.target.result));
                reader.readAsText(file);
            } else {
                alert('Accepted file formats include: ' + this.acceptedFileTypesAlert);
            }

            this.files = [];
            this.$refs.newfile.value = '';
        },
        extractEmailsFromText: function(text) {
            // Includes plus sign for gmail addresses
            return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
        },
        importEmails: function(rawText) {
            this.isLoading = false;
            var emails = this.extractEmailsFromText(rawText);
            if (!emails) {
                alert('No email addresses were found.');
                return;
            }
            this.emails = emails.join('\n');
        }
    }
};
</script>

<style scoped>

</style>
