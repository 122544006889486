const API = 'https://cms.pebl.pro/';
// const API = process.env.VUE_APP_STRAPI_API;

export default {
    getStrapiData(collectionType, config, successCallback, failureCallback) {
        // collectionType should be the strapi API ID, ex: 'faqs' or 'blogs'
        // config is an optional string to tell strapi to sort, filter, or limit the results
        // https://strapi.io/documentation/developer-docs/latest/developer-resources/content-api/content-api.html#filters

        // Include "draft" content only on dev servers
        if (window.location.hostname === 'localhost' || window.location.hostname === 'dev.pebl.pro') {
            console.log('-------------- GETTING STRAPI ' + collectionType + ' w/ config: ' + config + ' -----------');
            if (config) { config += '&'; }
            config += '_publicationState=preview';
        }

        const xhr = new XMLHttpRequest();
        xhr.open('GET', API + collectionType + '?' + config);
        xhr.setRequestHeader('Content-Type', 'application/json');
        const xhrx = xhr;
        xhr.onreadystatechange = function() {
            if (xhrx.readyState === 4 && xhrx.status === 200) {
                if (successCallback != null) {
                    successCallback(xhrx.responseText);
                    // console.log('success: ', JSON.parse(xhrx.responseText));
                    return successCallback(xhrx.responseText);
                }
            } else if (xhrx.readyState === 4) {
                if (failureCallback != null) {
                    failureCallback(xhrx.responseText);
                    console.log('error: ', xhrx.responseText);
                    return failureCallback(xhrx.responseText);
                }
            }
        };
        xhr.send();
    }
};
