<template>
    <div role="main">
        <!-- Inspiration see: https://www.chargebee.com/blog/ -->

        <!-- TODO: Add tags to Strapi, options to filter by tags -->

        <!-- <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <p class="title has-text-centered">
                        Blogs
                    </p>
                    <p class="subtitle has-text-centered">
                        Get in the know with news, tips, and instructional design ideas
                    </p>
                </div>
            </div>
        </section> -->
        <section class="hero is-dark">
            <div class="hero-body">
                <div class="container">
                    <p class="title has-text-centered">
                        Blogs
                    </p>
                    <p class="subtitle has-text-centered mb-6">
                        Get in the know with news, tips, and instructional design ideas
                    </p>
                    <div class="columns is-centered">
                        <div
                            v-for="(blog) in blogs.slice(0, 1)"
                            :key="blog.id"
                            class="column is-7-tablet is-5-widescreen">
                            <blog-block
                                class="featured"
                                :id="blog.id"
                                :show-blurb="true"
                                :show-author="true" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered is-multiline is-variable">
                        <div
                            v-for="(blog) in blogs.slice(1, blogs.length)"
                            :key="blog.id"
                            class="column is-half-tablet is-one-third-widescreen">
                            <blog-block
                                :featured="true"
                                :id="blog.id"
                                :show-author="true" />
                        </div>
                    </div>
                    <!-- <div class="columns is-centered">
                        <div class="column is-8-tablet is-6-desktop">
                            <div class="columns is-centered is-multiline is-variable is-1-mobile is-4-desktop is-8-widescreen">
                                <div
                                    v-for="(blog) in blogs.slice(3, blogs.length)"
                                    :key="blog.id"
                                    class="column is-half-tablet">
                                    <blog-block
                                        class="mb-6"
                                        :id="blog.id"
                                        :hide-footer="true" />
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BlogBlock from '../components/blogBlock.vue';

export default {
    name: 'BlogList',
    components: {
        BlogBlock
    },
    data: () => {
        return {
        };
    },
    metaInfo: {
        title: 'Blogs'
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        blogs() {
            return this.$store.state.blogs;
        }
    },
    mounted() {
        if (this.$store.state.blogs.length < 1) this.$store.dispatch('getBlogs');
    },
    methods: {
        notEmpty(data) {
            if (!data) return false;
            return true;
        },
        getImgUrl(pic) {
            if (!pic) return;
            return require('../assets/' + pic);
        }
    }
};
</script>
