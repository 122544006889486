<template>
    <div
        id="sub-admin"
        role="main">
        <!-- modal - upgrade confirm -->
        <div
            class="modal"
            :class="{ 'is-active': upgradeConfirmModalActive }">
            <div class="modal-background" />
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Upgrade customer environment?
                    </p>
                    <button
                        @click="closeUpgradeConfirmModal"
                        class="delete"
                        aria-label="close" />
                </header>
                <section class="modal-card-body">
                    Are you sure you wish to upgrade the customer environment from
                    <strong>{{ getProductName(upgradeEnvCustMainEnv.stripeProductId) }}</strong> to
                    <strong>{{ getProductName(upgradeEnvCustMainEnvToken.stripeProductId) }}</strong>?
                </section>
                <footer class="modal-card-foot">
                    <div v-if="!upgradingBusy">
                        <button
                            @click="closeUpgradeConfirmModal"
                            class="button is-text">
                            Cancel
                        </button>
                        <button
                            @click="upgradeCustomerEnvironment"
                            class="button is-primary">
                            Upgrade
                        </button>
                    </div>
                    <div v-else>
                        <p>
                            <i class="fa-fw fas fa-circle-notch fa-spin" /> Upgrading...
                        </p>
                    </div>
                </footer>
            </div>
            <button
                @click="closeUpgradeConfirmModal"
                class="modal-close is-large"
                aria-label="close" />
        </div>

        <!-- modal - customer select -->
        <div
            class="modal"
            :class="{ 'is-active': customerSelectModalActive }">
            <div class="modal-background" />
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Select customer
                    </p>
                    <button
                        @click="closeCustomerSelectModal"
                        class="delete"
                        aria-label="close" />
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <input
                            v-model="customerSelectFilter"
                            class="input"
                            type="text"
                            placeholder="Find customers...">
                    </div>
                    <div class="customer-select">
                        <table class="table is-hoverable table is-striped is-fullwidth is-size-6">
                            <tbody>
                                <tr
                                    v-for="(custRec, idx) in customerSelectFilteredList"
                                    :key="idx">
                                    <td>
                                        <a @click="applyCustomerSelect(custRec.customerEmail)"><i class="fas fa-sign-in-alt" /></a>
                                    </td>
                                    <td>{{ custRec.customerEmail }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button
                        @click="closeCustomerSelectModal"
                        class="button is-text">
                        Cancel
                    </button>
                </footer>
            </div>
            <button
                @click="closeCustomerSelectModal"
                class="modal-close is-large"
                aria-label="close" />
        </div>

        <!-- modal - record details -->
        <div
            class="modal"
            :class="{ 'is-active': recordDetailsModalActive }">
            <div class="modal-background" />
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Record details
                    </p>
                    <button
                        @click="closeRecordDetailsModal"
                        class="delete"
                        aria-label="close" />
                </header>
                <section class="modal-card-body">
                    <div class="record-details">
                        <table
                            class="table is-hoverable table is-striped is-fullwidth is-size-7">
                            <tbody>
                                <tr
                                    v-for="(recKey, idx) in Object.keys(recordDetailRecord)"
                                    :key="idx">
                                    <td><strong>{{ recKey }}</strong></td>
                                    <td>{{ recordDetailRecord[recKey] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button
                        @click="closeRecordDetailsModal"
                        class="button is-text">
                        Done
                    </button>
                </footer>
            </div>
            <button
                @click="closeRecordDetailsModal"
                class="modal-close is-large"
                aria-label="close" />
        </div>

        <!-- main content -->
        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column">
                            <div class="columns">
                                <div
                                    class="column is-one-fifth">
                                    <p class="title sans-font has-text-weight-bold is-5">
                                        Admin Tools
                                    </p>
                                    <aside class="menu">
                                        <ul class="menu-list">
                                            <li>
                                                <a
                                                    @click="showSection(ENVS_FOR_CUST_SECTION)"
                                                    :class="{'is-active':currentSection === ENVS_FOR_CUST_SECTION}">
                                                    <span>
                                                        <i class="fa-fw fas fa-globe" />
                                                    </span>
                                                    <span>Customer Search</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    @click="showSection(CUST_FOR_SUBDOMAIN_SECTION)"
                                                    :class="{'is-active':currentSection === CUST_FOR_SUBDOMAIN_SECTION}">
                                                    <span>
                                                        <i class="fa-fw fas fa-user" />
                                                    </span>
                                                    <span>Subdomain Search</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    @click="showSection(UPGRADE_ENV_FOR_CUSTOMER)"
                                                    :class="{'is-active':currentSection === UPGRADE_ENV_FOR_CUSTOMER}">
                                                    <span>
                                                        <i class="fa-fw fas fa-atom" />
                                                    </span>
                                                    <span>Upgrade Library</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </aside>
                                </div>

                                <!-- Libraries By Customer SECTION -->
                                <div
                                    v-if="currentSection === ENVS_FOR_CUST_SECTION"
                                    class="column subsection">
                                    <p class="title sans-font has-text-weight-bold is-5">
                                        Libraries By Customer
                                    </p>
                                    <div class="field-body">
                                        <div class="field">
                                            <div class="control">
                                                <input
                                                    class="input is-fullwidth"
                                                    type="text"
                                                    placeholder="Email address..."
                                                    v-model="envSearchCustEmail">
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="control">
                                                <button
                                                    @click="searchForEnvironmentsByCustomerEmail"
                                                    class="button is-link mr-3">
                                                    <span class="icon">
                                                        <i class="fas fa-search" />
                                                    </span>
                                                    <span>Search</span>
                                                </button>
                                                <button
                                                    title="find customers"
                                                    @click="openCustomerSelectModal(ENV_SEARCH_MODE)"
                                                    class="button">
                                                    <span class="icon">
                                                        <i class="fas fa-user-friends" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="envSearchCustEmailInvalid"
                                        class="notification is-danger is-light mt-3">
                                        <p>
                                            Must enter a valid email.
                                            <i class="fas fa-exclamation-triangle is-ghost" />
                                        </p>
                                    </div>
                                    <div
                                        v-if="envSearchBusy"
                                        class="mt-3">
                                        <p>
                                            <i class="fa-fw fas fa-circle-notch fa-spin" /> Searching...
                                        </p>
                                    </div>
                                    <div
                                        v-else-if="envSearchPerformed && customerMainEnvList.length === 0"
                                        class="notification is-info is-light mt-3">
                                        <p>
                                            No libraries found for customer email.
                                            <i class="fas fa-exclamation-triangle is-ghost" />
                                        </p>
                                    </div>
                                    <div
                                        v-else-if="envSearchPerformed && customerMainEnvList.length > 0"
                                        class="mt-3">
                                        <hr>
                                        <label class="label">Main Account</label>
                                        <table
                                            class="table is-hoverable table is-striped is-fullwidth is-size-7">
                                            <thead>
                                                <tr>
                                                    <th colspan="2" />
                                                    <th>Subdomain</th>
                                                    <th>Status</th>
                                                    <th>Stripe product id</th>
                                                    <th>Max authoring storage</th>
                                                    <th>Bookshelf storage</th>
                                                    <th>Date purchased</th>
                                                    <th>Date period ends</th>
                                                    <th>Cancel at period end</th>
                                                    <th>Canceled from stripe</th>
                                                    <th>Is trial</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(mainEnv, idx) in customerMainEnvList"
                                                    :key="idx">
                                                    <td>
                                                        <a
                                                            @click="openRecordDetailsModal(mainEnv)"
                                                            title="record details"><i class="fas fa-book-open" /></a>
                                                    </td>
                                                    <td>
                                                        <a
                                                            @click="suspendMainEnvironment(mainEnv)"
                                                            title="suspend environment"><i class="fas fa-power-off" /></a>
                                                    </td>
                                                    <td><strong>{{ mainEnv.subdomain }}</strong></td>
                                                    <td>{{ mainEnv.readinessStatus }}</td>
                                                    <td>{{ mainEnv.stripeProductId }}</td>
                                                    <td>{{ mainEnvironmentAuthoringStorageCapacity }}</td>
                                                    <td>{{ mainEnv.bookshelfStorage }}</td>
                                                    <td>{{ utilDateTimeStringFromMilliseconds(mainEnv.datePurchased) }}</td>
                                                    <td>{{ utilDateTimeStringFromMilliseconds(mainEnv.datePeriodExpires) }}</td>
                                                    <td>{{ mainEnv.cancelAtPeriodEnd }}</td>
                                                    <td>{{ mainEnv.stripeSubscriptionDeleted }}</td>
                                                    <td>{{ mainEnv.isTrialEnv }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div
                                            v-if="customerSubEnvList.length > 0"
                                            class="mt-3">
                                            <label class="label">Add-on Libraries</label>
                                            <table
                                                class="table is-hoverable table is-striped is-fullwidth is-size-7">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2" />
                                                        <th>Subdomain</th>
                                                        <th>Status</th>
                                                        <th>Stripe product id</th>
                                                        <th>Bookshelf storage</th>
                                                        <th>Date purchased</th>
                                                        <th>Date period ends</th>
                                                        <th>Cancel at period end</th>
                                                        <th>Canceled from stripe</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(subEnv, idx) in customerSubEnvList"
                                                        :key="idx">
                                                        <td>
                                                            <a
                                                                @click="openRecordDetailsModal(subEnv)"
                                                                title="record details"><i class="fas fa-book-open" /></a>
                                                        </td>
                                                        <td>
                                                            <a
                                                                @click="suspendSubEnvironment(subEnv)"
                                                                title="suspend environment"><i class="fas fa-power-off" /></a>
                                                        </td>
                                                        <td><strong>{{ subEnv.subdomain }}</strong></td>
                                                        <td>{{ subEnv.readinessStatus }}</td>
                                                        <td>{{ subEnv.stripeProductId }}</td>
                                                        <td>{{ subEnv.bookshelfStorage }}</td>
                                                        <td>{{ utilDateTimeStringFromMilliseconds(subEnv.datePurchased) }}</td>
                                                        <td>{{ utilDateTimeStringFromMilliseconds(subEnv.datePeriodExpires) }}</td>
                                                        <td>{{ subEnv.cancelAtPeriodEnd }}</td>
                                                        <td>{{ subEnv.stripeSubscriptionDeleted }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <!-- Customer By Subdomain SECTION -->
                                <div
                                    v-if="currentSection === CUST_FOR_SUBDOMAIN_SECTION"
                                    class="column subsection">
                                    <p class="title sans-font has-text-weight-bold is-5">
                                        Customer By Subdomain
                                    </p>
                                    <div class="field-body">
                                        <div class="field">
                                            <div class="control">
                                                <input
                                                    class="input is-fullwidth"
                                                    type="text"
                                                    placeholder="Subdomain..."
                                                    v-model="custSearchEnvSubdomain">
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="control">
                                                <button
                                                    @click="searchForCustomersBySubdomain"
                                                    class="button is-link">
                                                    <span class="icon">
                                                        <i class="fas fa-search" />
                                                    </span>
                                                    <span>Search</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="custSearchSubdomainInvalid"
                                        class="notification is-danger is-light mt-3">
                                        <p>
                                            Must enter a subdomain.
                                            <i class="fas fa-exclamation-triangle is-ghost" />
                                        </p>
                                    </div>
                                    <div
                                        v-if="custSearchBusy"
                                        class="mt-3">
                                        <p>
                                            <i class="fa-fw fas fa-circle-notch fa-spin" /> Searching...
                                        </p>
                                    </div>
                                    <div
                                        v-else-if="custSearchPerformed && customersForSubdomainList.length === 0"
                                        class="notification is-info is-light mt-3">
                                        <p>
                                            No customers found with this subdomain.
                                            <i class="fas fa-exclamation-triangle is-ghost" />
                                        </p>
                                    </div>
                                    <div
                                        v-else-if="custSearchPerformed && customersForSubdomainList.length > 0"
                                        class="mt-3">
                                        <hr>
                                        <table
                                            class="table is-hoverable table is-striped is-fullwidth is-size-7">
                                            <thead>
                                                <tr>
                                                    <th>Customer email</th>
                                                    <th>Stripe customer id</th>
                                                    <th>External system id</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(custRec, idx) in customersForSubdomainList"
                                                    :key="idx">
                                                    <td><strong>{{ custRec.customerEmail }}</strong></td>
                                                    <td>{{ custRec.stripeCustomerId }}</td>
                                                    <td>{{ custRec.externalCustomerKey }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!-- Upgrade Customer Environment SECTION -->
                                <div
                                    v-if="currentSection === UPGRADE_ENV_FOR_CUSTOMER"
                                    class="column subsection">
                                    <p class="title sans-font has-text-weight-bold is-5">
                                        Upgrade Customer Environment
                                    </p>
                                    <div class="field-body">
                                        <div class="field">
                                            <div class="control">
                                                <input
                                                    class="input is-fullwidth"
                                                    type="text"
                                                    placeholder="Email address..."
                                                    v-model="upgradeEnvCustEmail">
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="control">
                                                <button
                                                    @click="searchForUpgradeDataByCustomerEmail"
                                                    class="button is-link mr-3">
                                                    <span class="icon">
                                                        <i class="fas fa-search" />
                                                    </span>
                                                    <span>Search</span>
                                                </button>
                                                <button
                                                    title="find customers"
                                                    @click="openCustomerSelectModal(UPGRADE_MODE)"
                                                    class="button">
                                                    <span class="icon">
                                                        <i class="fas fa-user-friends" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="upgradeEnvCustEmailInvalid"
                                        class="notification is-danger is-light mt-3">
                                        <p>
                                            Must enter a valid email.
                                            <i class="fas fa-exclamation-triangle is-ghost" />
                                        </p>
                                    </div>
                                    <div
                                        v-if="upgradeEnvSearchBusy"
                                        class="mt-3">
                                        <p>
                                            <i class="fa-fw fas fa-circle-notch fa-spin" /> Searching...
                                        </p>
                                    </div>
                                    <div
                                        v-else-if="upgradeEnvSearchPerformed && upgradeEnvCustNoMainEnv"
                                        class="notification is-info is-light mt-3">
                                        <p>
                                            No main library account found for customer email.
                                            <i class="fas fa-exclamation-triangle is-ghost" />
                                        </p>
                                    </div>
                                    <div
                                        v-else-if="upgradeEnvSearchPerformed && upgradeEnvSucceeded"
                                        class="notification is-success is-light mt-3">
                                        <p>
                                            Upgrade succeeded
                                            <i class="fas fa-exclamation-triangle is-ghost" />
                                        </p>
                                    </div>
                                    <div
                                        v-else-if="upgradeEnvSearchPerformed && upgradeEnvFailed"
                                        class="notification is-danger is-light mt-3">
                                        <p>
                                            Upgrade failed: {{ upgradeEnvFailedReason }}
                                            <i class="fas fa-exclamation-triangle is-ghost" />
                                        </p>
                                    </div>
                                    <div v-else-if="upgradeEnvSearchPerformed">
                                        <div class="columns mt-3">
                                            <div class="column is-5">
                                                <label class="label">Main account</label>
                                                <div class="message is-info is-size-7">
                                                    <div class="message-header">
                                                        {{ getProductName(upgradeEnvCustMainEnv.stripeProductId) }}
                                                    </div>
                                                    <div class="message-body">
                                                        <ul>
                                                            <li><strong>Subdomain: </strong>{{ upgradeEnvCustMainEnv.subdomain }}</li>
                                                            <li><strong>Authoring storage: </strong>{{ upgradeEnvCustMainEnv.authoringStorage }}</li>
                                                            <li><strong>Bookshelf storage: </strong>{{ upgradeEnvCustMainEnv.bookshelfStorage }}</li>
                                                            <li><strong>Canceled in stripe: </strong>{{ upgradeEnvCustMainEnv.stripeSubscriptionDeleted }}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="column is-1 is-size-2 has-text-centered mt-5 pt-6">
                                                <div v-if="upgradeEnvCustHasEligibleMainEnv && upgradeEnvCustHasEligibleMainEnvToken">
                                                    <i class="fas fa-arrow-circle-right" />
                                                </div>
                                                <div v-else>
                                                    <i class="fas fa-ban" />
                                                </div>
                                            </div>
                                            <div class="column is-5">
                                                <label class="label">Upgrade\Environment token</label>
                                                <div v-if="!upgradeEnvCustMainEnvToken.envTokenId">
                                                    <div class="notification is-warning is-light mt-3">
                                                        <p>
                                                            No upgrade/environment token found for customer email.
                                                            <i class="fas fa-exclamation-triangle is-ghost" />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="message is-success is-size-7">
                                                        <div class="message-header">
                                                            {{ getProductName(upgradeEnvCustMainEnvToken.stripeProductId) }}
                                                        </div>
                                                        <div class="message-body">
                                                            <ul>
                                                                <li><strong>Authoring storage: </strong>{{ upgradeEnvCustMainEnvToken.authoringStorage }}</li>
                                                                <li><strong>Bookshelf storage: </strong>{{ upgradeEnvCustMainEnvToken.bookshelfStorage }}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div v-if="upgradeEnvCustHasEligibleMainEnv && upgradeEnvCustHasEligibleMainEnvToken">
                                            <button
                                                title="upgrade environment"
                                                @click="openUpgradeConfirmModal"
                                                class="button is-primary">
                                                Upgrade
                                            </button>
                                        </div>
                                        <div v-else>
                                            <div class="notification is-danger is-light mt-3">
                                                <label class="label"><i class="fas fa-exclamation-triangle is-ghost" /> Cannot upgrade</label>
                                                <ul
                                                    class="ml-5"
                                                    style="list-style-type: circle;">
                                                    <li v-if="!upgradeEnvCustHasEligibleMainEnv">
                                                        Customer main account is not eligible for upgrade.  Make sure the subscription has been canceled/refunded in Stripe.
                                                    </li>
                                                    <li v-if="!upgradeEnvCustHasEligibleMainEnvToken">
                                                        No upgrade/environment token exists for this customer.  Make sure a main account subscription has been generated in Stripe
                                                        and the generated invoice has been paid by the customer.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import {purchaseApi} from '../mixins/purchaseApi';
import {appUtil} from '../mixins/appUtil';

export default {
    name: 'SubscriptionAdmin',
    mixins: [purchaseApi, appUtil],
    data: () => {
        return {
            ENVS_FOR_CUST_SECTION: 'envsForCustomerSection',
            CUST_FOR_SUBDOMAIN_SECTION: 'custForSubdomainSection',
            UPGRADE_ENV_FOR_CUSTOMER: 'upgradeEnvForCustomerSection',
            ENV_SEARCH_MODE: 'envSearch',
            UPGRADE_MODE: 'upgrade',
            currentSection: '',
            envSearchCustEmail: '',
            custSearchEnvSubdomain: '',
            upgradeEnvCustEmail: '',
            customerMainEnvList: [],
            customerSubEnvList: [],
            customersForSubdomainList: [],
            customerSelectList: [],
            upgradeEnvCustMainEnv: {},
            upgradeEnvCustMainEnvToken: {},
            upgradeEnvCustProductMap: {},
            upgradeEnvCustHasEligibleMainEnv: false,
            upgradeEnvCustHasEligibleMainEnvToken: false,
            upgradeEnvCustNoMainEnv: false,
            envSearchCustEmailInvalid: false,
            custSearchSubdomainInvalid: false,
            upgradeEnvCustEmailInvalid: false,
            envSearchPerformed: false,
            custSearchPerformed: false,
            upgradeEnvSearchPerformed: false,
            upgradeEnvSucceeded: false,
            upgradeEnvFailed: false,
            upgradeEnvFailedReason: '',
            envSearchBusy: false,
            custSearchBusy: false,
            upgradeEnvSearchBusy: false,
            mainEnvironmentAuthoringStorageCapacity: '',
            customerSelectModalActive: false,
            customerSelectFilter: '',
            customerSelectMode: '',
            recordDetailRecord: {},
            recordDetailsModalActive: false,
            upgradeConfirmModalActive: false,
            upgradingBusy: false
        };
    },
    metaInfo: {
        title: 'Subscription Administration'
    },
    mounted: async function() {
        this.currentSection = this.ENVS_FOR_CUST_SECTION;
    },
    created() {
    },
    computed: {
        customerSelectFilteredList: function() {
            return this.customerSelectList.filter(c => {
                return ((c.customerEmail && c.customerEmail.toLowerCase().indexOf(this.customerSelectFilter.toLowerCase()) > -1));
            });
        }
    },
    methods: {
        suspendMainEnvironment(mainEnv) {
            alert('TODO suspendMainEnvironment (Need details for this)');
        },
        suspendSubEnvironment(subEnv) {
            alert('TODO suspendSubEnvironment (Need details for this)');
        },
        closeRecordDetailsModal() {
            this.recordDetailsModalActive = false;
        },
        async openRecordDetailsModal(record) {
            if (record) {
                this.recordDetailRecord = record;
                this.recordDetailsModalActive = true;
            }
        },
        async applySelectedCustomerEmailToEnvSearchEmail(customerEmail) {
            this.envSearchCustEmail = customerEmail;
            this.closeCustomerSelectModal();
            this.searchForEnvironmentsByCustomerEmail();
        },
        async applySelectedCustomerEmailToUpgradeSearchEmail(customerEmail) {
            this.upgradeEnvCustEmail = customerEmail;
            this.closeCustomerSelectModal();
            this.searchForUpgradeDataByCustomerEmail();
        },
        async applyCustomerSelect(customerEmail) {
            if (this.customerSelectMode === this.ENV_SEARCH_MODE) {
                await this.applySelectedCustomerEmailToEnvSearchEmail(customerEmail);
            } else if (this.customerSelectMode === this.UPGRADE_MODE) {
                await this.applySelectedCustomerEmailToUpgradeSearchEmail(customerEmail);
            }
        },
        closeCustomerSelectModal() {
            this.customerSelectModalActive = false;
        },
        async openCustomerSelectModal(custSelectMode) {
            this.customerSelectMode = custSelectMode;
            this.customerSelectFilter = '';
            if (this.customerSelectList.length === 0) {
                const custData = await this.performAdminGetCustomerList();
                this.customerSelectList = custData.customers;
            }
            this.customerSelectModalActive = true;
        },
        async upgradeCustomerEnvironment() {
            this.upgradingBusy = true;
            this.upgradeEnvFailed = false;
            this.upgradeEnvSucceeded = false;
            this.upgradeEnvFailedReason = '';
            const upgradeReturn = await this.performAdminUpgradeCustomerEnvironment(this.upgradeEnvCustMainEnv.mainEnvId, this.upgradeEnvCustMainEnvToken.envTokenId);
            if (upgradeReturn) {
                if (upgradeReturn.updated) this.upgradeEnvSucceeded = true;
                else {
                    this.upgradeEnvFailed = true;
                    this.upgradeEnvFailedReason = upgradeReturn.failureReason;
                }
            } else {
                this.upgradeEnvFailed = true;
                this.upgradeEnvFailedReason = 'Server is not responsive';
            }
            this.upgradingBusy = false;
            this.upgradeConfirmModalActive = false;
        },
        closeUpgradeConfirmModal() {
            this.upgradeConfirmModalActive = false;
        },
        openUpgradeConfirmModal() {
            if (this.upgradeEnvCustHasEligibleMainEnv && this.upgradeEnvCustHasEligibleMainEnvToken) {
                this.upgradingBusy = false;
                this.upgradeConfirmModalActive = true;
            }
        },
        getProductName(productId) {
            if (this.upgradeEnvCustProductMap) {
                const prod = this.upgradeEnvCustProductMap[productId];
                if (prod) return prod.name;
                else return 'unknown';
            } else return 'unknown';
        },
        validateUpgradeEligibility() {
            if (this.upgradeEnvCustMainEnv.stripeSubscriptionDeleted) {
                this.upgradeEnvCustHasEligibleMainEnv = true;
            } else this.upgradeEnvCustHasEligibleMainEnv = false;
        },
        async performCustomerUpgradeDataSearch() {
            const upgradeData = await this.performAdminGetUpgradeDataForCustomer(this.upgradeEnvCustEmail);
            if (upgradeData) {
                if (upgradeData.mainEnvironments && upgradeData.mainEnvironments.length > 0) {
                    this.upgradeEnvCustMainEnv = upgradeData.mainEnvironments[0];
                    if (upgradeData.mainEnvironmentTokens && upgradeData.mainEnvironmentTokens.length > 0) {
                        this.upgradeEnvCustMainEnvToken = upgradeData.mainEnvironmentTokens[0];
                        this.upgradeEnvCustHasEligibleMainEnvToken = true;
                    } else this.upgradeEnvCustHasEligibleMainEnvToken = false;
                    if (upgradeData.productMap) this.upgradeEnvCustProductMap = upgradeData.productMap;
                    this.validateUpgradeEligibility();
                } else this.upgradeEnvCustNoMainEnv = true;
            } else this.runAway();
        },
        async searchForUpgradeDataByCustomerEmail() {
            this.upgradeEnvCustEmail = this.upgradeEnvCustEmail.trim();
            this.upgradeEnvCustMainEnv = {};
            this.upgradeEnvCustMainEnvToken = {};
            this.upgradeEnvCustProductMap = {};
            this.upgradeEnvSearchPerformed = false;
            this.upgradeEnvCustHasEligibleMainEnv = false;
            this.upgradeEnvCustHasEligibleMainEnvToken = false;
            this.upgradeEnvCustNoMainEnv = false;
            this.upgradeEnvSucceeded = false;
            this.upgradeEnvFailed = false;
            this.upgradeEnvFailedReason = '';
            if (this.isValidEmail(this.upgradeEnvCustEmail)) {
                this.upgradeEnvCustEmailInvalid = false;
                this.upgradeEnvSearchBusy = true;
                await this.performCustomerUpgradeDataSearch();
                this.upgradeEnvSearchPerformed = true;
                this.upgradeEnvSearchBusy = false;
            } else this.upgradeEnvCustEmailInvalid = true;
        },
        hasSubEnvironmentExpired(subEnv) {
            return subEnv.datePeriodExpires < Date.now();
        },
        determineAuthoringSpaceAllocatedToMainEnv() {
            // If the storage ever changes from 'GB', we'll need to redo this
            if (!this.customerMainEnvList[0].authoringStorage) this.mainEnvironmentAuthoringStorageCapacity = '0 GB';
            else if (this.customerMainEnvList.length > 0) {
                if (this.customerMainEnvList[0].authoringStorage.indexOf('GB') > -1) {
                    let space = parseInt(this.customerMainEnvList[0].authoringStorage.replace('GB', ''));
                    for (const se of this.customerSubEnvList) {
                        if (!this.hasSubEnvironmentExpired(se) && !se.stripeSubscriptionDeleted) {
                            space += parseInt(se.authoringStorage.replace('GB', ''));
                        }
                    }
                    this.mainEnvironmentAuthoringStorageCapacity = space + ' GB';
                } else if (this.customerMainEnvList[0].authoringStorage.indexOf('MB') > -1) {
                    this.mainEnvironmentAuthoringStorageCapacity = this.customerMainEnvList[0].authoringStorage;
                }
            } else {
                this.mainEnvironmentAuthoringStorageCapacity = '0 GB';
            }
        },
        async performEnvironmentsByCustomerEmailSearch() {
            const envData = await this.performAdminGetEnvsForCustomer(this.envSearchCustEmail);
            if (envData) {
                this.customerMainEnvList = envData.mainEnvironments;
                this.customerSubEnvList = envData.subEnvironments;
                if (this.customerMainEnvList && this.customerMainEnvList.length > 0) this.determineAuthoringSpaceAllocatedToMainEnv();
            } else this.runAway();
        },
        async searchForEnvironmentsByCustomerEmail() {
            this.envSearchCustEmail = this.envSearchCustEmail.trim();
            this.customerMainEnvList = [];
            this.customerSubEnvList = [];
            this.envSearchPerformed = false;
            if (this.isValidEmail(this.envSearchCustEmail)) {
                this.envSearchCustEmailInvalid = false;
                this.envSearchBusy = true;
                await this.performEnvironmentsByCustomerEmailSearch();
                this.envSearchPerformed = true;
                this.envSearchBusy = false;
            } else this.envSearchCustEmailInvalid = true;
        },
        async performCustomerBySubdomainSearch() {
            const custData = await this.performAdminGetCustomerForSubdomain(this.custSearchEnvSubdomain);
            if (custData) {
                this.customersForSubdomainList = custData.customers;
            } else this.runAway();
        },
        async searchForCustomersBySubdomain() {
            this.custSearchEnvSubdomain = this.custSearchEnvSubdomain.trim().toLowerCase();
            this.custSearchPerformed = false;
            this.customersForSubdomainList = [];
            if (this.custSearchEnvSubdomain && this.custSearchEnvSubdomain.length > 0) {
                this.custSearchSubdomainInvalid = false;
                this.custSearchBusy = true;
                await this.performCustomerBySubdomainSearch();
                this.custSearchPerformed = true;
                this.custSearchBusy = false;
            } else this.custSearchSubdomainInvalid = true;
        },
        showSection(section) {
            this.currentSection = section;
        },
        runAway() {
            this.$router.push({name: 'Page Not Found'});
        }
    }
};
</script>

<style scoped>
th, td {
    vertical-align: middle;
}
</style>
