<template>
    <div role="main">
        <section class="hero is-light is-fullheight-with-navbar">
            <div class="hero-body">
                <div class="container">
                    <setup-steps
                        :account-type="'trial'"
                        :step-number="2" />

                    <div class="columns is-centered">
                        <div class="column is-half">
                            <div class="box">
                                <!-- Setup Trial Account -->
                                <div v-if="customerEligibleForTrial && !trialCreated">
                                    <div class="block has-text-centered">
                                        <p class="title">
                                            {{ trialAttributes.numDays }} day PeBL Pro trial
                                        </p>
                                    </div>
                                    <p class="mb-4">
                                        <strong>Choose a subdomain name for your new library.</strong>
                                        Enter a name and check that it's still available.
                                        Once you create your library, you cannot change your subdomain name.
                                    </p>
                                    <choose-subdomain
                                        :is-for-reservation="false"
                                        @result="checkSubdomainResult"
                                        :disabled="creatingTrial" />

                                    <div class="field mt-3">
                                        <div class="control">
                                            <label class="checkbox">
                                                <input
                                                    type="checkbox"
                                                    v-model="acceptTerms"
                                                    :disabled="creatingTrial">
                                                I accept the <router-link
                                                    :to="{name:'Terms of Use'}"
                                                    target="_blank"
                                                    class="has-text-danger">Terms of Use</router-link>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="buttons">
                                        <button
                                            type="submit"
                                            class="button is-danger"
                                            :class="{'is-loading':creatingTrial}"
                                            :disabled="startTrialDisabled || !acceptTerms || creatingTrial"
                                            :title="getStartTrialTitle()"
                                            @click="startProductTrial">
                                            Start Trial
                                        </button>
                                        <button
                                            v-if="!creatingTrial"
                                            class="button"
                                            type="submit"
                                            @click="cancelTrialSetup">
                                            Cancel
                                        </button>
                                        <p v-if="creatingTrial">
                                            Creating your account...
                                        </p>
                                    </div>
                                </div>

                                <!-- Message: No trial available -->
                                <div
                                    v-if="!customerEligibleForTrial && !trialCreated">
                                    <div class="block">
                                        <p class="subtitle has-text-danger has-text-centered">
                                            <i class="fas fa-exclamation-triangle" /> You are not eligible for a trial
                                        </p>
                                        <p>
                                            You already have a subscription or trial.
                                            If you would like to extend your trial,
                                            please purchase a subscription from the products page
                                            and apply it to your trial library in
                                            the Account Settings.
                                        </p>
                                    </div>
                                    <div class="has-text-centered">
                                        <button
                                            class="button is-primary"
                                            type="submit"
                                            @click="cancelTrialSetup">
                                            OK
                                        </button>
                                    </div>
                                </div>

                                <!-- Message: Trial success -->
                                <div
                                    v-if="customerEligibleForTrial && trialCreated">
                                    <!-- <div class="column is-6 is-flex is-flex-direction-column">
                                        <article class="message is-success mt-3">
                                            <div class="message-header">
                                                <p><i class="fas fa-exclamation-triangle" /> Trial Created</p>
                                            </div>
                                            <div class="message-body">
                                                Your trial environment has been created and is currently being deployed.
                                                You can view the status of your trial in your Account Settings.
                                            </div>
                                        </article>
                                        <div class="columns is-centered">
                                            <div class="column is-6 is-flex is-flex-direction-column">
                                                <button
                                                    class="button"
                                                    type="submit"
                                                    @click="goToAccountSettings">
                                                    OK
                                                </button>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="block">
                                        <p class="subtitle has-text-danger has-text-centered">
                                            <i class="fas fa-info-circle" /> Setup in progress...
                                        </p>
                                        <p>
                                            Your trial account has been created and is currently being deployed.
                                            You can view the status in your Account Settings.
                                        </p>
                                    </div>
                                    <div class="has-text-centered">
                                        <button
                                            class="button is-primary"
                                            type="submit"
                                            @click="goToAccountSettings">
                                            Show Settings
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import {purchaseApi} from '../mixins/purchaseApi';
import {appUtil} from '../mixins/appUtil';
import SetupSteps from '../components/setupSteps';
import chooseSubdomain from '../components/chooseSubdomain';

export default {
    name: 'TrialSetup',
    mixins: [purchaseApi, appUtil],
    components: {
        SetupSteps,
        chooseSubdomain
    },
    data: () => {
        return {
            acceptTerms: false,
            // subdomainInvalid: false,
            // subdomainUnavailable: false,
            validSubdomainAvailable: false,
            startTrialDisabled: true,
            // validatingSubdomain: false,
            requestedSubdomain: '',
            // errorMsg: '',
            // appReservedSubdomains: [],
            trialAttributes: {},
            customerEligibleForTrial: true,
            trialCreated: false,
            creatingTrial: false,
            product: {}
        };
    },
    metaInfo: {
        title: 'Trial Setup'
    },
    mounted: async function() {
        this.customerEligibleForTrial = await this.performCustomerEligibleForTrial();
        if (this.customerEligibleForTrial) await this.buildTrialAttributes();
    },
    created() {
        this.product = this.$store.getters.getChosenProduct;
    },
    methods: {
        checkSubdomainResult: function(result) {
            this.startTrialDisabled = !result.isValid;
            this.validSubdomainAvailable = result.isValid;
            this.requestedSubdomain = result.subdomain;
        },
        goToAccountSettings() {
            this.$router.push({name: 'Account Settings'});
        },
        async buildTrialAttributes() {
            const ta = this.$store.getters.getTrialAttributes;
            if (ta) this.trialAttributes = ta;
            else {
                this.trialAttributes = await this.performTrialAttributesSearch();
                this.$store.commit('setTrialAttributes', this.trialAttributes);
            }
        },
        getStartTrialTitle() {
            if (this.startTrialDisabled) return 'You must enter a valid unique subdomain';
            else if (!this.acceptTerms) return 'You must accept the terms and conditions';
            else return '';
        },
        cancelTrialSetup() {
            this.$router.push({name: 'Pricing'});
        },
        async verifyStillLoggedIn() {
            const profile = await this.getSimpleUserProfileFromApi();
            if (profile) return true;
            else return false;
        },
        async processProductTrial() {
            const startTrialReturn = await this.performCustomerStartTrial(this.requestedSubdomain);
            if (startTrialReturn.trialCreated) this.trialCreated = true;
            // else if (!startTrialReturn.subdomainValid) this.subdomainInvalid = true;
            // else if (!startTrialReturn.subdomainAvailable) this.subdomainUnavailable = true;
            else if (startTrialReturn.ineligibleForTrial) this.customerEligibleForTrial = false;
            this.creatingTrial = false;
        },
        async startProductTrial() {
            const stillLoggedIn = await this.verifyStillLoggedIn();
            if (stillLoggedIn) {
                this.startTrialDisabled = true;
                this.creatingTrial = true;
                if (this.validSubdomainAvailable) {
                    this.processProductTrial();
                } else this.creatingTrial = false;
            } else {
                this.$store.commit('setAfterLoginRoute', 'Trial Setup');
                this.$router.push({name: 'Login'});
            }
        }
    }
};
</script>

<style scoped>

</style>
