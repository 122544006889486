<template>
    <!-- Single feature page layout -->
    <feature-template
        v-if="feature"
        :key="featureKey">
        <template #featureTitle>
            {{ feature.name }}
        </template>

        <template #featureBody>
            <span
                class="is-draft"
                v-if="!feature.published_at">
                DRAFT
            </span>
            <vue-markdown-plus
                :anchor-attributes="{
                    target:'_blank'
                }"
                :scr="strapiApiUrl">
                {{ feature.body }}
            </vue-markdown-plus>
        </template>
        <template
            #relatedInteractions
            v-if="relatedInteractions">
            <div
                v-for="(interaction, index) in relatedInteractions"
                :key="index"
                class="column is-half-tablet is-full-mobile">
                <interaction-block :id="interaction.id" />
            </div>
        </template>
    </feature-template>
</template>

<script>
import featureTemplate from '../components/featureSpotlightTemplate.vue';
import VueMarkdownPlus from 'vue-markdown-plus';
import InteractionBlock from '../components/interactionBlock.vue';

export default {
    name: 'FeatureSpotlight',
    components: {
        'vue-markdown-plus': VueMarkdownPlus,
        featureTemplate,
        InteractionBlock
    },
    data() {
        return {
            name: 'FeatureSpotlight',
            featureKey: 0,
            routeParam: this.$route.params.id
        };
    },
    metaInfo() {
        return {
            // title will be appended into the titleTemplate in App.vue
            title: this.pageTitle,
            titleTemplate: 'PeBL Spotlight | %s'
        };
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        feature() {
            var features = this.$store.state.featureSpotlights;
            var featureId = this.$route.params.id;
            // get feature by id
            var feature = features.filter(function(elem) {
                if (elem.url === featureId) return elem;
            })[0];
            return feature;
        },
        interactions() {
            return this.$store.state.interactionDocs;
        },
        pageTitle() {
            return this.feature.name;
        },
        relatedInteractions() {
            // Find feature spotlights that have this interaction's tag(s) as a tag
            var interactions = [];
            var interactionTags = [];
            if (this.feature.interaction_tags && this.feature.interaction_tags.length > 0) {
                const tags = this.feature.interaction_tags;
                tags.forEach(tag => {
                    interactionTags.push(tag.name);
                });
            }
            console.log('interactionTags:', interactionTags);
            if (this.interactions.length > 0) {
                this.interactions.forEach(interaction => {
                    const nextInteraction = interaction;
                    const tags = nextInteraction.interaction_tags;
                    if (tags.length > 0) {
                        tags.forEach(tag => {
                            if (interactionTags.includes(tag.name) &&
                                !interactions.some(e => e.url === nextInteraction.url)) {
                                interactions.push(nextInteraction);
                            }
                        });
                    }
                });
            }
            return interactions;
        },
        relatedFeatures() {
            var features = [];
            if (this.interaction && this.interaction.feature_tags) {
                this.interaction.feature_tags.forEach(element => {
                    features.push({
                        name: element.name,
                        url: '/features/' + element.name
                    });
                });
            }
            return features;
        }
    },
    watch: {
        feature: function(newVal, oldVal) {
            this.pageTitle = this.feature.name;
        }
    },
    mounted() {
        if (this.$store.state.interactionDocs.length < 1) this.$store.dispatch('getInteractionDocs');
        if (this.$store.state.featureSpotlights.length < 1) this.$store.dispatch('getFeatureSpotlights');
    },
    methods: {

    }
};
</script>

