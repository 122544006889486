<template>
    <!-- Single interaction page layout -->
    <interaction-template
        v-if="interaction">
        <template #interactionTitle>
            {{ interaction.name }}
        </template>

        <template #interactionIcon>
            <img :src="interaction.icon.url">
        </template>

        <template #interactionBody>
            <span
                class="is-draft"
                v-if="!interaction.published_at" />
            <vue-markdown-plus
                :anchor-attributes="{
                    target:'_blank'
                }"
                :scr="strapiApiUrl">
                {{ interaction.body }}
            </vue-markdown-plus>
        </template>

        <!-- <template
            #interactionLinks
            v-if="relatedInteractions.length > 0">
            <div
                v-for="(relatedInteraction, index) in relatedInteractions"
                :key="index"
                class="column is-half-tablet is-full-mobile">
                <interaction-block
                    :id="interaction.id" />
            </div>
        </template> -->

        <template
            #featureLinks
            v-if="relatedFeatures">
            <!-- {{ relatedFeatures }} -->
            <router-link
                v-for="(relatedFeature, index) in relatedFeatures"
                :to="{path: '/features/'+relatedFeature.url}"
                :key="index"
                class="link-item">
                {{ relatedFeature.name }}
            </router-link>
        </template>
    </interaction-template>
</template>

<script>
import interactionTemplate from '../components/interactionTemplate.vue';
import VueMarkdownPlus from 'vue-markdown-plus';

export default {
    name: 'Interaction',
    components: {
        'vue-markdown-plus': VueMarkdownPlus,
        interactionTemplate
    },
    data() {
        return {
        };
    },
    metaInfo() {
        return {
            // title will be appended into the titleTemplate in App.vue
            title: this.pageTitle,
            titleTemplate: 'PeBL Interaction | %s'
        };
    },
    mounted() {
        if (this.$store.state.interactionDocs.length < 1) this.$store.dispatch('getInteractionDocs');
        if (this.$store.state.featureSpotlights.length < 1) this.$store.dispatch('getFeatureSpotlights');
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        interaction: {
            get: function() {
                var interactions = this.$store.state.interactionDocs;
                var interactionId = this.$route.params.id;
                // get interaction by id
                var interaction = interactions.filter(function(elem) {
                    if (elem.url === interactionId) return elem;
                })[0];
                return interaction;
            }
        },
        pageTitle: {
            get: function() {
                return this.interaction.name;
            }
        },
        features() {
            return this.$store.state.featureSpotlights;
        },
        relatedFeatures() {
            // Find feature spotlights that have this interaction's tag(s) as a tag
            var features = [];
            var interactionTags = [];
            if (this.interaction.interaction_tags && this.interaction.interaction_tags.length > 0) {
                const tags = this.interaction.interaction_tags;
                tags.forEach(tag => {
                    interactionTags.push(tag.name);
                });
            }
            if (this.features.length > 0) {
                this.features.forEach(feature => {
                    const nextFeature = feature;
                    const tags = nextFeature.interaction_tags;
                    if (tags.length > 0) {
                        tags.forEach(tag => {
                            if (interactionTags.includes(tag.name) &&
                                !features.some(e => e.url === nextFeature.url)) {
                                features.push(nextFeature);
                            }
                        });
                    }
                });
            }
            return features;
        }
    },
    watch: {
        interaction: function(newVal, oldVal) {
            this.pageTitle = this.interaction.name;
        }
    },
    methods: {
        notEmpty(data) {
            if (!data) return false;
            return true;
        }
    }
};
</script>

