<template>
    <div role="main">
        <section class="hero is-fullheight-with-navbar is-dark">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-8 has-text-centered">
                            <h1 class="title">
                                Oops!
                            </h1>
                            <p class="subtitle">
                                Sorry, we can’t find the page you were looking for.
                            </p>
                            <div class="block">
                                <img
                                    style="max-height: 50vh; min-height: 300px;"
                                    src="@/assets/FAQ-group.png">
                            </div>
                            <router-link
                                to="/"
                                class="button is-light is-outlined is-rounded">
                                Back to PeBL Pro
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound',
    data: () => {
        return {
            name: 'PageNotFound'
        };
    },
    metaInfo: {
        title: 'Page Not Found',
        template: null
    }
};
</script>

<style scoped>

</style>
