

<template>
    <div
        id="featureSpotlight"
        role="main">
        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title has-text-centered">
                        <slot name="featureTitle" />
                    </h1>
                    <div class="columns is-mobile is-centered">
                        <div class="column column is-fullwidth-mobile is-three-quarters-tablet is-three-fifths-desktop is-half-widescreen is-half-fullhd">
                            <div class="content">
                                <slot name="featureBody" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section
            v-if="hasInteractionLinks"
            class="hero is-light">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-mobile is-centered">
                        <div class="column is-fullwidth-mobile is-three-quarters-tablet is-three-fifths-desktop is-half-widescreen is-half-fullhd">
                            <div
                                v-if="hasFeatureLinks"
                                class="block has-text-centered">
                                <p class="title is-5">
                                    Further Reading
                                </p>
                                <slot name="featureLinks" />
                            </div>
                            <div
                                class="block">
                                <p class="title is-5">
                                    Related Interactions
                                </p>
                                <div class="columns is-mobile is-multiline">
                                    <slot name="relatedInteractions" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="hero is-medium is-black has-text-light has-spotlights">
            <div
                class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-two-thirds has-text-centered">
                            <h3 class="title has-text-shadow">
                                Feature Spotlights
                            </h3>
                            <p>
                                <router-link
                                    v-for="feature in features"
                                    :key="feature.id"
                                    :to="{ path: '/features/' + feature.url }"
                                    class="feature-list-item">
                                    {{ feature.name }}
                                </router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    computed: {
        hasInteractionLinks() {
            return !!this.$slots.relatedInteractions;
        },
        hasFeatureLinks() {
            return !!this.$slots.featureLinks;
        },
        features() {
            return this.$store.state.featureSpotlights;
        }
    },
    mounted() {
        if (this.$store.state.featureSpotlights.length < 1) this.$store.dispatch('getFeatureSpotlights');
    }

};
</script>
