<template>
    <div
        id="StartScreen"
        role="main">
        <section class="hero is-fullheight-with-navbar">
            <!-- <img
                v-if="!hasEverHadTrialEnvironment && !hasMainEnvironment && !hasEnvironmentTokens"
                class="hero-background hero-fit-image is-ghost"
                src="../assets/success-group.png"> -->

            <div class="hero-body">
                <div class="container">
                    <div
                        v-if="showStepsSection"
                        class="has-text-centered">
                        <setup-steps
                            :account-type="'trial'"
                            :step-number="2" />
                        <div class="columns is-centered">
                            <div class="column is-half">
                                <div class="box p-6">
                                    <div class="block">
                                        <p class="title">
                                            Next: Set up your account
                                        </p>
                                        <p>
                                            Choose which type of account you want
                                        </p>
                                    </div>
                                    <div class="buttons is-centered">
                                        <router-link
                                            :to="{name: 'Trial Setup'}"
                                            class="button is-primary">
                                            Free Trial
                                        </router-link>
                                        <router-link
                                            :to="{name: 'Pricing'}"
                                            class="button is-primary">
                                            Purchase PeBL Pro
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div class="columns is-centered">
                            <div class="column">
                                <div
                                    @click="launchStudio()"
                                    class="box main-panel is-clickable has-text-centered">
                                    <figure class="image is-square">
                                        <img :src="getImgUrl(studioIcon)">
                                    </figure>
                                    <p class="title">
                                        Studio
                                    </p>
                                    <p class="heading">
                                        Create, edit, and publish
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div
                                    v-if="showInviteLearnersPanel"
                                    class="box main-panel is-vertical">
                                    <button
                                        v-if="!inviteLearnerCloseButtonDisabled"
                                        class="delete"
                                        @click="showInviteLearnersPanel = false" />
                                    <div v-if="loadingInvLrnDataBusy">
                                        <p class="has-text-centered">
                                            <i class="fas fa-circle-notch fa-spin" /> Loading...
                                        </p>
                                    </div>
                                    <div v-else-if="availableInvLrnEnvs.length === 0">
                                        <div class="has-text-centered">
                                            <p class="block has-text-weight-bold">
                                                No available libraries
                                            </p>
                                            <p class="block">
                                                You may need to
                                                <router-link :to="{name: 'Account Settings', params: { section: 'account' } }">
                                                    set up your account
                                                </router-link>
                                            </p>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <invite-learners
                                            :show-library-selector="true"
                                            :available-envs="availableInvLrnEnvs"
                                            :selected-available-env="selectedInvLrnEnv"
                                            @preSendInvites="disableInviteLearnersCloseButton"
                                            @postSendInvites="enableInviteLearnersCloseButton"
                                            @close="closeInviteLearners" />
                                    </div>
                                </div>
                                <div
                                    v-else
                                    @click="setUpInviteLearners(); randomizeLearnerAvatar()"
                                    class="box main-panel is-clickable has-text-centered">
                                    <figure class="image is-square is-rounded">
                                        <div
                                            :style="{ 'background-position': randomLearnerIconXY }"
                                            class="learner-avatar-grid" />
                                    </figure>
                                    <p class="title">
                                        Learners
                                    </p>
                                    <p class="heading">
                                        Invite users to your PeBLs
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div
                                    v-if="showLibrariesPanel"
                                    class="box main-panel">
                                    <button
                                        class="delete"
                                        @click="showLibrariesPanel = false" />
                                    <div v-if="loadingLaunchDataBusy">
                                        <p class="has-text-centered">
                                            <i class="fas fa-circle-notch fa-spin" /> Loading...
                                        </p>
                                    </div>
                                    <div v-else-if="availableLaunchEnvs.length === 0">
                                        <div class="has-text-centered">
                                            <p class="block has-text-weight-bold">
                                                No available libraries
                                            </p>
                                            <p class="block">
                                                You may need to
                                                <router-link :to="{name: 'Account Settings', params: { section: 'account' } }">
                                                    set up your account
                                                </router-link>
                                            </p>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <!-- <div class="block">
                                            <p class="has-text-weight-bold">
                                                Explore your libraries
                                            </p>
                                        </div> -->
                                        <div
                                            v-if="availableLaunchEnvs.length > 1"
                                            class="field">
                                            <label class="label">Library</label>
                                            <div
                                                class="control">
                                                <div class="select">
                                                    <select
                                                        v-model="selectedLaunchEnv">
                                                        <option
                                                            v-for="(env, idx) in availableLaunchEnvs"
                                                            :key="idx"
                                                            :value="env.value">
                                                            {{ env.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="buttons">
                                            <button
                                                @click="launchLibrary"
                                                class="button is-primary is-fullwidth">
                                                <img
                                                    class="icon mr-1"
                                                    src="@/assets/favicon-library.png">
                                                Launch Library
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    @click="setUpLaunchLibrary(); randomizeLibraryIcon()"
                                    class="box main-panel is-clickable has-text-centered">
                                    <figure class="image is-square">
                                        <img :src="getImgUrl(libraryIcon)">
                                    </figure>
                                    <p class="title">
                                        Libraries
                                    </p>
                                    <p class="heading">
                                        Where learners find your PeBLs
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="has-text-centered">
                            <router-link :to="{name:'Account Settings'}">
                                Account settings
                            </router-link> |
                            <router-link :to="{name:'FAQ'}">
                                Support
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import SetupSteps from '../components/setupSteps';
import InviteLearners from '../components/inviteLearners.vue';
// import WaitForAccountSetup from '../components/waitForAccountSetup.vue';
import {purchaseApi} from '../mixins/purchaseApi';

export default {
    name: 'StartScreen',
    mixins: [purchaseApi],
    components: {
        SetupSteps,
        InviteLearners
        // WaitForAccountSetup
    },
    data: () => {
        return {
            name: 'StartScreen',
            showInviteLearnersPanel: false,
            showLibrariesPanel: false,
            studioIcon: null,
            studioIconNum: 0,
            libraryIcon: null,
            libraryIconNum: 0,
            randomLearnerIconXY: '0% 0%',
            showStepsSection: false,
            accountDataLoaded: false,
            selectedInvLrnEnv: '',
            availableInvLrnEnvs: [],
            selectedLaunchEnv: '',
            availableLaunchEnvs: [],
            loadingInvLrnDataBusy: false,
            trialEnvironment: {},
            mainEnvironment: {},
            subEnvironmentList: [],
            hasTrialEnvironment: false,
            hasMainEnvironment: false,
            hasTrialEnvironmentExpired: false,
            hasMainEnvironmentExpired: false,
            inviteLearnerCloseButtonDisabled: false,
            loadingLaunchDataBusy: false
        };
    },
    metaInfo: {
        title: 'Home'
    },
    computed: {
        loggedIn() {
            return this.$store.getters.getLoggedIn;
        }
    },
    created() {
        if (this.studioIconNum === 0) {
            this.studioIconNum = Math.floor(Math.random() * 4) + 1;
            this.libraryIconNum = Math.floor(Math.random() * 4) + 1;
        }
    },
    mounted: async function() {
        this.randomizeStudioIcon();
        this.randomizeLearnerAvatar();
        this.randomizeLibraryIcon();
        if (this.loggedIn) {
            const custEnvSummary = await this.performCustomerEnvironmentSummary();
            this.determineShowStepsSection(custEnvSummary);
            await this.buildCustomerAccountData();
        }
    },
    methods: {
        disableInviteLearnersCloseButton() {
            this.inviteLearnerCloseButtonDisabled = true;
        },
        enableInviteLearnersCloseButton() {
            this.inviteLearnerCloseButtonDisabled = false;
        },
        closeInviteLearners() {
            this.showInviteLearnersPanel = false;
        },
        verifyAllAccountDataPresent(customerAccountData) {
            if (customerAccountData.mainEnvironments &&
                customerAccountData.trialEnvironments &&
                customerAccountData.subEnvironments) {
                this.accountDataLoaded = true;
            }
        },
        hasSubEnvironmentExpired(subEnv) {
            return subEnv.datePeriodExpires < Date.now();
        },
        buildAvailableEnvEntry(value, name, envId) {
            return {
                value: value,
                name: name,
                envId: envId
            };
        },
        learnerSlotsAvailable(env) {
            return env.numMaxLearners >= (env.numCurrentLearners + env.numPendingLearnerInvitations);
        },
        buildAvailableInviteLearnerEnvironments() {
            this.availableInvLrnEnvs = [];
            let selectInvLrnEnvSet = false;
            if (this.hasTrialEnvironment && !this.hasTrialEnvironmentExpired && this.learnerSlotsAvailable(this.trialEnvironment)) {
                this.availableInvLrnEnvs.push(this.buildAvailableEnvEntry('trial', this.trialEnvironment.subdomain, this.trialEnvironment.mainEnvId));
                this.selectedInvLrnEnv = 'trial';
                selectInvLrnEnvSet = true;
            }
            if (this.hasMainEnvironment && !this.hasMainEnvironmentExpired && this.learnerSlotsAvailable(this.mainEnvironment)) {
                this.availableInvLrnEnvs.push(this.buildAvailableEnvEntry('main', this.mainEnvironment.subdomain, this.mainEnvironment.mainEnvId));
                this.selectedInvLrnEnv = 'main';
                selectInvLrnEnvSet = true;
            }
            for (const se of this.subEnvironmentList) {
                if (!this.hasSubEnvironmentExpired(se) && this.learnerSlotsAvailable(se)) {
                    this.availableInvLrnEnvs.push(this.buildAvailableEnvEntry('sub-' + se.subEnvId, se.subdomain, se.subEnvId));
                    if (!selectInvLrnEnvSet) {
                        this.selectedInvLrnEnv = 'sub-' + se.subEnvId;
                        selectInvLrnEnvSet = true;
                    }
                }
            }
        },
        buildAvailableLaunchEnvironments() {
            this.availableLaunchEnvs = [];
            let selectedLaunchEnvSet = false;
            if (this.hasTrialEnvironment && !this.hasTrialEnvironmentExpired) {
                this.availableLaunchEnvs.push(this.buildAvailableEnvEntry('trial', this.trialEnvironment.subdomain, this.trialEnvironment.mainEnvId));
                this.selectedLaunchEnv = 'trial';
                selectedLaunchEnvSet = true;
            }
            if (this.hasMainEnvironment && !this.hasMainEnvironmentExpired) {
                this.availableLaunchEnvs.push(this.buildAvailableEnvEntry('main', this.mainEnvironment.subdomain, this.mainEnvironment.mainEnvId));
                this.selectedLaunchEnv = 'main';
                selectedLaunchEnvSet = true;
            }
            for (const se of this.subEnvironmentList) {
                if (!this.hasSubEnvironmentExpired(se)) {
                    this.availableLaunchEnvs.push(this.buildAvailableEnvEntry('sub-' + se.subEnvId, se.subdomain, se.subEnvId));
                    if (!selectedLaunchEnvSet) {
                        this.selectedLaunchEnv = 'sub-' + se.subEnvId;
                        selectedLaunchEnvSet = true;
                    }
                }
            }
        },
        parseCustomerAccountData(customerAccountData) {
            this.subEnvironmentList = customerAccountData.subEnvironments;
            if (customerAccountData.mainEnvironments.length > 0) {
                this.hasMainEnvironment = true;
                this.mainEnvironment = customerAccountData.mainEnvironments[0];
                this.hasMainEnvironmentExpired = this.mainEnvironment.datePeriodExpires < Date.now();
                this.hasTrialEnvironment = false;
            } else {
                this.hasMainEnvironment = false;
                this.mainEnvironment = {};
                if (customerAccountData.trialEnvironments.length > 0) {
                    this.hasTrialEnvironment = true;
                    this.trialEnvironment = customerAccountData.trialEnvironments[0];
                    this.hasTrialEnvironmentExpired = this.trialEnvironment.datePeriodExpires < Date.now();
                } else {
                    this.hasTrialEnvironment = false;
                    this.trialEnvironment = {};
                }
            }
            this.buildAvailableLaunchEnvironments();
            this.buildAvailableInviteLearnerEnvironments();
        },
        async buildCustomerAccountData() {
            this.accountDataLoaded = false;
            const customerAccountData = await this.performCustomerAccountDataSearch();
            this.verifyAllAccountDataPresent(customerAccountData);
            if (this.accountDataLoaded) {
                this.parseCustomerAccountData(customerAccountData);
            }
        },
        async setUpInviteLearners() {
            if (this.loggedIn) {
                this.showInviteLearnersPanel = true;
                this.loadingInvLrnDataBusy = true;
                if (!this.accountDataLoaded) {
                    await this.buildCustomerAccountData();
                }
                this.loadingInvLrnDataBusy = false;
            }
        },
        async setUpLaunchLibrary() {
            if (this.loggedIn) {
                this.showLibrariesPanel = true;
                this.loadingLaunchDataBusy = true;
                if (!this.accountDataLoaded) {
                    await this.buildCustomerAccountData();
                }
                this.loadingLaunchDataBusy = false;
            }
        },
        launchStudio: function() {
            if (this.hasMainEnvironment) window.open('https://' + this.mainEnvironment.subdomain + '.pebl.pro/studio', '_blank');
            else if (this.hasTrialEnvironment) window.open('https://' + this.trialEnvironment.subdomain + '.pebl.pro/studio', '_blank');
        },
        getSubEnvironmentById(subEnvId) {
            for (const se of this.subEnvironmentList) {
                if (se.subEnvId === subEnvId) return se;
            }
            return null;
        },
        launchLibrary: function() {
            if (this.selectedLaunchEnv) {
                if (this.selectedLaunchEnv === 'main') {
                    window.open('https://' + this.mainEnvironment.subdomain + '.pebl.pro', '_blank');
                } else if (this.selectedLaunchEnv === 'trial') {
                    window.open('https://' + this.trialEnvironment.subdomain + '.pebl.pro', '_blank');
                } else if (this.selectedLaunchEnv.startsWith('sub-')) {
                    const subEnvId = parseInt(this.selectedLaunchEnv.replace('sub-', ''));
                    const subEnv = this.getSubEnvironmentById(subEnvId);
                    if (subEnv) window.open('https://' + subEnv.subdomain + '.pebl.pro', '_blank');
                }
            }
        },
        // launchModeration: function() {
        //     // TODO: Launch selected library to show MODERATION dashboard
        //     // REPLACE this link!
        //     // window.open('https://qa.author.pebl.pro/?', '_blank');
        //     var temp = confirm('TO DO: Launch the Moderation Dashboard for the selected library. \r\nOpen placeholder link?');
        //     if (temp) {
        //         window.open('https://qa.author.pebl.pro/?', '_blank');
        //     }
        // },
        // launchActivity: function() {
        //     // TODO: Launch selected library to show ACTIVITY dashboard
        //     // Check that subscription includes 'Activity Dashboard' (disabled for Trial and Basic)
        //     // REPLACE this link!
        //     // window.open('https://qa.author.pebl.pro/?', '_blank');
        // },
        determineShowStepsSection(ces) {
            if (ces && ces.status && ces.status === 'ok') {
                this.showStepsSection = (
                    !ces.trialExists &&
                    !ces.mainTokenExists &&
                    !ces.activeNonTrialMainsExist &&
                    !ces.expiredNonTrialMainsExist
                );
            } else {
                // this is bad....
                this.showStepsSection = true;
            }
        },
        // async buildTrialAttributes() {
        //     const ta = this.$store.getters.getTrialAttributes;
        //     if (ta) this.trialAttributes = ta;
        //     else {
        //         this.trialAttributes = await this.performTrialAttributesSearch();
        //         this.$store.commit('setTrialAttributes', this.trialAttributes);
        //     }
        // },
        // checkAccountSetupStatus: function() {
        //     // TODO: Replace with real account status check
        //     let accountSetup = true;
        //     // this.mode = 'setupPending';
        // },
        getImgUrl(pic) {
            if (!pic) return;
            return require('@/assets/' + pic);
        },
        randomizeLearnerAvatar: function() {
            const x1 = -64;
            const y1 = -100;
            const w = 300 / 4;
            const h = 443 / 3;
            const x = x1 + w * (Math.floor(Math.random() * 4) + 1);
            const y = y1 + h * (Math.floor(Math.random() * 3) + 1);
            const offset = x + '% ' + y + '%';
            this.randomLearnerIconXY = offset;
        },
        randomizeLibraryIcon: function() {
            this.libraryIconNum++;
            if (this.libraryIconNum > 4) this.libraryIconNum = 1;
            this.libraryIcon = 'home-library-' + this.libraryIconNum + '.png';
        },
        randomizeStudioIcon: function() {
            this.studioIconNum++;
            if (this.studioIconNum > 4) this.studioIconNum = 1;
            this.studioIcon = 'home-studio-' + this.studioIconNum + '.png';
        }
    }
};
</script>

<style scoped>

</style>
