<template>
    <div
        class="link-box"
        :class="{'is-draft':!interaction.published_at}">
        <router-link
            :to="{path:'/training/' + interaction.url}">
            <div class="columns is-mobile is-vcentered">
                <div class="column is-narrow">
                    <div class="interaction-icon">
                        <img
                            v-if="interaction.icon"
                            :src="interaction.icon.url">
                    </div>
                </div>
                <div class="column">
                    <p
                        class="link-title">
                        {{ interaction.name }}
                    </p>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'Interaction',
    props: ['id'],
    data: () => {
        return {
            interaction: []
        };
    },
    mounted() {
        // get strapi data as needed
        if (this.$store.state.interactionDocs.length < 1) {
            this.$store.dispatch('getInteractionDocs');
        }
        this.init();
    },
    watch: {
        // refresh if data loads
        '$store.state.interactionDocs': function() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.interaction.length < 1) {
                const id = this.id;
                var items = this.$store.state.interactionDocs;
                this.interaction = items.filter(function(elem) {
                    if (elem.id === id) return elem;
                })[0];
            }
        }
    }
};
</script>
