<template>
    <div
        role="main"
        class="mb-6">
        <!-- <breadcrumbs /> -->
        <section class="hero">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <h1 class="title">
                        FAQ
                    </h1>
                    <p class="subtitle">
                        Find answers to commonly asked questions.
                    </p>
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div class="columns is-centered">
                    <div class="column is-narrow">
                        <div class="block">
                            <div class="field has-addons">
                                <div class="control has-icons-left">
                                    <input
                                        v-model="searchQuery"
                                        class="input"
                                        type="text"
                                        placeholder="Search">
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-search" />
                                    </span>
                                </div>
                                <div
                                    v-if="searchQuery"
                                    class="control">
                                    <button
                                        @click="searchQuery=''"
                                        class="button is-light">
                                        <span class="icon is-small">
                                            <i class="fas fa-times" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <aside
                            id="faq-categories"
                            class="menu">
                            <p class="menu-label">
                                Topics
                            </p>
                            <ul class="menu-list">
                                <li
                                    v-for="(category,index) in categories"
                                    :key="index">
                                    <a
                                        @click="selectCategory(category)"
                                        :class="{'is-active':category.name === selectedCategory.name}">
                                        {{ category.name }}
                                    </a>
                                </li>
                            </ul>
                        </aside>
                    </div>

                    <div class="column is-two-thirds">
                        <div
                            v-if="Object.keys(selectedCategory).length === 0 && searchQuery.length <3"
                            class="splash-image">
                            <img
                                class="crisp"
                                src="@/assets/FAQ-group.png">
                        </div>

                        <div v-else>
                            <h5 class="title is-5 pt-2">
                                <span v-if="searchQuery.length > 2">Search Results</span>
                                <span v-else-if="Object.keys(selectedCategory).length > 0">{{ selectedCategory.name }}</span>
                            </h5>
                            <div class="block">
                                <p
                                    v-if="faqs.length < 1">
                                    No matches found. <br>Try searching for different terms or click on one of the topics.
                                </p>
                            </div>
                            <div class="block">
                                <div
                                    v-for="(faq,index) in faqs"
                                    :key="index +'_'+ faq.id"
                                    class="faq-block"
                                    :class="{'is-selected':expandedFaqs.includes(index +'_'+ faq.id),'is-draft':faq.published_at === null}">
                                    <label
                                        class="faq-question"
                                        :for="index +'_'+ faq.id">
                                        <i
                                            v-if="expandedFaqs.includes(index +'_'+ faq.id)"
                                            class="fa-fw fas fa-caret-down" />
                                        <i
                                            v-else
                                            class="fa-fw fas fa-caret-right" />
                                        {{ faq.question }}
                                    </label>
                                    <input
                                        v-model="expandedFaqs"
                                        :value="index +'_'+ faq.id"
                                        class="faq-question-toggle"
                                        type="checkbox"
                                        :id="index +'_'+ faq.id">
                                    <div class="faq-answer content ml-5">
                                        <vue-markdown-plus
                                            :anchor-attributes="{
                                                target:'_blank'
                                            }"
                                            :scr="strapiApiUrl">
                                            {{ faq.answer }}
                                        </vue-markdown-plus>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
import VueMarkdownPlus from 'vue-markdown-plus';
// import breadcrumbs from '../components/breadcrumbs.vue';

export default {
    name: 'FAQ',
    components: {
        'vue-markdown-plus': VueMarkdownPlus
        // breadcrumbs
    },
    data: () => {
        return {
            searchQuery: '',
            categories: [],
            selectedCategory: {},
            expandedFaqs: []
        };
    },
    metaInfo: {
        title: 'FAQ'
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        faqs() {
            if (this.searchQuery.length > 2) {
                var results = [];
                // check each search word against every word in faq question, answer text, and category name
                // TODO: once the list gets too long add search by word stemming and related terms.
                const words = this.searchQuery.toLowerCase().split(' ');
                this.categories.forEach((category) => {
                    category.faqs.forEach((faq) => {
                        var faqWords = faq.question + ' ' + faq.answer + ' ' + category.name;
                        faqWords = faqWords.replace(/['!"#$%&\\'()*+,\-./:;<=>?@[\\\]^_`{|}~']/g, '');
                        faqWords = faqWords.toLowerCase();
                        if (
                            words.some(r => faqWords.includes(r)) &&
                            !results.some(e => e.question === faq.question)
                        ) results.push(faq);
                    });
                });
                return results;
            } else if (Object.keys(this.selectedCategory).length > 0) {
                return this.selectedCategory.faqs;
            }
            return this.$store.state.faqs;
        }
    },
    mounted() {
        if (this.$store.state.faqs.length < 1) this.$store.dispatch('getFaqs');
        if (this.categories.length < 1) this.setUpCategories();
    },
    watch: {
        searchQuery: function() {
            if (this.searchQuery.length > 2) {
                this.selectedCategory = {};
            }
        },
        '$store.state.faqs': function() {
            if (this.categories.length < 1) this.setUpCategories();
        }
    },
    methods: {
        selectCategory: function(category) {
            // console.log(category);
            this.searchQuery = '';
            this.selectedCategory = category;
        },
        setUpCategories: function() {
            // get a list of categories
            // Some FAQs can belong to more than 1 category.
            this.categories = [];
            this.$store.state.faqs.forEach((faq) => {
                if (faq.faq_categories) {
                    faq.faq_categories.forEach((myCategory) => {
                        // fix strapi urls
                        // faq.answer = faq.answer.split('(/uploads/').join(`(${this.strapiApiUrl}/uploads/`);
                        if (this.categoryExists(myCategory.category)) {
                            var oldCategory = this.categories.find(element => element.name === myCategory.category);
                            oldCategory.faqs.push(faq);
                        } else {
                            var newCategory = {
                                name: myCategory.category,
                                faqs: [faq]
                            };
                            this.categories.push(newCategory);
                        }
                    });
                }
            });
            // sort alphabetically by name
            this.categories.sort(function(a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        },
        categoryExists: function(newCategory) {
            var match = false;
            this.categories.forEach((oldCategory) => {
                if (oldCategory.name === newCategory) match = true;
                // console.log(oldCategory.name + ' = ' + match + ' = ' + newCategory);
            });
            return match;
        },
        faqUid: function(category, faq) {
            return category.name + ' #' + faq.id;
        },
        notEmpty(data) {
            // check if optional strapi data item has a value
            if (!data) return false;
            return true;
        },
        getImgUrl(pic) {
            if (!pic) return;
            return require('../assets/' + pic);
        }
    }
};
</script>


