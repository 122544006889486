<template>
    <!-- Widget to validate subdomain input -->
    <div id="chooseSubdomain">
        <!-- <p class="mb-4">
            <strong>Choose a subdomain name for your new library.</strong>
            Enter a name and check that it's still available.
            Once you create your library, you cannot change your subdomain name.
        </p> -->
        <div class="field">
            <label
                class="label"
                for="subdomainField">
                Subdomain
            </label>
            <div class="field-body">
                <div class="field is-expanded">
                    <div class="field has-addons">
                        <p class="control has-icons-left is-expanded">
                            <input
                                id="subdomainField"
                                v-model="requestedSubdomain"
                                type="text"
                                class="input"
                                :class="{ 'is-danger': subdomainInvalid || subdomainUnavailable, 'is-success': validSubdomainAvailable || subdomainReserved }"
                                :disabled="validatingSubdomain || reservingSubdomain || disabled"
                                placeholder="">
                            <span class="icon is-small is-left">
                                <i class="fas fa-globe" />
                            </span>
                        </p>
                        <p
                            v-if="isForReservation"
                            class="control">
                            <button
                                @click="attemptReserveSubdomain"
                                class="button is-fullwidth"
                                :disabled="disabled"
                                :class="{
                                    'is-loading':reservingSubdomain,
                                    'is-success is-outlined':subdomainReserved,
                                    'is-danger':subdomainInvalid || subdomainUnavailable
                                }">
                                Reserve
                            </button>
                        </p>
                        <p
                            v-else
                            class="control">
                            <button
                                @click="attemptValidateSubdomain"
                                class="button is-fullwidth"
                                :disabled="disabled"
                                :class="{
                                    'is-loading':validatingSubdomain,
                                    'is-success is-outlined':validSubdomainAvailable,
                                    'is-danger':subdomainInvalid || subdomainUnavailable
                                }">
                                Check availability
                            </button>
                        </p>
                    </div>
                    <p class="help">
                        Subdomains must be between {{ SUBDOMAIN_MIN }} and {{ SUBDOMAIN_MAX }} characters in length, must begin with an alphanumeric character,
                        and may only contain alphanumerics and hyphens.
                    </p>
                </div>
            </div>
        </div>
        <div v-if="(validSubdomainAvailable || subdomainReserved) && !subdomainInvalid && !subdomainUnavailable">
            <div class="notification is-success is-light">
                <p v-if="isForReservation">
                    Your subdomain has been reserved until {{ subdomainReservedExpires }}.
                    <i class="far fa-check-circle is-ghost" />
                </p>
                <p v-else>
                    That subdomain is available.
                    <i class="far fa-check-circle is-ghost" />
                </p>
                <br>
                <p>Your url will look like this: <strong>{{ requestedSubdomain }}.pebl.pro</strong></p>
                <!-- <p>Click the button below to claim this subdomain.</p> -->
            </div>
        </div>
        <div v-if="subdomainUnavailable">
            <div class="notification is-danger is-light">
                <p>
                    <strong>{{ requestedSubdomain }}</strong> is not available.
                    <i class="fas fa-exclamation-triangle is-ghost" />
                </p>
            </div>
        </div>
        <div v-if="subdomainInvalid">
            <div class="notification is-danger is-light">
                <p>
                    <strong>{{ requestedSubdomain }}</strong> is not valid.
                    <i class="fas fa-exclamation-triangle is-ghost" />
                </p>
                <p>
                    It must be between {{ SUBDOMAIN_MIN }} and {{ SUBDOMAIN_MAX }} characters in length, must begin with an alphanumeric character,
                    and may only contain alphanumerics and hyphens.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {purchaseApi} from '../mixins/purchaseApi';
import {appUtil} from '../mixins/appUtil';

export default {
    name: 'ChooseSubdomain',
    mixins: [purchaseApi, appUtil],
    props: [
        'disabled',
        'isForReservation'
    ],
    data: () => {
        return {
            requestedSubdomain: '',
            appReservedSubdomains: [],
            subdomainInvalid: false,
            validatingSubdomain: false,
            subdomainUnavailable: false,
            validSubdomainAvailable: false,
            errorMsg: '',
            reservingSubdomain: false,
            subdomainReserved: false,
            subdomainReservedExpires: ''
        };
    },
    watch: {
        'requestedSubdomain'() {
            this.reset();
        }
    },
    methods: {
        async reserveSubdomain() {
            this.subdomainReservedExpires = '';
            const rso = await this.performReserveSubdomain(this.requestedSubdomain);
            if (rso.status === 'ok') {
                if (!rso.isValid) this.subdomainInvalid = true;
                else if (!rso.isAvailable) this.subdomainUnavailable = true;
                else if (rso.reserved) {
                    console.log(rso);
                    this.subdomainReserved = true;
                    this.subdomainReservedExpires = this.utilDateTimeStringFromMilliseconds(rso.dateExpires);
                }
            } else this.subdomainUnavailable = true;
        },
        async attemptReserveSubdomain() {
            this.$emit('preReserving');
            this.requestedSubdomain = this.requestedSubdomain.toLowerCase().trim();
            this.subdomainInvalid = false;
            this.subdomainUnavailable = false;
            this.subdomainReserved = false;
            const stillLoggedIn = await this.verifyStillLoggedIn();
            if (stillLoggedIn) {
                this.reservingSubdomain = true;
                if (this.isValidSubdomain(this.requestedSubdomain)) {
                    const sdReserved = await this.isSubdomainReservedByApp();
                    if (sdReserved) {
                        this.subdomainUnavailable = true;
                    } else await this.reserveSubdomain();
                } else this.subdomainInvalid = true;
                this.reservingSubdomain = false;
            }
            this.$emit('postReserving');
        },
        sendResult: function() {
            let validity = false;
            if (this.validSubdomainAvailable &&
                !this.subdomainInvalid &&
                !this.subdomainUnavailable
            ) validity = true;
            this.$emit('result', {
                isValid: validity,
                subdomain: this.requestedSubdomain
            });
        },
        reset: function() {
            this.subdomainInvalid = false;
            this.validatingSubdomain = false;
            this.subdomainUnavailable = false;
            this.validSubdomainAvailable = false;
            this.errorMsg = '';
            this.reservingSubdomain = false;
            this.subdomainReserved = false;
            this.subdomainReservedExpires = '';
            this.sendResult();
        },
        async attemptValidateSubdomain() {
            this.subdomainInvalid = false;
            this.errorMsg = '';
            this.requestedSubdomain = this.requestedSubdomain.toLowerCase().trim();
            const stillLoggedIn = await this.verifyStillLoggedIn();
            if (stillLoggedIn) {
                this.validatingSubdomain = true;
                this.subdomainUnavailable = false;
                this.validSubdomainAvailable = false;
                if (this.isValidSubdomain(this.requestedSubdomain)) {
                    const sdReserved = await this.isSubdomainReservedByApp();
                    if (sdReserved) this.subdomainUnavailable = true;
                    else await this.checkSubdomainAvailability();
                } else this.subdomainInvalid = true;
                this.validatingSubdomain = false;
                this.sendResult();
            }
        },
        async isSubdomainReservedByApp() {
            if (this.appReservedSubdomains.length <= 0) {
                this.appReservedSubdomains = await this.performGetAppReservedSubdomains();
            }
            return this.appReservedSubdomains.includes(this.requestedSubdomain);
        },
        async checkSubdomainAvailability() {
            const rso = await this.performSubdomainAvailable(this.requestedSubdomain);
            if (rso.status === 'ok') {
                if (!rso.isValid) this.subdomainInvalid = true;
                else if (!rso.isAvailable) this.subdomainUnavailable = true;
                else this.validSubdomainAvailable = true;
            } else this.subdomainUnavailable = true;
            this.validatingSubdomain = false;
        },
        async verifyStillLoggedIn() {
            const profile = await this.getSimpleUserProfileFromApi();
            if (profile) return true;
            else return false;
        }
    }
};
</script>
