<template>
    <div role="main">
        <section class="hero is-fullheight-with-navbar">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <p class="subtitle">
                        Canceling...
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import {purchaseApi} from '../mixins/purchaseApi';

export default {
    name: 'CheckoutCanceled',
    mixins: [purchaseApi],
    data: () => {
        return {
        };
    },
    metaInfo: {
        title: 'Checkout Canceled'
    },
    mounted: async function() {
        const profile = await this.getSimpleUserProfileFromApi();
        if (profile) {
            this.$store.commit('setLoggedIn', true);
            this.$store.commit('setCustomerProfile', profile);
            this.$router.push({name: 'Pricing'});
        } else {
            this.performExternalLogin(this.LOGIN_REDIRECT_URL_PRICING);
        }
    },
    created() {
    },
    methods: {
    }
};
</script>

<style scoped>

</style>
