var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"chooseSubdomain"}},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":"subdomainField"}},[_vm._v(" Subdomain ")]),_c('div',{staticClass:"field-body"},[_c('div',{staticClass:"field is-expanded"},[_c('div',{staticClass:"field has-addons"},[_c('p',{staticClass:"control has-icons-left is-expanded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.requestedSubdomain),expression:"requestedSubdomain"}],staticClass:"input",class:{ 'is-danger': _vm.subdomainInvalid || _vm.subdomainUnavailable, 'is-success': _vm.validSubdomainAvailable || _vm.subdomainReserved },attrs:{"id":"subdomainField","type":"text","disabled":_vm.validatingSubdomain || _vm.reservingSubdomain || _vm.disabled,"placeholder":""},domProps:{"value":(_vm.requestedSubdomain)},on:{"input":function($event){if($event.target.composing){ return; }_vm.requestedSubdomain=$event.target.value}}}),_vm._m(0)]),(_vm.isForReservation)?_c('p',{staticClass:"control"},[_c('button',{staticClass:"button is-fullwidth",class:{
                                'is-loading':_vm.reservingSubdomain,
                                'is-success is-outlined':_vm.subdomainReserved,
                                'is-danger':_vm.subdomainInvalid || _vm.subdomainUnavailable
                            },attrs:{"disabled":_vm.disabled},on:{"click":_vm.attemptReserveSubdomain}},[_vm._v(" Reserve ")])]):_c('p',{staticClass:"control"},[_c('button',{staticClass:"button is-fullwidth",class:{
                                'is-loading':_vm.validatingSubdomain,
                                'is-success is-outlined':_vm.validSubdomainAvailable,
                                'is-danger':_vm.subdomainInvalid || _vm.subdomainUnavailable
                            },attrs:{"disabled":_vm.disabled},on:{"click":_vm.attemptValidateSubdomain}},[_vm._v(" Check availability ")])])]),_c('p',{staticClass:"help"},[_vm._v(" Subdomains must be between "+_vm._s(_vm.SUBDOMAIN_MIN)+" and "+_vm._s(_vm.SUBDOMAIN_MAX)+" characters in length, must begin with an alphanumeric character, and may only contain alphanumerics and hyphens. ")])])])]),((_vm.validSubdomainAvailable || _vm.subdomainReserved) && !_vm.subdomainInvalid && !_vm.subdomainUnavailable)?_c('div',[_c('div',{staticClass:"notification is-success is-light"},[(_vm.isForReservation)?_c('p',[_vm._v(" Your subdomain has been reserved until "+_vm._s(_vm.subdomainReservedExpires)+". "),_c('i',{staticClass:"far fa-check-circle is-ghost"})]):_c('p',[_vm._v(" That subdomain is available. "),_c('i',{staticClass:"far fa-check-circle is-ghost"})]),_c('br'),_c('p',[_vm._v("Your url will look like this: "),_c('strong',[_vm._v(_vm._s(_vm.requestedSubdomain)+".pebl.pro")])])])]):_vm._e(),(_vm.subdomainUnavailable)?_c('div',[_c('div',{staticClass:"notification is-danger is-light"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.requestedSubdomain))]),_vm._v(" is not available. "),_c('i',{staticClass:"fas fa-exclamation-triangle is-ghost"})])])]):_vm._e(),(_vm.subdomainInvalid)?_c('div',[_c('div',{staticClass:"notification is-danger is-light"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.requestedSubdomain))]),_vm._v(" is not valid. "),_c('i',{staticClass:"fas fa-exclamation-triangle is-ghost"})]),_c('p',[_vm._v(" It must be between "+_vm._s(_vm.SUBDOMAIN_MIN)+" and "+_vm._s(_vm.SUBDOMAIN_MAX)+" characters in length, must begin with an alphanumeric character, and may only contain alphanumerics and hyphens. ")])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-small is-left"},[_c('i',{staticClass:"fas fa-globe"})])}]

export { render, staticRenderFns }