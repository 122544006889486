<template>
    <div
        id="settings"
        role="main">
        <!-- See Readme for Account Settings token logic and Var explainer -->

        <!-- modals -->

        <!-- modal - confirm delete learner or invitation -->
        <div
            class="modal"
            :class="{ 'is-active': envLrnInvRecordRemoveConfirmModalActive }">
            <div class="modal-background" />
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Delete learner
                        <span v-if="envLrnInvRecordToRemove && envLrnInvRecordToRemove.type === 'invite'"> invitation</span>?
                    </p>
                    <button
                        @click="closeEnvLrnInvRecordDeleteConfirm"
                        class="delete"
                        aria-label="close" />
                </header>
                <section class="modal-card-body">
                    <p v-if="envLrnInvRecordToRemove && envLrnInvRecordToRemove.type === 'invite'">
                        Are you sure you wish to delete the invitation for learner
                        <strong v-if="envLrnInvRecordToRemove">{{ envLrnInvRecordToRemove.email }}</strong>
                        from this library?
                    </p>
                    <p v-else>
                        Are you sure you wish to delete learner
                        <strong v-if="envLrnInvRecordToRemove">{{ envLrnInvRecordToRemove.email }}</strong> from this library?
                    </p>
                </section>
                <footer class="modal-card-foot">
                    <div v-if="envLrnInvRecordRemoveConfirmBusy">
                        <i class="fa-fw fas fa-circle-notch fa-spin" /> Deleting...
                    </div>
                    <div
                        v-else
                        class="buttons is-right">
                        <article
                            v-if="envLrnInvRecordRemoveConfirmRetMsg !== ''"
                            class="message is-warning is-small mt-4">
                            <div class="message-body">
                                <i class="fas fa-exclamation-triangle" /> {{ envLrnInvRecordRemoveConfirmRetMsg }}
                            </div>
                        </article>
                        <button
                            @click="closeEnvLrnInvRecordDeleteConfirm"
                            class="button is-text">
                            Cancel
                        </button>
                        <button
                            @click="removeLearnerOrInviteFromEnvironment"
                            class="button is-primary">
                            Delete
                        </button>
                    </div>
                </footer>
            </div>
            <button
                @click="closeEnvLrnInvRecordDeleteConfirm"
                class="modal-close is-large"
                aria-label="close" />
        </div>

        <!-- modal - confirm cancel main env -->
        <div
            class="modal"
            :class="{ 'is-active': cancelMainEnvConfirmModalActive }">
            <div class="modal-background" />
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Cancel library subscription?
                    </p>
                    <button
                        @click="closeCancelMainEnvConfirmModal"
                        class="delete"
                        aria-label="close" />
                </header>
                <section class="modal-card-body">
                    <p>
                        Your library subscription will expire on
                        <span class="has-text-weight-bold">{{ utilDateStringFromMilliseconds(mainEnvironment.datePeriodExpires) }}</span> and not be renewed.
                    </p>
                    <p class="mt-3">
                        Inactive subscriptions and associated data are purged from our environment after
                        <span class="has-text-weight-bold">{{ INACTIVE_PURGE_DAYS }}</span> days.
                        Any associated library additions will also be canceled.
                    </p>
                    <p class="has-text-weight-bold mt-3">
                        You can reactivate your subscription at any time before it expires but you will need to manually reactive your library additions.
                    </p>
                </section>
                <footer class="modal-card-foot">
                    <div v-if="cancelMainEnvConfirmModalBusy">
                        <i class="fa-fw fas fa-circle-notch fa-spin" /> Canceling...
                    </div>
                    <div
                        v-else
                        class="buttons is-right">
                        <article
                            v-if="cancelMainEnvConfirmRetMsg !== ''"
                            class="message is-warning is-small mt-4">
                            <div class="message-body">
                                <i class="fas fa-exclamation-triangle" /> {{ cancelMainEnvConfirmRetMsg }}
                            </div>
                        </article>
                        <button
                            @click="closeCancelMainEnvConfirmModal"
                            class="button is-text">
                            No
                        </button>
                        <button
                            @click="cancelSubscriptionForMainEnv"
                            class="button is-primary">
                            Yes
                        </button>
                    </div>
                </footer>
            </div>
            <button
                @click="closeCancelMainEnvConfirmModal"
                class="modal-close is-large"
                aria-label="close" />
        </div>

        <!-- modal - confirm reactivate main env -->
        <div
            class="modal"
            :class="{ 'is-active': reactivateMainEnvConfirmModalActive }">
            <div class="modal-background" />
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Reactivate library subscription?
                    </p>
                    <button
                        @click="closeReactivateMainEnvConfirmModal"
                        class="delete"
                        aria-label="close" />
                </header>
                <section class="modal-card-body">
                    <p>
                        Your library subscription will be reactivated and you will be billed on
                        <span class="has-text-weight-bold">{{ utilDateStringFromMilliseconds(mainEnvironment.datePeriodExpires) }}</span>.
                    </p>
                    <p class="has-text-weight-bold mt-3">
                        You will need to manually reactive any associated library additions that you wish to keep active.
                    </p>
                </section>
                <footer class="modal-card-foot">
                    <div v-if="reactivateMainEnvConfirmModalBusy">
                        <i class="fa-fw fas fa-circle-notch fa-spin" /> Reactivating...
                    </div>
                    <div
                        v-else
                        class="buttons is-right">
                        <article
                            v-if="reactivateMainEnvConfirmRetMsg !== ''"
                            class="message is-warning is-small mt-4">
                            <div class="message-body">
                                <i class="fas fa-exclamation-triangle" /> {{ reactivateMainEnvConfirmRetMsg }}
                            </div>
                        </article>
                        <button
                            @click="closeReactivateMainEnvConfirmModal"
                            class="button is-text">
                            Cancel
                        </button>
                        <button
                            @click="reactivateSubscriptionForMainEnv"
                            class="button is-primary">
                            Reactivate
                        </button>
                    </div>
                </footer>
            </div>
            <button
                @click="closeReactivateMainEnvConfirmModal"
                class="modal-close is-large"
                aria-label="close" />
        </div>


        <!-- modal - confirm cancel sub env -->
        <div
            class="modal"
            :class="{ 'is-active': cancelSubEnvConfirmModalActive }">
            <div class="modal-background" />
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Cancel library addition subscription?
                    </p>
                    <button
                        @click="closeCancelSubEnvConfirmModal"
                        class="delete"
                        aria-label="close" />
                </header>
                <section class="modal-card-body">
                    <p>
                        Your library addition subscription will expire on
                        <span class="has-text-weight-bold">{{ utilDateStringFromMilliseconds(selectedSubEnvironment.datePeriodExpires) }}</span> and not be renewed.
                    </p>
                    <p class="mt-3">
                        Inactive subscriptions and associated data are purged from our environment after
                        <span class="has-text-weight-bold">{{ INACTIVE_PURGE_DAYS }}</span> days.
                    </p>
                </section>
                <footer class="modal-card-foot">
                    <div v-if="cancelSubEnvConfirmModalBusy">
                        <i class="fa-fw fas fa-circle-notch fa-spin" /> Canceling...
                    </div>
                    <div
                        v-else
                        class="buttons is-right">
                        <article
                            v-if="cancelSubEnvConfirmRetMsg !== ''"
                            class="message is-warning is-small mt-4">
                            <div class="message-body">
                                <i class="fas fa-exclamation-triangle" /> {{ cancelSubEnvConfirmRetMsg }}
                            </div>
                        </article>
                        <button
                            @click="closeCancelSubEnvConfirmModal"
                            class="button is-text">
                            No
                        </button>
                        <button
                            @click="cancelSubscriptionForSubEnv"
                            class="button is-primary">
                            Yes
                        </button>
                    </div>
                </footer>
            </div>
            <button
                @click="closeCancelSubEnvConfirmModal"
                class="modal-close is-large"
                aria-label="close" />
        </div>

        <!-- modal - confirm reactivate sub env -->
        <div
            class="modal"
            :class="{ 'is-active': reactivateSubEnvConfirmModalActive }">
            <div class="modal-background" />
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Reactivate library addition subscription?
                    </p>
                    <button
                        @click="closeReactivateSubEnvConfirmModal"
                        class="delete"
                        aria-label="close" />
                </header>
                <section class="modal-card-body">
                    <p>
                        Your library addition subscription will be reactivated and you will be billed on
                        <span class="has-text-weight-bold">{{ utilDateStringFromMilliseconds(selectedSubEnvironment.datePeriodExpires) }}</span>.
                    </p>
                </section>
                <footer class="modal-card-foot">
                    <div v-if="reactivateSubEnvConfirmModalBusy">
                        <i class="fa-fw fas fa-circle-notch fa-spin" /> Reactivating...
                    </div>
                    <div
                        v-else
                        class="buttons is-right">
                        <article
                            v-if="reactivateSubEnvConfirmRetMsg !== ''"
                            class="message is-warning is-small mt-4">
                            <div class="message-body">
                                <i class="fas fa-exclamation-triangle" /> {{ reactivateSubEnvConfirmRetMsg }}
                            </div>
                        </article>
                        <button
                            @click="closeReactivateSubEnvConfirmModal"
                            class="button is-text">
                            Cancel
                        </button>
                        <button
                            @click="reactivateSubscriptionForSubEnv"
                            class="button is-primary">
                            Reactivate
                        </button>
                    </div>
                </footer>
            </div>
            <button
                @click="closeReactivateSubEnvConfirmModal"
                class="modal-close is-large"
                aria-label="close" />
        </div>

        <!-- modal - redeem token -->
        <div
            class="modal"
            :class="{ 'is-active': redeemTokenModalActive }">
            <div class="modal-background" />
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Set up subscription
                    </p>
                    <button
                        @click="cancelRedeemEnvironmentToken"
                        class="delete"
                        aria-label="close" />
                </header>
                <section class="modal-card-body">
                    <div v-if="!redeemTokenTokenSelected">
                        <ul class="menu-list">
                            <li
                                v-for="et in environmentTokenList"
                                :key="et.envTokenId">
                                <a @click="setSelectedTokenToRedeem(et)">
                                    <i class="icon-next fas fa-chevron-right" />
                                    <div
                                        class="column is-12"
                                        :title="getReadableEnvironmentTokenProductType(et)">
                                        <p class="has-text-weight-bold">
                                            <i
                                                v-if="isEnvironmentTokenMainType(et)"
                                                class="fas fa-folder" />
                                            <i
                                                v-else
                                                class="fas fa-folder-plus" />
                                            {{ getProductNameForEnvironmentToken(et) }}
                                        </p>
                                        <!--<p class="is-italic is-size-7">{{  }}</p>-->
                                        <p class="is-size-7">
                                            <span class="tag mr-1">
                                                <span class="has-text-weight-bold">learners:</span> {{ et.numMaxLearners }}
                                            </span>
                                            <span class="tag mr-1">
                                                <span class="has-text-weight-bold">
                                                    <span v-if="isEnvironmentTokenMainType(et)">
                                                        additional
                                                    </span>
                                                    authoring storage:
                                                </span>
                                                {{ et.authoringStorage }}
                                            </span>
                                            <span class="tag">
                                                <span class="has-text-weight-bold">bookshelf storage:</span> {{ et.bookshelfStorage }}
                                            </span>
                                        </p>
                                        <p class="is-size-7 mt-3">
                                            <span class="has-text-weight-bold">purchase date:</span>
                                            {{ utilDateTimeStringFromMilliseconds(et.datePurchased) }}
                                        </p>
                                        <p class="is-size-7">
                                            <span class="has-text-weight-bold">expiration date:</span>
                                            {{ utilDateTimeStringFromMilliseconds(et.datePeriodExpires) }}
                                        </p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <!--main environment token for trial-->
                        <div v-if="isEnvironmentTokenMainType(redeemTokenSelectedToken) && hasTrialEnvironment">
                            <p>Upgrade your trial to a subscription with the following specifications:</p>
                            <ul class="pl-5 mt-2">
                                <li>
                                    <span class="has-text-weight-bold">Learners:</span> {{ redeemTokenSelectedToken.numMaxLearners }}
                                </li>
                                <li>
                                    <span class="has-text-weight-bold">Authoring Storage:</span> {{ redeemTokenSelectedToken.authoringStorage }}
                                </li>
                                <li>
                                    <span class="has-text-weight-bold">Bookshelf Storage:</span> {{ redeemTokenSelectedToken.bookshelfStorage }}
                                </li>
                            </ul>
                        </div>
                        <!--main environment token for expired main environment-->
                        <div v-else-if="isEnvironmentTokenMainType(redeemTokenSelectedToken) && hasMainEnvironmentExpired">
                            <p>Update an expired authoring environment with the following specifications:</p>
                            <ul class="pl-5 mt-2">
                                <li>
                                    <span class="has-text-weight-bold">Learners:</span> {{ redeemTokenSelectedToken.numMaxLearners }}
                                </li>
                                <li>
                                    <span class="has-text-weight-bold">Authoring Storage:</span> {{ redeemTokenSelectedToken.authoringStorage }}
                                </li>
                                <li>
                                    <span class="has-text-weight-bold">Bookshelf Storage:</span> {{ redeemTokenSelectedToken.bookshelfStorage }}
                                </li>
                            </ul>
                        </div>
                        <!--main environment token for new main environment-->
                        <div v-else-if="isEnvironmentTokenMainType(redeemTokenSelectedToken) && !hasMainEnvironmentExpired && !hasTrialEnvironment">
                            <p>Create a new authoring environment with the following specifications:</p>
                            <ul class="pl-5 mt-2">
                                <li>
                                    <span class="has-text-weight-bold">Learners:</span> {{ redeemTokenSelectedToken.numMaxLearners }}
                                </li>
                                <li>
                                    <span class="has-text-weight-bold">Authoring Storage:</span> {{ redeemTokenSelectedToken.authoringStorage }}
                                </li>
                                <li>
                                    <span class="has-text-weight-bold">Bookshelf Storage:</span> {{ redeemTokenSelectedToken.bookshelfStorage }}
                                </li>
                            </ul>
                        </div>
                        <!--sub environment token expired subs exist-->
                        <div v-else-if="!isEnvironmentTokenMainType(redeemTokenSelectedToken) && redeemTokenModalShowAskWhatSubEnvSection">
                            <p>Redeem subscription on expired library or create new library?</p>
                            <div class="columns is-centered pt-4">
                                <div class="column is-6 is-flex is-flex-direction-column">
                                    <button
                                        @click="setRedeemSubEnvTokenOnExpired"
                                        class="button">
                                        Renew expired library
                                    </button>
                                </div>
                                <div class="column is-6 is-flex is-flex-direction-column">
                                    <button
                                        @click="setRedeemSubEnvTokenOnNew"
                                        class="button">
                                        Create new library
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--sub environment token renew expired sub env -->
                        <div v-else-if="!isEnvironmentTokenMainType(redeemTokenSelectedToken) && redeemingTokenOnExpiredSubEnv">
                            <p class="has-text-weight-bold">
                                Renew the following expired library:
                            </p>
                            <div class="columns is-left pt-4">
                                <div class="column is-8 is-flex is-flex-direction-column">
                                    <div class="select is-fullwidth">
                                        <select
                                            v-model="redeemTokenModalSelectedExpiredSubEnvId"
                                            class="is-fullwidth">
                                            <option
                                                v-for="ese in expiredSubEnvironmentList"
                                                :key="ese.subEnvId"
                                                :value="ese.subEnvId">
                                                {{ ese.subdomain }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--sub environment token redeem on new sub env-->
                        <div v-else-if="!isEnvironmentTokenMainType(redeemTokenSelectedToken) && redeemingTokenOnNewSubEnv">
                            <p>
                                Add <span class="has-text-weight-bold">{{ redeemTokenSelectedToken.authoringStorage }}</span>
                                authoring storage to your account and a new library with the following resources :
                            </p>
                            <ul class="pl-5 mt-2">
                                <li>
                                    <span class="has-text-weight-bold">Learners:</span> {{ redeemTokenSelectedToken.numMaxLearners }}
                                </li>
                                <li>
                                    <span class="has-text-weight-bold">Bookshelf Storage:</span> {{ redeemTokenSelectedToken.bookshelfStorage }}
                                </li>
                            </ul>
                        </div>
                        <article
                            v-if="redeemTokenModalShowSubdomainSection"
                            class="message mt-3">
                            <div class="message-header">
                                <p>Pick Your Subdomain</p>
                            </div>
                            <div class="message-body">
                                You can check the validity and availability of your desired subdomain.
                                If you want to continue with the subdomain you've already reserved,
                                click its tag below, then click Redeem.
                                <div class="columns mt-3">
                                    <div class="column is-four-fifths">
                                        <div class="control has-icons-left has-icons-right">
                                            <input
                                                class="input"
                                                :class="{ 'is-danger': subdomainInvalidNewEnv || subdomainUnavailableNewEnv, 'is-success': validSubdomainAvailableNewEnv }"
                                                type="text"
                                                :disabled="validatingSubdomainNewEnv"
                                                @keyup="checkSubdomainEntryForNewEnv"
                                                v-model="requestedSubdomainNewEnv"
                                                placeholder="Subdomain">
                                            <span class="icon is-small is-left">
                                                <i class="fas fa-globe" />
                                            </span>
                                            <span
                                                v-if="subdomainInvalidNewEnv || subdomainUnavailableNewEnv"
                                                class="icon is-small is-right">
                                                <i class="fas fa-exclamation-triangle" />
                                            </span>
                                            <span
                                                v-if="validSubdomainAvailableNewEnv"
                                                class="icon is-small is-right">
                                                <i class="fas fa-check" />
                                            </span>
                                        </div>
                                        <p
                                            v-if="subdomainInvalidNewEnv"
                                            class="help is-danger">
                                            This subdomain is invalid.
                                        </p>
                                        <p
                                            v-if="subdomainUnavailableNewEnv"
                                            class="help is-danger">
                                            This subdomain is unavailable
                                        </p>
                                        <p
                                            v-if="validSubdomainAvailableNewEnv"
                                            class="help is-success">
                                            This subdomain is available
                                        </p>
                                    </div>
                                    <div class="column">
                                        <button
                                            v-if="!validatingSubdomainNewEnv"
                                            @click="attemptValidateSubdomainForNewEnv"
                                            class="button is-fullwidth">
                                            Validate
                                        </button>
                                        <div
                                            v-if="validatingSubdomainNewEnv"
                                            class="control is-loading" />
                                    </div>
                                </div>
                                <div v-if="reservedSubdomains.length > 0">
                                    <span class="is-size-7 mr-2">reserved: </span>
                                    <a
                                        v-for="rs in reservedSubdomains"
                                        :key="rs.reservedSubdomainId"
                                        class="tag mr-2 is-link"
                                        @click="applyReservedSubdomainsToNewEnv(rs.reservedSubdomain)">
                                        {{ rs.reservedSubdomain }}
                                    </a>
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <div v-if="redeemTokenModalBusy">
                        <i class="fa-fw fas fa-circle-notch fa-spin" /> Redeeming...
                    </div>
                    <div
                        v-else
                        class="buttons is-right">
                        <article
                            v-if="redeemTokenRetMsg !== ''"
                            class="message is-warning is-small mt-4">
                            <div class="message-body">
                                <i class="fas fa-exclamation-triangle" /> {{ redeemTokenRetMsg }}
                            </div>
                        </article>
                        <button
                            @click="cancelRedeemEnvironmentToken"
                            class="button is-text">
                            Cancel
                        </button>
                        <button
                            v-if="redeemTokenModalShowRedeemButton"
                            @click="processRedeemToken"
                            class="button is-primary">
                            Redeem
                        </button>
                    </div>
                </footer>
            </div>
            <button
                @click="cancelRedeemEnvironmentToken"
                class="modal-close is-large"
                aria-label="close" />
        </div>

        <!-- MAIN PAGE CONTENT -->

        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <div
                        v-if="amBusy"
                        class="has-text-centered">
                        <i class="fa-fw fas fa-circle-notch fa-spin" /> Loading PeBL account data...
                    </div>
                    <div
                        v-if="!amBusy && !accountDataLoaded"
                        class="has-text-centered">
                        Unable to load account list
                    </div>
                    <div
                        v-if="!amBusy && accountDataLoaded"
                        class="columns is-centered">
                        <div class="column">
                            <div class="columns">
                                <div
                                    id="setting-main-sections"
                                    class="column is-one-third">
                                    <p class="title sans-font has-text-weight-bold is-5">
                                        Settings
                                    </p>
                                    <aside class="menu">
                                        <ul class="menu-list">
                                            <li
                                                v-if="hasEnvironmentTokens">
                                                <a
                                                    @click="openRedeemEnvironmentTokenModal"
                                                    class="has-text-warning">
                                                    <span>
                                                        <i class="fa-fw fas fa-exclamation-triangle" />
                                                    </span>
                                                    <span>Notifications
                                                        <span class="tag">{{ environmentTokenList.length }}</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    @click="resetSectionHistory('account')"
                                                    :class="{'is-active':sectionHistory[0] === 'account'}">
                                                    <span>
                                                        <i class="fa-fw fas fa-cog" />
                                                    </span>
                                                    <span>Your Account</span>
                                                </a>
                                            </li>
                                            <li v-if="numTotalLibraries > 0">
                                                <a
                                                    @click="resetSectionHistory('library')"
                                                    :class="{'is-active':sectionHistory[0] === 'library'}">
                                                    <span>
                                                        <i class="fa-fw fas fa-book" />
                                                    </span>
                                                    <span>Libraries <span class="tag">{{ numTotalLibraries }}</span></span>
                                                </a>
                                            </li>
                                            <li v-if="hasTrialEnvironment || hasMainEnvironment">
                                                <a
                                                    @click="beginManageLearners('trialOrMain', true, null, false)"
                                                    :class="{'is-active':sectionHistory[0] === 'learner'}">
                                                    <span>
                                                        <i class="fa-fw fas fa-users" />
                                                    </span>
                                                    <span>Learners <span class="tag">{{ totalNumberOfCurrentLearners }}</span></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </aside>
                                </div>

                                <!-- ACCOUNT SECTION -->

                                <div
                                    v-if="section === 'account'"
                                    id="account-section"
                                    class="column subsection">
                                    <p class="title sans-font has-text-weight-bold is-5">
                                        Your account
                                    </p>

                                    <!-- Accont Info -->

                                    <div class="field is-grouped is-grouped-multiline">
                                        <div class="control">
                                            <span class="tag is-light">{{ profileData.email }}</span>
                                        </div>
                                        <div
                                            v-if="hasTrialEnvironment"
                                            class="control">
                                            <div class="tags has-addons">
                                                <span class="tag is-light">{{ trialEnvironment.authoringStorage }} authoring storage</span>
                                                <span
                                                    class="tag is-light"
                                                    :class="percentUsedTagClass(
                                                        trialEnvironment.authoringPercentStorageUsed, 1)">
                                                    {{ trialEnvironment.authoringPercentStorageUsed * 100 }}% used
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Account Alerts -->

                                    <!-- User has a login but no trial, yet -->
                                    <div v-if="!hasEverHadTrialEnvironment && !hasMainEnvironment && !hasEnvironmentTokens">
                                        <article class="notification is-warning is-light">
                                            <p class="has-text-weight-bold">
                                                <i class="fa-fw fas fa-exclamation-triangle" /> Action required
                                            </p>
                                            <p>
                                                You need to <router-link :to="{name: 'Start Screen'}">
                                                    finish setting up your account
                                                </router-link>
                                            </p>
                                        </article>
                                    </div>

                                    <!-- User purchased something that needs attention -->
                                    <div v-if="hasEnvironmentTokens">
                                        <article
                                            class="notification is-success is-light ">
                                            <p>
                                                <span class="has-text-weight-bold">
                                                    <i class="fa-fw fas fa-star" />
                                                    You have a subscription to set up
                                                </span>
                                                <a
                                                    @click="openRedeemEnvironmentTokenModal"
                                                    class="is-pulled-right">Click here</a>
                                            </p>
                                        </article>
                                    </div>

                                    <!-- General Trial status -->
                                    <div v-if="hasTrialEnvironment">
                                        <template v-if="trialEnvironment.readinessStatus === 'deleting' || deletedTrial">
                                            <article class="notification is-danger is-light">
                                                <p>
                                                    <span class="has-text-weight-bold">
                                                        Your trial is currently being deleted
                                                    </span>
                                                </p>
                                            </article>
                                        </template>
                                        <template v-else>
                                            <article class="notification is-warning is-light">
                                                <p>
                                                    <span class="has-text-weight-bold">
                                                        Your trial ends in {{ numDaysLeftOnTrial }}
                                                        <span v-if="numDaysLeftOnTrial == 1">day</span>
                                                        <span v-else>days</span>
                                                    </span>
                                                    <router-link
                                                        :to="{name: 'Pricing'}"
                                                        class="is-pulled-right">
                                                        Ready to upgrade?
                                                    </router-link>
                                                </p>
                                            </article>
                                            <button
                                                v-if="trialEnvironment.readinessStatus !== 'provisioning'"
                                                @click="confirmCancelTrial"
                                                class="button is-primary"
                                                :class="{'is-loading': deletingTrial}">
                                                Cancel Trial
                                            </button>
                                        </template>
                                    </div>

                                    <!-- Account suspended -->
                                    <!-- TODO: also check for suspended trial accounts? -->
                                    <div v-if="hasMainEnvironment && mainEnvironment.isSuspended">
                                        <article class="notification is-warning is-light">
                                            <p>
                                                <span class="has-text-weight-bold">
                                                    <i class="fa-fw fas fa-exclamation-triangle" />
                                                    Your account has been suspended
                                                </span>
                                                <span class="is-pulled-right">
                                                    For more information contact
                                                    <a href="mailto:support@pebl.pro?subject=Suspended%20Account">PeBL Pro support</a>
                                                    <!-- <router-link
                                                        :to="{name: 'Contact Us'}"
                                                        class="is-pulled-right">
                                                        contact PeBL Pro support
                                                    </router-link> -->
                                                </span>
                                            </p>
                                        </article>
                                    </div>

                                    <!-- ANDY STOPPING POINT -->

                                    <!-- Account Settings -->

                                    <ul class="menu-list">
                                        <li v-if="hasMainEnvironment">
                                            <a @click="showLastInvoice(mainEnvironment.lastInvoiceUrl)">
                                                <i class="icon-next fas fa-chevron-right" />
                                                <p class="has-text-weight-bold">
                                                    <i class="fa-fw fas fa-file-invoice" /> Last invoice</p>
                                                <span>
                                                    View your last paid invoice for this account and get a receipt
                                                </span>
                                            </a>
                                        </li>

                                        <li v-if="hasMainEnvironment || hasEnvironmentTokens">
                                            <a @click="goToCustomerPortal">
                                                <i class="icon-next fas fa-chevron-right" />
                                                <p class="has-text-weight-bold">
                                                    <i class="fa-fw fas fa-credit-card" />
                                                    Payment options
                                                </p>
                                                <p>Manage your payment options</p>
                                            </a>
                                        </li>

                                        <li v-if="hasMainEnvironment && hasMainEnvironmentExpired && !hasEnvironmentTokens">
                                            <router-link :to="{name: 'Pricing'}">
                                                <i class="icon-next fas fa-chevron-right" />
                                                <p class="has-text-weight-bold">
                                                    <i class="fa-fw fas fa-calendar-times" />
                                                    Expired
                                                </p>
                                                <p>
                                                    Your subscription for this account expired on
                                                    <span class="has-text-weight-bold">
                                                        {{ utilDateTimeStringFromMilliseconds(mainEnvironment.datePeriodExpires) }}
                                                    </span>
                                                </p>
                                                <p>Click here to purchase a PeBL Pro subscription and renew your account</p>
                                            </router-link>
                                        </li>

                                        <li v-if="hasMainEnvironment && hasMainEnvironmentExpired && hasEnvironmentTokens">
                                            <a @click="openRedeemEnvironmentTokenModal">
                                                <i class="icon-next fas fa-chevron-right" />
                                                <p class="has-text-weight-bold">
                                                    <i class="fa-fw fas fa-calendar-times" />
                                                    Expired
                                                </p>
                                                <p>
                                                    Your subscription for this account expired on
                                                    <span class="has-text-weight-bold">
                                                        {{ utilDateTimeStringFromMilliseconds(mainEnvironment.datePeriodExpires) }}
                                                    </span>
                                                </p>
                                                <article
                                                    v-if="hasEnvironmentTokens"
                                                    class="message is-link mt-3">
                                                    <i class="fas fa-chess-queen" /> You have an available library token that can be redeemed to update your account
                                                </article>
                                            </a>
                                        </li>

                                        <li v-if="hasMainEnvironment && !hasMainEnvironmentExpired">
                                            <a @click="goToPricingAddons">
                                                <i class="icon-next fas fa-chevron-right" />
                                                <p class="has-text-weight-bold">
                                                    <i class="fa-fw fas fa-star" /> Add an addition to this library
                                                </p>
                                                <p>
                                                    Using {{ mainEnvironment.authoringPercentStorageUsed * 100 }}% of {{ mainEnvironmentAuthoringStorageCapacity }} authoring storage
                                                </p>
                                            </a>
                                        </li>

                                        <li v-if="hasMainEnvironment && !hasMainEnvironmentExpired && mainEnvironment.cancelAtPeriodEnd && !mainEnvironment.stripeSubscriptionDeleted">
                                            <a @click="showReactivateMainEnvConfirmModal">
                                                <i class="icon-next fas fa-chevron-right" />
                                                <p class="has-text-weight-bold">
                                                    <i class="fa-fw fas fa-calendar-times" /> Reactivate subscription?
                                                </p>
                                                <p>
                                                    Your subscription for this account will expire on
                                                    <span class="has-text-weight-bold">
                                                        {{ utilDateStringFromMilliseconds(mainEnvironment.datePeriodExpires) }}
                                                    </span>.
                                                </p>
                                                <p class="is-size-7">
                                                    Inactive subscriptions and associated data are purged from our environment after {{ INACTIVE_PURGE_DAYS }} days.
                                                    Do you want to reactivate your subscription?
                                                </p>
                                            </a>
                                        </li>

                                        <li v-if="hasMainEnvironment && !hasMainEnvironmentExpired && mainEnvironment.stripeSubscriptionDeleted">
                                            <a>
                                                <p class="has-text-weight-bold">
                                                    <i class="fa-fw fas fa-calendar-times" /> Subscription canceled
                                                </p>
                                                <p>
                                                    Your subscription for this account will expire on
                                                    <span class="has-text-weight-bold">
                                                        {{ utilDateStringFromMilliseconds(mainEnvironment.datePeriodExpires) }}
                                                    </span>.
                                                </p>
                                                <p class="is-size-7">
                                                    Your subscription has been canceled externally and cannot be reactivated.
                                                </p>
                                            </a>
                                        </li>

                                        <li v-if="hasMainEnvironment && !hasMainEnvironmentExpired && !mainEnvironment.cancelAtPeriodEnd && !mainEnvironment.stripeSubscriptionDeleted">
                                            <a @click="showCancelMainEnvConfirmModal">
                                                <i class="icon-next fas fa-chevron-right" />
                                                <p class="has-text-weight-bold">
                                                    <i class="fa-fw fas fa-calendar-alt" /> Cancel subscription?
                                                </p>
                                                <p>
                                                    Your subscription for this account is active.  You will be billed again on
                                                    <span class="has-text-weight-bold">
                                                        {{ utilDateStringFromMilliseconds(mainEnvironment.datePeriodExpires) }}
                                                    </span>.
                                                </p>
                                                <p class="is-size-7">
                                                    Data from inactive subscriptions is purged {{ INACTIVE_PURGE_DAYS }} days after the subscription expires.
                                                    Add-on Library subscriptions associated with this subscription will also be cancelled.
                                                </p>
                                                <p class="is-size-7">
                                                    You can reactivate your subscription any time before it expires.
                                                    Add-on Library subscriptions must be reactivated manually.
                                                </p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <!-- LIBRARIES SECTION -->
                                <div
                                    v-if="(section === 'library')"
                                    id="libraries-section"
                                    class="column subsection">
                                    <p class="title sans-font has-text-weight-bold is-5">
                                        Manage your libraries
                                    </p>

                                    <!-- Libraries Alerts -->

                                    <!-- User has login account but no trial, yet -->
                                    <div v-if="!hasTrialEnvironment && !hasMainEnvironment">
                                        <article class="notification is-warning is-light">
                                            <p class="has-text-weight-bold">
                                                <i class="fa-fw fas fa-exclamation-triangle" /> Action required
                                            </p>
                                            <p>
                                                You need to <router-link :to="{name: 'Start Screen'}">
                                                    finish setting up your account
                                                </router-link>
                                            </p>
                                        </article>
                                    </div>

                                    <!-- Libraries Settings -->

                                    <div v-else>
                                        <ul class="menu-list">
                                            <li v-if="hasTrialEnvironment">
                                                <a @click="showTrialEnvironmentDetails">
                                                    <i class="icon-next fas fa-chevron-right" />
                                                    <figure class="image is-48x48 is-pulled-left mr-3">
                                                        <img
                                                            class="settings__subsection-library-logo"
                                                            :src="getLogoImage('')">
                                                    </figure>
                                                    <p>
                                                        <span class="has-text-weight-bold">
                                                            {{ trialEnvironment.subdomain }}
                                                        </span>
                                                    </p>
                                                    <div class="field is-grouped is-grouped-multiline">
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Status</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="[trialEnvironment.readinessStatus === 'unavailable' ? 'is-danger' : 'is-success']">
                                                                    {{ trialEnvironment.readinessStatus }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">PeBLs</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        trialEnvironment.numPeblsPublished,
                                                                        trialEnvironment.numMaxPebls)">
                                                                    {{ trialEnvironment.numPeblsPublished }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Learners</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        trialEnvironment.numCurrentLearners,
                                                                        trialEnvironment.numMaxLearners)">
                                                                    {{ trialEnvironment.numCurrentLearners }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Bookshelf storage used</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        trialEnvironment.bookshelfPercentStorageUsed, 1)">
                                                                    {{ trialEnvironment.bookshelfPercentStorageUsed * 100 }}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Bandwidth used</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        trialEnvironment.bandwidthPercentUsed, 1)">
                                                                    {{ trialEnvironment.bandwidthPercentUsed * 100 }}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="hasTrialEnvironmentExpired"
                                                            class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-danger">
                                                                    expired
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <!-- Main Library settings -->
                                            <li v-else-if="hasMainEnvironment">
                                                <a @click="showMainEnvironmentLibraryDetails">
                                                    <i class="icon-next fas fa-chevron-right" />
                                                    <figure class="image is-48x48 is-pulled-left mr-3">
                                                        <img
                                                            class="settings__subsection-library-logo"
                                                            :src="getLogoImage('')">
                                                    </figure>
                                                    <p>
                                                        <span class="has-text-weight-bold">
                                                            {{ mainEnvironment.subdomain }}
                                                        </span>
                                                    </p>
                                                    <div class="field is-grouped is-grouped-multiline">
                                                        <div class="control">
                                                            <span class="tag is-light"><i class="fa-fw fas fa-star" />Default Library</span>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Status</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="[mainEnvironment.readinessStatus === 'unavailable' ? 'is-danger' : 'is-success']">
                                                                    {{ mainEnvironment.readinessStatus }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">PeBLs</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        mainEnvironment.numPeblsPublished,
                                                                        mainEnvironment.numMaxPebls)">
                                                                    {{ mainEnvironment.numPeblsPublished }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Learners</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        mainEnvironment.numCurrentLearners,
                                                                        mainEnvironment.numMaxLearners)">
                                                                    {{ mainEnvironment.numCurrentLearners }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Bookshelf storage used</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        mainEnvironment.bookshelfPercentStorageUsed, 1)">
                                                                    {{ mainEnvironment.bookshelfPercentStorageUsed * 100 }}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Bandwidth used</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        mainEnvironment.bandwidthPercentUsed, 1)">
                                                                    {{ mainEnvironment.bandwidthPercentUsed * 100 }}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="hasMainEnvironmentExpired"
                                                            class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-danger">
                                                                    expired
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="mainEnvironment.cancelAtPeriodEnd || mainEnvironment.stripeSubscriptionDeleted"
                                                            class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-danger">
                                                                    canceled
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="mainEnvironment.isSuspended"
                                                            class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-danger">
                                                                    suspended
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                            <!-- Add on Libaries (sub env) -->
                                            <li
                                                v-for="subEnv in subEnvironmentList"
                                                :key="subEnv.subEnvId">
                                                <a @click="showSubEnvironmentLibraryDetails(subEnv)">
                                                    <i class="icon-next fas fa-chevron-right" />
                                                    <figure class="image is-48x48 is-pulled-left mr-3">
                                                        <img
                                                            class="settings__subsection-library-logo"
                                                            :src="getLogoImage('')">
                                                    </figure>
                                                    <p>
                                                        <span class="has-text-weight-bold">
                                                            {{ subEnv.subdomain }}
                                                        </span>
                                                    </p>
                                                    <div class="field is-grouped is-grouped-multiline">
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Status</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="[subEnv.readinessStatus === 'unavailable' ? 'is-danger' : 'is-success']">
                                                                    {{ subEnv.readinessStatus }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">PeBLs</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        subEnv.numPeblsPublished,
                                                                        subEnv.numMaxPebls)">
                                                                    {{ subEnv.numPeblsPublished }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Learners</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        subEnv.numCurrentLearners,
                                                                        subEnv.numMaxLearners)">
                                                                    {{ subEnv.numCurrentLearners }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Bookshelf storage used</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        subEnv.bookshelfPercentStorageUsed, 1)">
                                                                    {{ subEnv.bookshelfPercentStorageUsed * 100 }}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-light">Bandwidth used</span>
                                                                <span
                                                                    class="tag is-light"
                                                                    :class="percentUsedTagClass(
                                                                        subEnv.bandwidthPercentUsed, 1)">
                                                                    {{ subEnv.bandwidthPercentUsed * 100 }}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="hasSubEnvironmentExpired(subEnv)"
                                                            class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-danger">
                                                                    expired
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="subEnv.cancelAtPeriodEnd || subEnv.stripeSubscriptionDeleted"
                                                            class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-danger">
                                                                    canceled
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="subEnv.isSuspended"
                                                            class="control">
                                                            <div class="tags has-addons">
                                                                <span class="tag is-danger">
                                                                    suspended
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <button
                                        v-if="hasMainEnvironment"
                                        @click="goToPricingAddons"
                                        class="button mt-4">
                                        + Add a library
                                    </button>
                                </div>

                                <!-- LEARNERS SECTION -->

                                <div
                                    v-if="section === 'learner'"
                                    id="learners-section"
                                    class="column subsection">
                                    <p class="title sans-font has-text-weight-bold is-5">
                                        Manage your learners
                                    </p>

                                    <!-- Learner Alerts -->

                                    <div v-if="mngLrnBusy">
                                        <i class="fa-fw fas fa-circle-notch fa-spin" /> Loading...
                                    </div>

                                    <div v-else-if="!hasEverHadTrialEnvironment && !hasMainEnvironment && !hasEnvironmentTokens">
                                        <article class="notification is-warning is-light">
                                            <!-- User has login account but no trial, yet -->
                                            <p class="has-text-weight-bold">
                                                <i class="fa-fw fas fa-exclamation-triangle" /> Action required
                                            </p>
                                            <p>
                                                You need to <router-link :to="{name: 'Start Screen'}">
                                                    finish setting up your account
                                                </router-link>
                                            </p>
                                        </article>
                                    </div>

                                    <div v-else-if="mngLrnEnvLearnerLoadFailed">
                                        Unable to load learner information
                                    </div>

                                    <!-- Learner Settings -->

                                    <div v-else>
                                        <div class="field is-horizontal">
                                            <div class="field-label is-normal">
                                                <label class="label">Library</label>
                                            </div>
                                            <div class="field-body">
                                                <div class="field">
                                                    <div class="control">
                                                        <div class="select is-fullwidth">
                                                            <select
                                                                @change="changeManageLearnersEnvironment"
                                                                v-model="mngLrnSelectedAvailableEnv">
                                                                <option
                                                                    v-for="mlae in mngLrnAvailableEnv"
                                                                    :key="mlae.value"
                                                                    :value="mlae.value">
                                                                    {{ mlae.name }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="mngLrnEnvCanInviteMoreLearners"
                                                    class="field">
                                                    <div class="control">
                                                        <button
                                                            @click="setUpInviteLearners"
                                                            class="button">
                                                            <span>Invite Learners...</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="mngLrnSelectedAvailableEnv"
                                            class="field is-horizontal">
                                            <div class="field-label is-normal">
                                                <label class="label" />
                                            </div>
                                            <div class="field-body">
                                                <div class="field is-grouped is-grouped-multiline">
                                                    <div class="control">
                                                        <div class="tags has-addons">
                                                            <span class="tag is-light">Active learners</span>
                                                            <span
                                                                class="tag is-light"
                                                                :class="percentUsedTagClass(
                                                                    mngLrnEnvCurrentNumLearners,
                                                                    mngLrnEnvMaxLearnerSlots)">
                                                                {{ mngLrnEnvCurrentNumLearners }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="control">
                                                        <div class="tags has-addons">
                                                            <span class="tag is-light">Pending invitations</span>
                                                            <span
                                                                class="tag is-light"
                                                                :class="percentUsedTagClass(
                                                                    mngLrnEnvCurrentNumLearners + mngLrnEnvNumPendingInvites,
                                                                    mngLrnEnvMaxLearnerSlots)">
                                                                {{ mngLrnEnvNumPendingInvites }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="control">
                                                        <div class="tags has-addons">
                                                            <span class="tag is-light">Max learners</span>
                                                            <span
                                                                class="tag is-light"
                                                                :class="percentUsedTagClass(
                                                                    mngLrnEnvCurrentNumLearners + mngLrnEnvNumPendingInvites,
                                                                    mngLrnEnvMaxLearnerSlots)">
                                                                {{ mngLrnEnvMaxLearnerSlots }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="mngLrnEnvLearnerAndPendingList.length === 0 && mngLrnLearnerFilter === ''">
                                            <p
                                                v-if="mngLrnSelectedAvailableEnv"
                                                class="block has-text-centered mt-6">
                                                No learners have been <a @click="setUpInviteLearners">invited</a> to this library
                                            </p>
                                        </div>
                                        <div v-else>
                                            <div class="learnerTable">
                                                <table class="table is-hoverable table is-striped is-fullwidth">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="2">
                                                                <div class="field">
                                                                    <input
                                                                        v-model="mngLrnLearnerFilter"
                                                                        class="input"
                                                                        type="text"
                                                                        placeholder="Find learners...">
                                                                </div>
                                                            </th>
                                                            <th>Library</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(envLrnInvRec, idx) in mngLrnFilteredLearnerAndPendingList"
                                                            :key="idx">
                                                            <td class="is-narrow">
                                                                <!-- <span
                                                                    title="Active learner"
                                                                    v-if="envLrnInvRec.type === 'learner'">
                                                                    <i class="fas fa-user" />
                                                                </span> -->
                                                                <span
                                                                    title="Invitation pending"
                                                                    v-if="envLrnInvRec.type === 'invite'">
                                                                    <i class="fas fa-paper-plane is-ghost" />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <a
                                                                    :href="'mailto:'+ envLrnInvRec.email"
                                                                    target="_blank"
                                                                    title="Send email">{{ envLrnInvRec.email }}</a>
                                                            </td>
                                                            <td>
                                                                <!-- <a @click="showSection('library')">{{ envLrnInvRec.subdomain }}</a> -->
                                                                {{ envLrnInvRec.subdomain }}
                                                            </td>
                                                            <td>
                                                                <div class="field has-addons is-pulled-right">
                                                                    <!--<p class="control">-->
                                                                    <!--<button-->
                                                                    <!--@click="resendEnvLrnInv(envLrnInvRec)"-->
                                                                    <!--class="button is-small is-text"-->
                                                                    <!--title="Resend invite">-->
                                                                    <!--<span class="icon">-->
                                                                    <!--<i class="fas fa-redo" />-->
                                                                    <!--</span>-->
                                                                    <!--</button>-->
                                                                    <!--</p>-->
                                                                    <p class="control">
                                                                        <button
                                                                            @click="showEnvLrnInvRecordDeleteConfirm(envLrnInvRec)"
                                                                            class="button is-small is-text"
                                                                            title="Delete learner">
                                                                            <span class="icon">
                                                                                <i class="fas fa-trash" />
                                                                            </span>
                                                                        </button>
                                                                    </p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- SUBSECTIONS -->

                                <!-- Trial Environment Library Details -->
                                <div
                                    v-if="section === 'trialEnvironmentDetails'"
                                    class="subsection column">
                                    <p>
                                        <button
                                            class="button is-small is-text is-rounded"
                                            @click="closeSection">
                                            <i class="icon-back fas fa-arrow-left" />
                                        </button>
                                        <span class="title sans-font has-text-weight-bold is-5"> {{ trialEnvironment.subdomain }}</span>
                                        <span v-if="!hasTrialEnvironmentExpired"> - Open in <a @click="launchStudio(trialEnvironment.subdomain)">Studio</a> | <a @click="launchLibrary(trialEnvironment.subdomain)">Library</a></span>
                                        <!-- | <a @click="launchModeration">Dashboard</a> -->
                                    </p>
                                    <div class="columns">
                                        <div class="column">
                                            <div>
                                                <ul class="menu-list">
                                                    <li v-if="!doesTrialHaveLearnerSlotsAvailable()">
                                                        <a>
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-users-slash" />
                                                                At learner capacity</p>
                                                            <p>
                                                                You are at current learner capacity with {{ trialEnvironment.numCurrentLearners }} learner(s) and
                                                                {{ trialEnvironment.numPendingLearnerInvitations }} pending invitation(s).
                                                            </p>
                                                        </a>
                                                    </li>

                                                    <li v-if="!hasTrialEnvironmentExpired && doesTrialHaveLearnerSlotsAvailable()">
                                                        <a @click="beginManageLearners('trial', true, null, false)">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-user-plus" />
                                                                Manage learners</p>
                                                            <!-- <p>
                                                                You are currently using {{ trialEnvironment.numCurrentLearners }} learner(s) and
                                                                {{ trialEnvironment.numPendingLearnerInvitations }} pending invitation(s) out of
                                                                {{ trialEnvironment.numMaxLearners }} slots.
                                                            </p> -->
                                                            <p>Manage your trial library learners.</p>
                                                        </a>
                                                    </li>

                                                    <li v-if="hasEnvironmentTokens">
                                                        <a>
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-star" /> Redeem your token to activate your trial
                                                            </p>
                                                            <span>
                                                                <ul>
                                                                    <li>{{ trialEnvironment.bookshelfPercentStorageUsed * 100 }}% of {{ trialEnvironment.bookshelfStorage }} bookshelf storage</li>
                                                                    <li>{{ trialEnvironment.bandwidthPercentUsed * 100 }}% of your available bandwidth</li>
                                                                    <li>{{ trialEnvironment.numPeblsPublished }} PeBLs/EPUBs of {{ trialEnvironment.numMaxPebls }} slots</li>
                                                                </ul>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li v-if="!hasTrialEnvironmentExpired">
                                                        <a @click="showConfigureLrs('trial')">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-tools" /> LRS configuration
                                                            </p>
                                                            <p>
                                                                <span
                                                                    v-if="isLrsConfiguredForTrialEnvironment()">
                                                                    {{ trialEnvironment.lrsUrl }}
                                                                    <!-- {{ trialEnvironment.lrsAuthToken }} ) -->
                                                                </span>
                                                                <span v-else>
                                                                    Learning Record Store settings
                                                                </span>
                                                            </p>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a @click="showConfigureWhitelist('trial')">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-tools" /> LMS configuration
                                                            </p>
                                                            <p>
                                                                <span>Before distributing SCORM-based courses,
                                                                    you must whitelist your LMS.
                                                                    You can also use these settings to whitelist other systems
                                                                    that need access to your PeBL Pro library.</span>
                                                            </p>
                                                        </a>
                                                    </li>
                                                    <li v-if="!hasEnvironmentTokens">
                                                        <a
                                                            @click="goToPricing"
                                                            class="has-text-warning">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-crown" />
                                                                Ready to upgrade?
                                                            </p>
                                                            <!-- <span>
                                                                <ul>
                                                                    <li>{{ trialEnvironment.bookshelfPercentStorageUsed * 100 }}% of {{ trialEnvironment.bookshelfStorage }} bookshelf storage</li>
                                                                    <li>{{ trialEnvironment.bandwidthPercentUsed * 100 }}% of your available bandwidth</li>
                                                                    <li>{{ trialEnvironment.numPeblsPublished }} PeBLs/EPUBs of {{ trialEnvironment.numMaxPebls }} slots</li>
                                                                </ul>
                                                            </span> -->
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Main Environment Library Details -->
                                <div
                                    v-if="section === 'mainEnvironmentDetails'"
                                    class="subsection column">
                                    <p>
                                        <button
                                            class="button is-small is-text is-rounded"
                                            @click="closeSection">
                                            <i class="icon-back fas fa-arrow-left" />
                                        </button>
                                        <span class="title sans-font has-text-weight-bold is-5"> {{ mainEnvironment.subdomain }}</span>
                                        <span v-if="!hasMainEnvironmentExpired"> - Open in <a @click="launchStudio(mainEnvironment.subdomain)">Studio</a> | <a @click="launchLibrary(mainEnvironment.subdomain)">Library</a></span>
                                        <!-- | <a @click="launchModeration">Dashboard</a> -->
                                    </p>
                                    <div class="columns">
                                        <div class="column">
                                            <div>
                                                <ul class="menu-list">
                                                    <!-- <li>
                                                        <a>
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-star" /> Resource utilization
                                                            </p>
                                                            <span>
                                                                <ul>
                                                                    <li>{{ mainEnvironment.bookshelfPercentStorageUsed * 100 }}% of {{ mainEnvironment.bookshelfStorage }} bookshelf storage</li>
                                                                    <li>{{ mainEnvironment.bandwidthPercentUsed * 100 }}% of your available bandwidth</li>
                                                                    <li>{{ mainEnvironment.numPeblsPublished }} PeBLs/EPUBs
                                                                        <span v-if="mainEnvironment.numMaxPebls !== 'Unlimited'">
                                                                            of {{ mainEnvironment.numMaxPebls }} slots
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </span>
                                                        </a>
                                                    </li> -->
                                                    <li v-if="!doesMainEnvironmentHaveLearnerSlotsAvailable()">
                                                        <a>
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-users-slash" />
                                                                At learner capacity</p>
                                                            <p>
                                                                You are at current learner capacity with {{ mainEnvironment.numCurrentLearners }} learner(s) and
                                                                {{ mainEnvironment.numPendingLearnerInvitations }} pending invitation(s).
                                                            </p>
                                                        </a>
                                                    </li>
                                                    <li v-if="!hasMainEnvironmentExpired && doesMainEnvironmentHaveLearnerSlotsAvailable()">
                                                        <a @click="beginManageLearners('main', true, null, false)">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-user-plus" />
                                                                Manage learners</p>
                                                            <p>
                                                                You are currently using {{ mainEnvironment.numCurrentLearners }} learner(s) and
                                                                {{ mainEnvironment.numPendingLearnerInvitations }} pending invitation(s) out of
                                                                {{ mainEnvironment.numMaxLearners }} slots.
                                                            </p>
                                                            <p>Manage your library learners.</p>
                                                        </a>
                                                    </li>

                                                    <li v-if="!hasMainEnvironmentExpired">
                                                        <a @click="showConfigureLrs('main')">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-tools" /> LRS configuration
                                                            </p>
                                                            <p>
                                                                <span
                                                                    v-if="isLrsConfiguredForMainEnvironment()">
                                                                    {{ mainEnvironment.lrsUrl }}
                                                                    <!-- {{ mainEnvironment.lrsAuthToken }} ) -->
                                                                </span>
                                                                <span v-else>
                                                                    Learning Record Store settings
                                                                </span>
                                                            </p>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a @click="showConfigureWhitelist('main')">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-tools" /> LMS configuration
                                                            </p>
                                                            <p>
                                                                <span>Before distributing SCORM-based courses,
                                                                    you must whitelist your LMS.
                                                                    You can also use these settings to whitelist other systems
                                                                    that need access to your PeBL Pro library.</span>
                                                            </p>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Sub Environment Library Details (add-ons) -->
                                <div
                                    v-if="section === 'subEnvironmentDetails'"
                                    class="subsection column">
                                    <p>
                                        <button
                                            class="button is-small is-text is-rounded"
                                            @click="closeSection">
                                            <i class="icon-back fas fa-arrow-left" />
                                        </button>
                                        <span class="title sans-font has-text-weight-bold is-5"> {{ selectedSubEnvironment.subdomain }}</span>
                                        <span v-if="!hasSubEnvironmentExpired(selectedSubEnvironment)"> - Open in <a @click="launchStudio(mainEnvironment.subdomain || trialEnvironment.subdomain)">Studio</a> | <a @click="launchLibrary(selectedSubEnvironment.subdomain)">Library</a></span>
                                        <!-- | <a @click="launchModeration">Dashboard</a> -->
                                    </p>
                                    <div class="columns">
                                        <div class="column">
                                            <div>
                                                <ul class="menu-list">
                                                    <!-- <li>
                                                        <a>
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-star" /> Resource utilization
                                                            </p>
                                                            <span>
                                                                <ul>
                                                                    <li>{{ selectedSubEnvironment.bookshelfPercentStorageUsed * 100 }}% of {{ selectedSubEnvironment.bookshelfStorage }} bookshelf storage</li>
                                                                    <li>{{ selectedSubEnvironment.bandwidthPercentUsed * 100 }}% of your available bandwidth</li>
                                                                    <li>{{ selectedSubEnvironment.numPeblsPublished }} PeBLs/EPUBs</li>
                                                                </ul>
                                                            </span>
                                                        </a>
                                                    </li> -->
                                                    <li>
                                                        <a @click="showLastInvoice(selectedSubEnvironment.lastInvoiceUrl)">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-file-invoice" /> Last add-on invoice</p>
                                                            <span>
                                                                View your last paid invoice for this library and get a receipt
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li v-if="hasSubEnvironmentExpired(selectedSubEnvironment)">
                                                        <router-link :to="{name: 'Pricing'}">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-calendar-times" />
                                                                Expired
                                                            </p>
                                                            <p>
                                                                Your subscription for this library expired on
                                                                <span class="has-text-weight-bold">
                                                                    {{ utilDateTimeStringFromMilliseconds(selectedSubEnvironment.datePeriodExpires) }}
                                                                </span>
                                                            </p>
                                                            <p>Click here to purchase a PeBL Pro subscription and renew your library</p>
                                                        </router-link>
                                                    </li>
                                                    <li v-if="!hasSubEnvironmentExpired(selectedSubEnvironment) && !doesSelectedSubEnvironmentHaveLearnerSlotsAvailable">
                                                        <a>
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-users-slash" />
                                                                At learner capacity</p>
                                                            <p>
                                                                You are at current learner capacity with {{ selectedSubEnvironment.numCurrentLearners }} learner(s) and
                                                                {{ selectedSubEnvironment.numPendingLearnerInvitations }} pending invitation(s).
                                                            </p>
                                                        </a>
                                                    </li>
                                                    <li v-if="!hasSubEnvironmentExpired(selectedSubEnvironment) && doesSelectedSubEnvironmentHaveLearnerSlotsAvailable()">
                                                        <a @click="beginManageLearners('sub', true, selectedSubEnvironment, false)">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-user-plus" />
                                                                Manage learners</p>
                                                            <p>
                                                                You are currently using {{ selectedSubEnvironment.numCurrentLearners }} learner(s) and
                                                                {{ selectedSubEnvironment.numPendingLearnerInvitations }} pending invitation(s) out of
                                                                {{ selectedSubEnvironment.numMaxLearners }} slots.
                                                            </p>
                                                            <p>Manage your library learners.</p>
                                                        </a>
                                                    </li>
                                                    <li v-if="!hasSubEnvironmentExpired(selectedSubEnvironment)">
                                                        <a @click="showConfigureLrs('sub')">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-tools" /> LRS configuration
                                                            </p>
                                                            <p>
                                                                <span
                                                                    v-if="isLrsConfiguredForSubEnvironment(selectedSubEnvironment)">
                                                                    {{ selectedSubEnvironment.lrsUrl }}
                                                                    <!-- {{ selectedSubEnvironment.lrsAuthToken }} ) -->
                                                                </span>
                                                                <span v-else>
                                                                    Learning Record Store settings
                                                                </span>
                                                            </p>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a @click="showConfigureWhitelist('sub')">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-tools" /> LMS configuration
                                                            </p>
                                                            <p>
                                                                <span>Before distributing SCORM-based courses,
                                                                    you must whitelist your LMS.
                                                                    You can also use these settings to whitelist other systems
                                                                    that need access to your PeBL Pro library.</span>
                                                            </p>
                                                        </a>
                                                    </li>
                                                    <li v-if="!hasSubEnvironmentExpired(selectedSubEnvironment) && selectedSubEnvironment.stripeSubscriptionDeleted">
                                                        <a>
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-calendar-times" /> Subscription canceled
                                                            </p>
                                                            <p>
                                                                Your subscription for this library will expire on
                                                                <span class="has-text-weight-bold">
                                                                    {{ utilDateStringFromMilliseconds(selectedSubEnvironment.datePeriodExpires) }}
                                                                </span>.
                                                            </p>
                                                            <p class="is-size-7">
                                                                Your subscription has been canceled externally and cannot be reactivated.
                                                            </p>
                                                        </a>
                                                    </li>
                                                    <li v-if="!hasSubEnvironmentExpired(selectedSubEnvironment) && selectedSubEnvironment.cancelAtPeriodEnd && (mainEnvironment.cancelAtPeriodEnd || mainEnvironment.stripeSubscriptionDeleted) ">
                                                        <a>
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-calendar-times" /> Subscription canceled
                                                            </p>
                                                            <p>
                                                                Your subscription for this library addition will expire on
                                                                <span class="has-text-weight-bold">
                                                                    {{ utilDateStringFromMilliseconds(selectedSubEnvironment.datePeriodExpires) }}
                                                                </span>.
                                                            </p>
                                                            <p class="is-size-7">
                                                                It cannot be reactivated because your authoring account is also canceled.
                                                            </p>
                                                        </a>
                                                    </li>
                                                    <li v-if="!hasSubEnvironmentExpired(selectedSubEnvironment) && selectedSubEnvironment.cancelAtPeriodEnd && !selectedSubEnvironment.stripeSubscriptionDeleted && !mainEnvironment.cancelAtPeriodEnd && !mainEnvironment.stripeSubscriptionDeleted">
                                                        <a @click="showReactivateSubEnvConfirmModal">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-calendar-times" /> Reactivate subscription?
                                                            </p>
                                                            <p>
                                                                Your subscription for this library addition will expire on
                                                                <span class="has-text-weight-bold">
                                                                    {{ utilDateStringFromMilliseconds(selectedSubEnvironment.datePeriodExpires) }}
                                                                </span>.
                                                            </p>
                                                            <p class="is-size-7">
                                                                Inactive subscriptions and associated data are purged from our environment after {{ INACTIVE_PURGE_DAYS }} days.
                                                                Do you want to reactivate your subscription?
                                                            </p>
                                                        </a>
                                                    </li>
                                                    <li v-if="!hasSubEnvironmentExpired(selectedSubEnvironment) && !selectedSubEnvironment.cancelAtPeriodEnd && !selectedSubEnvironment.stripeSubscriptionDeleted">
                                                        <a @click="showCancelSubEnvConfirmModal">
                                                            <i class="icon-next fas fa-chevron-right" />
                                                            <p class="has-text-weight-bold">
                                                                <i class="fa-fw fas fa-calendar-alt" /> Cancel subscription?
                                                            </p>
                                                            <p>
                                                                Your subscription for this library addition is active.  You will be billed again on
                                                                <span class="has-text-weight-bold">
                                                                    {{ utilDateStringFromMilliseconds(selectedSubEnvironment.datePeriodExpires) }}
                                                                </span>.
                                                            </p>
                                                            <p class="is-size-7">
                                                                Data from inactive subscriptions is purged {{ INACTIVE_PURGE_DAYS }} days after the subscription expires.
                                                                Your authoring account will lose <span class="has-text-weight-bold">{{ selectedSubEnvironment.authoringStorage }}</span>
                                                                of authoring storage when this library expires and is not renewed.
                                                            </p>
                                                            <p class="is-size-7">
                                                                You can reactivate your subscription any time before it expires.
                                                            </p>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- LRS Configuration -->
                                <div
                                    v-if="section === 'configureLrs'"
                                    class="subsection column">
                                    <p>
                                        <button
                                            class="button is-small is-text is-rounded"
                                            @click="closeSection">
                                            <i class="icon-back fas fa-arrow-left" />
                                        </button>
                                        <span class="title sans-font has-text-weight-bold is-5"> LRS Configuration</span>
                                    </p>
                                    <div class="field">
                                        <label class="label">LRS URL</label>
                                        <div class="control">
                                            <input
                                                class="input"
                                                :class="{ 'is-danger': configureLrsUrlInvalid }"
                                                type="text"
                                                @keyup="checkLrsConfigData"
                                                v-model="configureLrsUrl"
                                                placeholder="https://...">
                                        </div>
                                        <p
                                            v-if="configureLrsUrlInvalid"
                                            class="help is-danger">
                                            This URL is invalid.
                                        </p>
                                    </div>

                                    <div v-if="!configureLrsAuthType">
                                        <p>
                                            Authenticate using <a @click="showLrsAuthTypeInput('login');">login credentials</a>
                                            or an <a @click="showLrsAuthTypeInput('token');">LRS token</a>
                                        </p>
                                    </div>
                                    <div
                                        v-if="configureLrsAuthType === 'token'"
                                        class="field">
                                        <label class="label">LRS Authentication Token</label>
                                        <div class="control">
                                            <input
                                                class="input"
                                                :class="{ 'is-danger': configureLrsAuthTokenInvalid }"
                                                type="text"
                                                @keyup="checkLrsConfigData"
                                                v-model="configureLrsAuthToken"
                                                placeholder="">
                                        </div>
                                        <p>
                                            <a @click="showLrsAuthTypeInput('login');">Use login credentials instead</a>
                                        </p>
                                        <p
                                            v-if="configureLrsAuthTokenInvalid"
                                            class="help is-danger">
                                            This token is invalid.
                                        </p>
                                    </div>
                                    <div v-if="configureLrsAuthType === 'login'">
                                        <div class="field">
                                            <label class="label">LRS Login Credentials</label>
                                            <div class="control has-icons-left">
                                                <input
                                                    class="input"
                                                    type="text"
                                                    @keyup="generateLrsToken();checkLrsConfigData();"
                                                    v-model="configureLrsAuthUsername"
                                                    placeholder="Username/Key">
                                                <span class="icon is-small is-left">
                                                    <i class="fas fa-user" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <p class="control has-icons-left">
                                                <input
                                                    class="input"
                                                    type="password"
                                                    @keyup="generateLrsToken();checkLrsConfigData();"
                                                    v-model="configureLrsAuthPassword"
                                                    placeholder="Password/Secret">
                                                <span class="icon is-small is-left">
                                                    <i class="fas fa-lock" />
                                                </span>
                                            </p>
                                        </div>
                                        <p>
                                            <a @click="showLrsAuthTypeInput('token');">Use authentication token instead</a>
                                        </p>
                                    </div>
                                    <div v-if="configureLrsBusy">
                                        <i class="fa-fw fas fa-circle-notch fa-spin" /> Saving...
                                    </div>
                                    <div v-else>
                                        <button
                                            v-if="!configureLrsShowSavedMessage"
                                            :disabled="configureLrsDisableSaveButton"
                                            @click="saveEnvironmentLrsConfig"
                                            class="button is-link">
                                            Save
                                        </button>
                                        <article
                                            v-if="configureLrsShowSavedMessage"
                                            class="message is-success is-small mt-4">
                                            <div class="message-body">
                                                <i class="fas fa-exclamation-triangle" /> Saved!
                                            </div>
                                        </article>
                                        <article
                                            v-if="configureLrsRetMsg !== ''"
                                            class="message is-warning is-small mt-4">
                                            <div class="message-body">
                                                <i class="fas fa-exclamation-triangle" /> {{ configureLrsRetMsg }}
                                            </div>
                                        </article>
                                    </div>
                                </div>


                                <!-- Configure Domain Whitelist -->
                                <div
                                    v-if="section === 'ConfigureWhitelist'"
                                    class="subsection column">
                                    <p>
                                        <button
                                            class="button is-small is-text is-rounded"
                                            @click="closeSection">
                                            <i class="icon-back fas fa-arrow-left" />
                                        </button>
                                        <span class="title sans-font has-text-weight-bold is-5"> LMS Configuration</span>
                                    </p>
                                    <label class="label">Specify the domain(s) that can launch your library.</label>
                                    <p>Do not include URL protocols ('http', 'https') or slashes.  This might look like <span class="tag">lms.com</span> or <span class="tag">lms.myorg.com</span></p>
                                    <article
                                        v-if="configureWhitelistDuplicateDomains"
                                        class="message is-danger is-small mt-4">
                                        <div class="message-body">
                                            <i class="fas fa-exclamation-triangle" /> Duplicate domains are listed
                                        </div>
                                    </article>
                                    <div
                                        v-for="(dom, index) in configureWhitelistDomainList"
                                        :key="index"
                                        class="field is-grouped">
                                        <div class="control is-expanded">
                                            <input
                                                @change="cleanAndValidateWhitelistData"
                                                class="input"
                                                type="text"
                                                v-model="dom.val"
                                                placeholder="domain...">
                                            <p
                                                v-if="!isValidWhitelistEntry(dom.val)"
                                                class="help is-danger">
                                                This domain is invalid
                                            </p>
                                        </div>
                                        <div class="control">
                                            <button
                                                @click="deleteConfigureWhitelistAddress(index)"
                                                class="button is-danger is-inverted"
                                                title="Delete">
                                                <span class="icon is-small"><i class="fas fa-trash" /></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="buttons">
                                        <button
                                            v-if="!configureWhitelistShowSavedMessage"
                                            @click="saveConfigureWhitelist"
                                            :disabled="configureWhitelistDisableSaveButton"
                                            class="button is-primary"
                                            :class="{'is-loading':configureWhitelistIsBusy}">
                                            Save
                                        </button>
                                        <button
                                            v-if="!configureWhitelistShowSavedMessage"
                                            @click="addConfigureWhitelistAddress()"
                                            class="button">
                                            Add another domain
                                        </button>
                                        <article
                                            v-if="configureWhitelistShowSavedMessage"
                                            class="message is-success is-small mt-4">
                                            <div class="message-body">
                                                <i class="fas fa-exclamation-triangle" /> Saved!
                                            </div>
                                        </article>
                                        <article
                                            v-if="configureWhitelistRetMsg !== ''"
                                            class="message is-warning is-small mt-4">
                                            <div class="message-body">
                                                <i class="fas fa-exclamation-triangle" /> {{ configureWhitelistRetMsg }}
                                            </div>
                                        </article>
                                    </div>
                                </div>


                                <!-- Invite Learners -->
                                <div
                                    v-if="section === 'inviteLearners'"
                                    class="subsection column">
                                    <p>
                                        <button
                                            class="button is-small is-text is-rounded"
                                            @click="closeInviteLearners">
                                            <i class="icon-back fas fa-arrow-left" />
                                        </button>
                                        <span class="title sans-font has-text-weight-bold is-5">Send Invites</span>
                                    </p>
                                    <invite-learners
                                        :show-library-selector="false"
                                        :available-envs="mngLrnAvailableEnv"
                                        :selected-available-env="mngLrnSelectedAvailableEnv"
                                        @preSendInvites="disableInviteLearnersCloseButton"
                                        @postSendInvites="enableInviteLearnersCloseButton"
                                        @inviteSendSuccess="reloadManageLearnerDataShowInviteSection"
                                        @close="closeInviteLearners" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import {purchaseApi} from '../mixins/purchaseApi';
import {appUtil} from '../mixins/appUtil';
import InviteLearners from '../components/inviteLearners.vue';
import EventBus from '@/mixins/event-bus';
import Base64 from 'crypto-js/enc-base64';
import Latin1 from 'crypto-js/enc-latin1';

export default {
    name: 'AccountSettings',
    mixins: [purchaseApi, appUtil],
    components: {InviteLearners},
    data: () => {
        return {
            INACTIVE_PURGE_DAYS: 30,
            amBusy: false,
            accountDataLoaded: false,
            profileData: {},
            productMap: {},
            appReservedSubdomains: [],
            reservedSubdomains: [],
            hasEnvironmentTokens: false,
            environmentTokenList: [],
            trialEnvironment: {}, // THERE SHOULD EVER ONLY BE ONE OF THESE AT MOST
            hasEverHadTrialEnvironment: false,
            hasTrialEnvironment: false,
            hasTrialEnvironmentExpired: false,
            hasAnySubEnvironmentExpired: false,
            expiredSubEnvironmentList: [],
            mainEnvironment: {}, // THERE SHOULD EVER ONLY BE ONE OF THESE AT MOST
            hasMainEnvironment: false,
            hasMainEnvironmentExpired: false,
            mainEnvironmentAuthoringStorageCapacity: '',
            subEnvironmentList: [],
            totalNumberOfCurrentLearners: 0,
            reactivateMainEnvConfirmModalBusy: false,
            reactivateMainEnvConfirmRetMsg: '',
            reactivateMainEnvConfirmModalActive: false,
            cancelMainEnvConfirmModalBusy: false,
            cancelMainEnvConfirmRetMsg: '',
            cancelMainEnvConfirmModalActive: false,
            reactivateSubEnvConfirmModalBusy: false,
            reactivateSubEnvConfirmRetMsg: '',
            reactivateSubEnvConfirmModalActive: false,
            cancelSubEnvConfirmModalBusy: false,
            cancelSubEnvConfirmRetMsg: '',
            cancelSubEnvConfirmModalActive: false,
            redeemTokenRetMsg: '',
            redeemTokenModalShowRedeemButton: false,
            redeemTokenSelectedToken: null,
            redeemTokenTokenSelected: false,
            redeemTokenModalBusy: false,
            redeemTokenModalActive: false,
            redeemTokenModalShowSubdomainSection: false,
            redeemTokenModalShowAskWhatSubEnvSection: false,
            redeemingTokenOnNewSubEnv: false,
            redeemingTokenOnExpiredSubEnv: false,
            redeemTokenModalSelectedExpiredSubEnvId: -1,
            subdomainInvalidNewEnv: false,
            subdomainUnavailableNewEnv: false,
            validSubdomainAvailableNewEnv: false,
            validatingSubdomainNewEnv: false,
            requestedSubdomainNewEnv: '',
            selectedSubEnvironment: {},
            configureLrsBusy: false,
            configureLrsAuthType: 'login',
            configureLrsRetMsg: '',
            configureLrsSubdomain: '',
            configureLrsUrl: '',
            configureLrsAuthToken: '',
            configureLrsAuthUsername: '',
            configureLrsAuthPassword: '',
            configureLrsEnvType: '',
            configureLrsUrlInvalid: false,
            configureLrsAuthTokenInvalid: false,
            configureLrsDisableSaveButton: false,
            configureLrsShowSavedMessage: false,
            configureWhitelistIsBusy: false,
            configureWhitelistDomainList: [],
            configureWhitelistDisableSaveButton: true,
            configureWhitelistRetMsg: '',
            configureWhitelistEnvType: '',
            configureWhitelistAnyDomainsInvalid: false,
            configureWhitelistDuplicateDomains: false,
            configureWhitelistShowSavedMessage: false,
            mngLrnBusy: false,
            mngLrnEnvType: '',
            mngLrnEnvLearnerAndPendingList: [],
            mngLrnEnvCurrentNumLearners: 0,
            mngLrnEnvNumPendingInvites: 0,
            mngLrnEnvIsExpired: false,
            mngLrnEnvLearnerLoadFailed: false,
            mngLrnSelectedSubEnv: null,
            mngLrnSelectedAvailableEnv: '',
            mngLrnAvailableEnv: [],
            mngLrnEnvMaxLearnerSlots: 0,
            mngLrnEnvCanInviteMoreLearners: false,
            mngLrnCachedLearnerData: {},
            mngLrnLearnerFilter: '',
            mngLrnShowInviteLearnerSection: false,
            mngLrnInvLearnerCloseButtonDisabled: false,
            envLrnInvRecordToRemove: null,
            envLrnInvRecordRemoveConfirmModalActive: false,
            envLrnInvRecordRemoveConfirmBusy: false,
            envLrnInvRecordRemoveConfirmRetMsg: '',
            sectionHistory: [],
            deletingTrial: false,
            deletedTrial: false
        };
    },
    metaInfo: {
        title: 'Account Settings'
    },
    created() {
        this.resetSectionHistory(this.$route.params.section);
    },
    computed: {
        mngLrnFilteredLearnerAndPendingList: function() {
            return this.mngLrnEnvLearnerAndPendingList.filter(lpo => {
                return ((lpo.email && lpo.email.toLowerCase().indexOf(this.mngLrnLearnerFilter.toLowerCase()) > -1));
            });
        },
        section: function() {
            const section = this.getCurrentSection();
            return section;
        },
        // validMainEnvironmentsForNewSubEnv : function() {
        //     const validMainEnvs = [];
        //     for (const me of this.mainEnvironmentList) {
        //         if (me.datePeriodExpires > Date.now() && !me.cancelAtPeriodEnd) validMainEnvs.push(me);
        //     }
        //     return validMainEnvs;
        // },
        numTotalLibraries: function() {
            if (this.hasMainEnvironment) {
                return 1 + this.subEnvironmentList.length;
            } else if (this.hasTrialEnvironment) {
                return 1;
            } else return 0;
        },
        numDaysLeftOnTrial: function() {
            if (this.hasTrialEnvironment) {
                if (Date.now() > this.trialEnvironment.datePeriodExpires) return 0;
                else {
                    return Math.ceil((this.trialEnvironment.datePeriodExpires - Date.now()) / (1000 * 60 * 60 * 24));
                }
            } else return 0;
        }
    },
    watch: {
        '$route.params.section'() {
            this.resetSectionHistory(this.$route.params.section);
        }
    },
    mounted: async function() {
        this.amBusy = true;
        this.buildCustomerAccountData();

        EventBus.$on('navbarShowSection', payload => {
            this.resetSectionHistory(payload);
        });
    },
    methods: {
        async confirmCancelTrial() {
            if (window.confirm('Are you sure you want to cancel your trial? Your data will be deleted and the subdomain will be made available.')) {
                this.deletingTrial = true;
                const result = await this.performCustomerCancelTrial();
                console.log(result);
                this.deletingTrial = false;
                this.deletedTrial = true;
            }
        },
        showLrsAuthTypeInput(type) {
            // type is 'token' or 'login'
            this.configureLrsAuthType = type;
        },
        disableInviteLearnersCloseButton() {
            this.mngLrnInvLearnerCloseButtonDisabled = true;
        },
        enableInviteLearnersCloseButton() {
            this.mngLrnInvLearnerCloseButtonDisabled = false;
        },
        closeInviteLearners() {
            this.mngLrnShowInviteLearnerSection = false;
            this.closeSection;
            this.reloadManageLearnerData();
        },
        setUpInviteLearners() {
            this.mngLrnShowInviteLearnerSection = true;
            this.showSection('inviteLearners');
        },
        closeEnvLrnInvRecordDeleteConfirm() {
            this.envLrnInvRecordToRemove = null;
            this.envLrnInvRecordRemoveConfirmModalActive = false;
        },
        showEnvLrnInvRecordDeleteConfirm(envLrnInvRecord) {
            if (envLrnInvRecord) {
                this.envLrnInvRecordToRemove = envLrnInvRecord;
                this.envLrnInvRecordRemoveConfirmBusy = false;
                this.envLrnInvRecordRemoveConfirmRetMsg = '';
                this.envLrnInvRecordRemoveConfirmModalActive = true;
            }
        },
        resendEnvLrnInv(envLrnInvRecord) {
            if (envLrnInvRecord) {
                alert('TO DO: resend invite to this learner.');
            }
        },
        async removeInviteFromEnvironment() {
            if (this.mngLrnEnvType === 'trial' || this.mngLrnEnvType === 'main') {
                await this.performRemoveLearnerInviteFromMainEnvironment(this.envLrnInvRecordToRemove.relevantEnvId, this.envLrnInvRecordToRemove.relevantRecordId);
            } else if (this.mngLrnEnvType === 'sub') {
                await this.performRemoveLearnerInviteFromSubEnvironment(this.envLrnInvRecordToRemove.relevantEnvId, this.envLrnInvRecordToRemove.relevantRecordId);
            }
        },
        async removeLearnerFromEnvironment() {
            let removeResp;
            if (this.mngLrnEnvType === 'trial' || this.mngLrnEnvType === 'main') {
                removeResp = await this.performRemoveLearnerFromMainEnvironment(this.envLrnInvRecordToRemove.relevantEnvId, this.envLrnInvRecordToRemove.relevantRecordId);
            } else if (this.mngLrnEnvType === 'sub') {
                removeResp = await this.performRemoveLearnerFromSubEnvironment(this.envLrnInvRecordToRemove.relevantEnvId, this.envLrnInvRecordToRemove.relevantRecordId);
            }
            if (removeResp && removeResp.removed) this.totalNumberOfCurrentLearners--;
        },
        async removeLearnerOrInviteFromEnvironment() {
            if (this.envLrnInvRecordToRemove) {
                this.envLrnInvRecordRemoveConfirmBusy = true;
                if (this.envLrnInvRecordToRemove.type === 'invite') await this.removeInviteFromEnvironment();
                else await this.removeLearnerFromEnvironment();
                await this.reloadManageLearnerData();
                this.closeEnvLrnInvRecordDeleteConfirm();
            }
        },
        buildMngLrnEnvLearnerAndPendingListObject(type, relevantRecordId, email, relevantEnvId, subdomain) {
            return {
                type: type,
                relevantRecordId: relevantRecordId,
                email: email,
                relevantEnvId: relevantEnvId,
                subdomain: subdomain
            };
        },
        buildMngLrnEnvLearnerAndPendingList(learnerList, pendingInviteList) {
            this.mngLrnEnvLearnerAndPendingList = [];
            for (const lrn of learnerList) {
                this.mngLrnEnvLearnerAndPendingList.push(this.buildMngLrnEnvLearnerAndPendingListObject(
                    'learner', lrn.peblLearnerId, lrn.learnerEmail, lrn.mainEnvId || lrn.subEnvId, lrn.subdomain)
                );
            }
            for (const pi of pendingInviteList) {
                this.mngLrnEnvLearnerAndPendingList.push(this.buildMngLrnEnvLearnerAndPendingListObject(
                    'invite', pi.peblLearnerInvitationId, pi.learnerEmail, pi.mainEnvId || pi.subEnvId, pi.subdomain)
                );
            }
            this.mngLrnEnvLearnerAndPendingList.sort((a, b) => (a.email > b.email) ? 1 : -1);
        },
        addToManageLearnerDataCache(key, learnerList, pendingInviteList) {
            if (!this.mngLrnCachedLearnerData[key]) {
                this.mngLrnCachedLearnerData[key] = {};
            }
            this.mngLrnCachedLearnerData[key].learnerList = learnerList;
            this.mngLrnCachedLearnerData[key].pendingInviteList = pendingInviteList;
        },
        getManageLearnerDataFromCacheIfExists(key) {
            let cacheRet = null;
            if (this.mngLrnCachedLearnerData[key]) {
                cacheRet = {
                    learnerList: this.mngLrnCachedLearnerData[key].learnerList,
                    pendingInviteList: this.mngLrnCachedLearnerData[key].pendingInviteList
                };
            }
            return cacheRet;
        },
        async buildAppropriateManageLearnersData(useCache) {
            let llRet = null;
            let cachedData = null;
            if (this.mngLrnEnvType === 'trial') {
                this.mngLrnSelectedAvailableEnv = 'trial';
                if (useCache) cachedData = this.getManageLearnerDataFromCacheIfExists(this.mngLrnSelectedAvailableEnv);
                if (!cachedData) llRet = await this.performLearnerListFromMainEnvironment(this.trialEnvironment.mainEnvId);
                this.mngLrnEnvIsExpired = this.hasTrialEnvironmentExpired;
                this.mngLrnEnvMaxLearnerSlots = this.trialEnvironment.numMaxLearners;
            } else if (this.mngLrnEnvType === 'main') {
                this.mngLrnSelectedAvailableEnv = 'main';
                if (useCache) cachedData = this.getManageLearnerDataFromCacheIfExists(this.mngLrnSelectedAvailableEnv);
                if (!cachedData) llRet = await this.performLearnerListFromMainEnvironment(this.mainEnvironment.mainEnvId);
                this.mngLrnEnvIsExpired = this.hasMainEnvironmentExpired;
                this.mngLrnEnvMaxLearnerSlots = this.mainEnvironment.numMaxLearners;
            } else if (this.mngLrnEnvType === 'sub') {
                this.mngLrnSelectedAvailableEnv = 'sub-' + this.mngLrnSelectedSubEnv.subEnvId;
                if (useCache) cachedData = this.getManageLearnerDataFromCacheIfExists(this.mngLrnSelectedAvailableEnv);
                if (!cachedData) llRet = await this.performLearnerListFromSubEnvironment(this.mngLrnSelectedSubEnv.subEnvId);
                this.mngLrnEnvIsExpired = this.hasSubEnvironmentExpired(this.mngLrnSelectedSubEnv);
                this.mngLrnEnvMaxLearnerSlots = this.mngLrnSelectedSubEnv.numMaxLearners;
            }
            if (cachedData) {
                this.mngLrnEnvCurrentNumLearners = cachedData.learnerList.length;
                this.mngLrnEnvNumPendingInvites = cachedData.pendingInviteList.length;
                this.buildMngLrnEnvLearnerAndPendingList(cachedData.learnerList, cachedData.pendingInviteList);
            } else if (llRet && llRet.status && llRet.status === 'ok') {
                this.mngLrnEnvCurrentNumLearners = llRet.learnerList.length;
                this.mngLrnEnvNumPendingInvites = llRet.pendingInviteList.length;
                this.buildMngLrnEnvLearnerAndPendingList(llRet.learnerList, llRet.pendingInviteList);
                this.addToManageLearnerDataCache(this.mngLrnSelectedAvailableEnv, llRet.learnerList, llRet.pendingInviteList);
            } else this.mngLrnEnvLearnerLoadFailed = true;
        },
        determineEnvCanInviteMoreLearners() {
            this.mngLrnEnvCanInviteMoreLearners = false;
            if (!this.mngLrnEnvIsExpired) {
                this.mngLrnEnvCanInviteMoreLearners = this.mngLrnEnvMaxLearnerSlots > (this.mngLrnEnvNumPendingInvites + this.mngLrnEnvCurrentNumLearners);
            }
        },
        async beginManageLearners(typeEnvironment, useCache, subEnvIfAny, showInvLrnSection) {
            this.mngLrnBusy = true;
            this.resetSectionHistory('learner');
            this.mngLrnEnvLearnerAndPendingList = [];
            this.mngLrnEnvCurrentNumLearners = 0;
            this.mngLrnEnvNumPendingInvites = 0;
            this.mngLrnEnvIsExpired = false;
            this.mngLrnEnvLearnerLoadFailed = false;
            this.mngLrnSelectedSubEnv = subEnvIfAny;
            this.mngLrnEnvMaxLearnerSlots = 0;
            this.mngLrnLearnerFilter = '';
            this.mngLrnShowInviteLearnerSection = showInvLrnSection;
            this.mngLrnInvLearnerCloseButtonDisabled = false;
            if (typeEnvironment === 'trialOrMain') {
                if (this.hasTrialEnvironment) this.mngLrnEnvType = 'trial';
                else this.mngLrnEnvType = 'main';
            } else this.mngLrnEnvType = typeEnvironment;
            await this.buildAppropriateManageLearnersData(useCache);
            this.determineEnvCanInviteMoreLearners();
            this.mngLrnBusy = false;
        },
        async reloadManageLearnerData() {
            await this.beginManageLearners(this.mngLrnEnvType, false, this.mngLrnSelectedSubEnv, false);
        },
        async reloadManageLearnerDataShowInviteSection() {
            await this.beginManageLearners(this.mngLrnEnvType, false, this.mngLrnSelectedSubEnv, true);
        },
        async changeManageLearnersEnvironment() {
            if (this.mngLrnSelectedAvailableEnv === 'trial') await this.beginManageLearners('trial', true, null, false);
            else if (this.mngLrnSelectedAvailableEnv === 'main') await this.beginManageLearners('main', true, null, false);
            else if (this.mngLrnSelectedAvailableEnv.startsWith('sub-')) {
                const subEnvId = parseInt(this.mngLrnSelectedAvailableEnv.replace('sub-', ''));
                const subEnv = this.getSubEnvironmentById(subEnvId);
                await this.beginManageLearners('sub', true, subEnv, false);
            }
        },
        getSubEnvironmentById(subEnvId) {
            for (const se of this.subEnvironmentList) {
                if (se.subEnvId === subEnvId) return se;
            }
            return null;
        },
        checkLrsConfigData() {
            this.configureLrsUrlInvalid = false;
            this.configureLrsAuthTokenInvalid = false;
            this.configureLrsDisableSaveButton = false;
            if (!this.configureLrsUrl || this.configureLrsUrl.trim().length <= 0 || !this.isValidUrl(this.configureLrsUrl)) {
                this.configureLrsUrlInvalid = true;
                this.configureLrsDisableSaveButton = true;
            }
            if (!this.configureLrsAuthToken || this.configureLrsAuthToken.trim().length <= 0) {
                this.configureLrsAuthTokenInvalid = true;
                this.configureLrsDisableSaveButton = true;
            }
            // Allow both fields being empty as a valid option
            if ((!this.configureLrsUrl || this.configureLrsUrl.trim().length <= 0) && (!this.configureLrsAuthToken || this.configureLrsAuthToken.trim().length <= 0)) {
                this.configureLrsUrlInvalid = false;
                this.configureLrsAuthTokenInvalid = false;
                this.configureLrsDisableSaveButton = false;
            }
        },
        updateLocalLrsConfigData() {
            if (this.configureLrsEnvType === 'trial') {
                this.trialEnvironment.lrsUrl = this.configureLrsUrl;
                this.trialEnvironment.lrsAuthToken = this.configureLrsAuthToken;
            } else if (this.configureLrsEnvType === 'main') {
                this.mainEnvironment.lrsUrl = this.configureLrsUrl;
                this.mainEnvironment.lrsAuthToken = this.configureLrsAuthToken;
            } else if (this.configureLrsEnvType === 'sub') {
                this.selectedSubEnvironment.lrsUrl = this.configureLrsUrl;
                this.selectedSubEnvironment.lrsAuthToken = this.configureLrsAuthToken;
            }
        },
        async saveAppropriateLrsConfig() {
            let lrsConfigRet = null;
            if (this.configureLrsEnvType === 'trial') {
                lrsConfigRet = await this.performUpdateMainEnvironmentLrsConfig(this.trialEnvironment.mainEnvId, this.configureLrsUrl, this.configureLrsAuthToken);
            } else if (this.configureLrsEnvType === 'main') {
                lrsConfigRet = await this.performUpdateMainEnvironmentLrsConfig(this.mainEnvironment.mainEnvId, this.configureLrsUrl, this.configureLrsAuthToken);
            } else if (this.configureLrsEnvType === 'sub') {
                lrsConfigRet = await this.performUpdateSubEnvironmentLrsConfig(this.selectedSubEnvironment.subEnvId, this.configureLrsUrl, this.configureLrsAuthToken);
            }
            return lrsConfigRet;
        },
        async saveEnvironmentLrsConfig() {
            this.checkLrsConfigData();
            if (!this.configureLrsUrlInvalid && !this.configureLrsAuthTokenInvalid) {
                this.configureLrsBusy = true;
                const lrsConfigRet = await this.saveAppropriateLrsConfig();
                if (lrsConfigRet) {
                    if (lrsConfigRet.updated) {
                        this.configureLrsShowSavedMessage = true;
                        this.updateLocalLrsConfigData();
                    } else {
                        this.configureLrsRetMsg = lrsConfigRet.failureReason;
                    }
                }
            }
            this.configureLrsBusy = false;
        },
        isLrsConfiguredForTrialEnvironment() {
            if (this.trialEnvironment.lrsUrl && this.trialEnvironment.lrsUrl.trim().length > 0 &&
                this.trialEnvironment.lrsAuthToken && this.trialEnvironment.lrsAuthToken.trim().length > 0) return true;
            else return false;
        },
        isLrsConfiguredForMainEnvironment() {
            if (this.mainEnvironment.lrsUrl && this.mainEnvironment.lrsUrl.trim().length > 0 &&
                this.mainEnvironment.lrsAuthToken && this.mainEnvironment.lrsAuthToken.trim().length > 0) return true;
            else return false;
        },
        isLrsConfiguredForSubEnvironment(subEnv) {
            if (subEnv.lrsUrl && subEnv.lrsUrl.trim().length > 0 &&
                subEnv.lrsAuthToken && subEnv.lrsAuthToken.trim().length > 0) return true;
            else return false;
        },
        showConfigureLrs(envType) {
            this.configureLrsEnvType = envType;
            this.configureLrsUrlInvalid = false;
            this.configureLrsAuthTokenInvalid = false;
            this.configureLrsDisableSaveButton = true;
            this.configureLrsBusy = false;
            this.configureLrsShowSavedMessage = false;
            this.configureLrsRetMsg = '';
            if (envType === 'trial') {
                this.configureLrsUrl = this.trialEnvironment.lrsUrl;
                this.configureLrsAuthToken = this.trialEnvironment.lrsAuthToken;
                this.generateLrsUsernamePassword();
                this.configureLrsSubdomain = this.trialEnvironment.subdomain;
            } else if (envType === 'main') {
                this.configureLrsUrl = this.mainEnvironment.lrsUrl;
                this.configureLrsAuthToken = this.mainEnvironment.lrsAuthToken;
                this.generateLrsUsernamePassword();
                this.configureLrsSubdomain = this.mainEnvironment.subdomain;
            } else if (envType === 'sub') {
                this.configureLrsUrl = this.selectedSubEnvironment.lrsUrl;
                this.configureLrsAuthToken = this.selectedSubEnvironment.lrsAuthToken;
                this.generateLrsUsernamePassword();
                this.configureLrsSubdomain = this.selectedSubEnvironment.subdomain;
            } else return;
            this.showSection('configureLrs');
        },
        convertWhitelistObjectArrayToStringArray(whitelistObjectArray) {
            const ret = [];
            if (whitelistObjectArray) {
                for (const wli of whitelistObjectArray) {
                    ret.push(wli.val.trim().toLowerCase());
                }
            }
            return ret;
        },
        convertWhitelistStringArrayToObjectArray(whitelistStringArray) {
            const ret = [];
            if (whitelistStringArray) {
                for (const wli of whitelistStringArray) {
                    ret.push({val: wli});
                }
            }
            return ret;
        },
        cleanWhitelistDataEntry(entry) {
            if (entry) {
                let cleanedEntry = entry.replace('http://', '').replace('https://', '');
                if (cleanedEntry.indexOf('/') > -1) {
                    cleanedEntry = cleanedEntry.substring(0, cleanedEntry.indexOf('/'));
                }
                // console.log(cleanedEntry);
                return cleanedEntry;
            } else return '';
        },
        cleanAndValidateWhitelistData() {
            this.configureWhitelistAnyDomainsInvalid = false;
            this.configureWhitelistDuplicateDomains = false;
            this.configureWhitelistDisableSaveButton = false;
            const uniqueDomains = [];
            for (const d of this.configureWhitelistDomainList) {
                d.val = d.val.toLowerCase().trim();
                if (!this.isValidWhitelistEntry(d.val)) {
                    const cleanedVal = this.cleanWhitelistDataEntry(d.val);
                    if (this.isValidWhitelistEntry(cleanedVal)) d.val = cleanedVal;
                    else this.configureWhitelistAnyDomainsInvalid = true;
                }
                if (!this.configureWhitelistAnyDomainsInvalid) {
                    if (uniqueDomains.includes(d.val)) this.configureWhitelistDuplicateDomains = true;
                    else (uniqueDomains.push(d.val));
                }
            }
            if (this.configureWhitelistAnyDomainsInvalid || this.configureWhitelistDuplicateDomains) {
                this.configureWhitelistDisableSaveButton = true;
            } else this.configureWhitelistDisableSaveButton = false;
        },
        updateLocalWhitelistData() {
            if (this.configureWhitelistEnvType === 'trial') {
                this.trialEnvironment.domainWhitelist = this.convertWhitelistObjectArrayToStringArray(this.configureWhitelistDomainList);
            } else if (this.configureWhitelistEnvType === 'main') {
                this.mainEnvironment.domainWhitelist = this.convertWhitelistObjectArrayToStringArray(this.configureWhitelistDomainList);
            } else if (this.configureWhitelistEnvType === 'sub') {
                this.selectedSubEnvironment.domainWhitelist = this.convertWhitelistObjectArrayToStringArray(this.configureWhitelistDomainList);
            }
        },
        async saveAppropriateWhitelist() {
            let configWlRet = null;
            if (this.configureWhitelistEnvType === 'trial') {
                configWlRet = await this.performUpdateMainEnvironmentWhitelist(this.trialEnvironment.mainEnvId, this.convertWhitelistObjectArrayToStringArray(this.configureWhitelistDomainList));
            } else if (this.configureWhitelistEnvType === 'main') {
                configWlRet = await this.performUpdateMainEnvironmentWhitelist(this.mainEnvironment.mainEnvId, this.convertWhitelistObjectArrayToStringArray(this.configureWhitelistDomainList));
            } else if (this.configureWhitelistEnvType === 'sub') {
                configWlRet = await this.performUpdateSubEnvironmentWhitelist(this.selectedSubEnvironment.subEnvId, this.convertWhitelistObjectArrayToStringArray(this.configureWhitelistDomainList));
            }
            return configWlRet;
        },
        async saveConfigureWhitelist() {
            this.cleanAndValidateWhitelistData();
            if (!this.configureWhitelistAnyDomainsInvalid && !this.configureWhitelistDuplicateDomains) {
                this.configureWhitelistIsBusy = true;
                const configWlRet = await this.saveAppropriateWhitelist();
                if (configWlRet) {
                    if (configWlRet.updated) {
                        this.configureWhitelistShowSavedMessage = true;
                        this.updateLocalWhitelistData();
                    } else {
                        this.configureWhitelistRetMsg = configWlRet.failureReason;
                    }
                }
            }
        },
        showConfigureWhitelist(envType) {
            this.configureWhitelistIsBusy = false;
            this.configureWhitelistDisableSaveButton = true;
            this.configureWhitelistShowSavedMessage = false;
            this.configureWhitelistEnvType = envType;
            this.configureWhitelistAnyDomainsInvalid = false;
            this.configureWhitelistDuplicateDomains = false;
            this.configureWhitelistRetMsg = '';
            if (envType === 'trial') {
                this.configureWhitelistDomainList = this.convertWhitelistStringArrayToObjectArray(this.trialEnvironment.domainWhitelist);
            } else if (envType === 'main') {
                this.configureWhitelistDomainList = this.convertWhitelistStringArrayToObjectArray(this.mainEnvironment.domainWhitelist);
            } else if (envType === 'sub') {
                this.configureWhitelistDomainList = this.convertWhitelistStringArrayToObjectArray(this.selectedSubEnvironment.domainWhitelist);
            } else return;
            if (this.configureWhitelistDomainList.length < 1) this.addConfigureWhitelistAddress();
            this.showSection('ConfigureWhitelist');
        },
        addConfigureWhitelistAddress() {
            const blank = {val: ''};
            this.configureWhitelistDomainList.push(blank);
        },
        deleteConfigureWhitelistAddress(index) {
            this.configureWhitelistDomainList.splice(index, 1);
            // if (this.configureWhitelistDomainList.length < 1) this.addConfigureWhitelistAddress();
            this.cleanAndValidateWhitelistData();
        },


        doesTrialHaveLearnerSlotsAvailable() {
            return this.trialEnvironment.numMaxLearners >= (this.trialEnvironment.numCurrentLearners + this.trialEnvironment.numPendingLearnerInvitations);
        },
        doesMainEnvironmentHaveLearnerSlotsAvailable() {
            return this.mainEnvironment.numMaxLearners >= (this.mainEnvironment.numCurrentLearners + this.mainEnvironment.numPendingLearnerInvitations);
        },
        doesSelectedSubEnvironmentHaveLearnerSlotsAvailable() {
            return this.selectedSubEnvironment.numMaxLearners >= (this.selectedSubEnvironment.numCurrentLearners + this.selectedSubEnvironment.numPendingLearnerInvitations);
        },
        showTrialEnvironmentDetails() {
            this.showSection('trialEnvironmentDetails');
        },
        showMainEnvironmentLibraryDetails() {
            this.showSection('mainEnvironmentDetails');
        },
        showSubEnvironmentLibraryDetails(subEnv) {
            this.selectedSubEnvironment = subEnv;
            this.showSection('subEnvironmentDetails');
        },
        async reactivateSubscriptionForMainEnv() {
            this.reactivateMainEnvConfirmModalBusy = true;
            const reactivateRet = await this.performReactivateMainEnvironmentSubscription(this.mainEnvironment.mainEnvId);
            if (reactivateRet.subscriptionReactivated) {
                this.reactivateMainEnvConfirmRetMsg = '';
                this.mainEnvironment.cancelAtPeriodEnd = false;
                this.reactivateMainEnvConfirmModalBusy = false;
                this.reactivateMainEnvConfirmModalActive = false;
            } else {
                this.reactivateMainEnvConfirmRetMsg = reactivateRet.failureReason;
                this.reactivateMainEnvConfirmModalBusy = false;
            }
        },
        async cancelSubscriptionForMainEnv() {
            this.cancelMainEnvConfirmModalBusy = true;
            const cancelRet = await this.performCancelMainEnvironmentSubscription(this.mainEnvironment.mainEnvId);
            if (cancelRet.subscriptionCanceled) {
                this.cancelMainEnvConfirmRetMsg = '';
                this.mainEnvironment.cancelAtPeriodEnd = true;
                for (const se of this.subEnvironmentList) {
                    se.cancelAtPeriodEnd = true;
                }
                this.cancelMainEnvConfirmModalBusy = false;
                this.cancelMainEnvConfirmModalActive = false;
            } else {
                this.cancelMainEnvConfirmRetMsg = cancelRet.failureReason;
                this.cancelMainEnvConfirmModalActive = false;
            }
        },
        closeReactivateMainEnvConfirmModal() {
            this.reactivateMainEnvConfirmModalActive = false;
        },
        closeCancelMainEnvConfirmModal() {
            this.cancelMainEnvConfirmModalActive = false;
        },
        showReactivateMainEnvConfirmModal() {
            this.reactivateMainEnvConfirmModalBusy = false;
            this.reactivateMainEnvConfirmRetMsg = '';
            this.reactivateMainEnvConfirmModalActive = true;
        },
        showCancelMainEnvConfirmModal() {
            this.cancelMainEnvConfirmModalBusy = false;
            this.cancelMainEnvConfirmRetMsg = '';
            this.cancelMainEnvConfirmModalActive = true;
        },
        async reactivateSubscriptionForSubEnv() {
            this.reactivateSubEnvConfirmModalBusy = true;
            const reactivateRet = await this.performReactivateSubEnvironmentSubscription(this.selectedSubEnvironment.subEnvId);
            if (reactivateRet.subscriptionReactivated) {
                this.reactivateSubEnvConfirmRetMsg = '';
                this.selectedSubEnvironment.cancelAtPeriodEnd = false;
                this.reactivateSubEnvConfirmModalBusy = false;
                this.reactivateSubEnvConfirmModalActive = false;
            } else {
                this.reactivateSubEnvConfirmRetMsg = reactivateRet.failureReason;
                this.reactivateSubEnvConfirmModalBusy = false;
            }
        },
        async cancelSubscriptionForSubEnv() {
            this.cancelSubEnvConfirmModalBusy = true;
            const cancelRet = await this.performCancelSubEnvironmentSubscription(this.selectedSubEnvironment.subEnvId);
            if (cancelRet.subscriptionCanceled) {
                this.cancelSubEnvConfirmRetMsg = '';
                this.selectedSubEnvironment.cancelAtPeriodEnd = true;
                this.cancelSubEnvConfirmModalBusy = false;
                this.cancelSubEnvConfirmModalActive = false;
            } else {
                this.cancelSubEnvConfirmRetMsg = cancelRet.failureReason;
                this.cancelSubEnvConfirmModalActive = false;
            }
        },
        closeReactivateSubEnvConfirmModal() {
            this.reactivateSubEnvConfirmModalActive = false;
        },
        closeCancelSubEnvConfirmModal() {
            this.cancelSubEnvConfirmModalActive = false;
        },
        showReactivateSubEnvConfirmModal() {
            this.reactivateSubEnvConfirmModalBusy = false;
            this.reactivateSubEnvConfirmRetMsg = '';
            this.reactivateSubEnvConfirmModalActive = true;
        },
        showCancelSubEnvConfirmModal() {
            this.cancelSubEnvConfirmModalBusy = false;
            this.cancelSubEnvConfirmRetMsg = '';
            this.cancelSubEnvConfirmModalActive = true;
        },
        async processRedeemEnvironmentTokenOnTrial() {
            this.redeemTokenModalBusy = true;
            const redeemTokenRet = await this.performRedeemTokenOnTrial(this.redeemTokenSelectedToken.envTokenId, this.trialEnvironment.mainEnvId);
            if (redeemTokenRet.tokenRedeemed) {
                this.redeemTokenModalBusy = false;
                this.redeemTokenModalActive = false;
                this.resetSectionHistory('account');
                this.buildCustomerAccountData();
            } else {
                this.redeemTokenRetMsg = redeemTokenRet.rejectReason;
                this.redeemTokenModalBusy = false;
            }
        },
        async processRedeemEnvironmentTokenOnNewMain() {
            await this.attemptValidateSubdomainForNewEnv();
            if (this.validSubdomainAvailableNewEnv) {
                this.redeemTokenModalBusy = true;
                const redeemTokenRet = await this.performRedeemTokenOnNewMainEnvironment(this.redeemTokenSelectedToken.envTokenId, this.requestedSubdomainNewEnv);
                if (redeemTokenRet.tokenRedeemed) {
                    this.redeemTokenModalBusy = false;
                    this.redeemTokenModalActive = false;
                    this.resetSectionHistory('account');
                    this.buildCustomerAccountData();
                } else {
                    this.redeemTokenRetMsg = redeemTokenRet.rejectReason;
                    this.redeemTokenModalBusy = false;
                }
            }
        },
        async processRedeemEnvironmentTokenOnExpiredMain() {
            this.redeemTokenModalBusy = true;
            const redeemTokenRet = await this.performRedeemTokenOnExpiredMainEnvironment(this.redeemTokenSelectedToken.envTokenId, this.mainEnvironment.mainEnvId);
            if (redeemTokenRet.tokenRedeemed) {
                this.redeemTokenModalBusy = false;
                this.redeemTokenModalActive = false;
                this.resetSectionHistory('account');
                this.buildCustomerAccountData();
            } else {
                this.redeemTokenRetMsg = redeemTokenRet.rejectReason;
                this.redeemTokenModalBusy = false;
            }
        },
        async processRedeemEnvironmentTokenOnNewSub() {
            await this.attemptValidateSubdomainForNewEnv();
            if (this.validSubdomainAvailableNewEnv) {
                this.redeemTokenModalBusy = true;
                const redeemTokenRet = await this.performRedeemTokenOnNewSubEnvironment(this.redeemTokenSelectedToken.envTokenId, this.requestedSubdomainNewEnv, this.mainEnvironment.mainEnvId);
                if (redeemTokenRet.tokenRedeemed) {
                    this.redeemTokenModalBusy = false;
                    this.redeemTokenModalActive = false;
                    this.resetSectionHistory('account');
                    this.buildCustomerAccountData();
                } else {
                    this.redeemTokenRetMsg = redeemTokenRet.rejectReason;
                    this.redeemTokenModalBusy = false;
                }
            }
        },
        async processRedeemEnvironmentTokenOnExpiredSub() {
            if (this.redeemTokenModalSelectedExpiredSubEnvId > 0) {
                this.redeemTokenModalBusy = true;
                const redeemTokenRet = await this.performRedeemTokenOnExpiredSubEnvironment(this.redeemTokenSelectedToken.envTokenId, this.redeemTokenModalSelectedExpiredSubEnvId);
                if (redeemTokenRet.tokenRedeemed) {
                    this.redeemTokenModalBusy = false;
                    this.redeemTokenModalActive = false;
                    this.resetSectionHistory('account');
                    this.buildCustomerAccountData();
                } else {
                    this.redeemTokenRetMsg = redeemTokenRet.rejectReason;
                    this.redeemTokenModalBusy = false;
                }
            }
        },
        async processRedeemToken() {
            if (this.isEnvironmentTokenMainType(this.redeemTokenSelectedToken)) {
                if (this.hasTrialEnvironment) {
                    await this.processRedeemEnvironmentTokenOnTrial();
                } else if (this.hasMainEnvironmentExpired) {
                    await this.processRedeemEnvironmentTokenOnExpiredMain();
                } else if (this.hasMainEnvironment) {
                    // console.log('This should never happen...bad juju');
                    this.redeemTokenRetMsg = 'You already have an active main environment';
                } else {
                    await this.processRedeemEnvironmentTokenOnNewMain();
                }
            } else {
                if (this.redeemingTokenOnNewSubEnv) {
                    await this.processRedeemEnvironmentTokenOnNewSub();
                } else if (this.redeemingTokenOnExpiredSubEnv) {
                    await this.processRedeemEnvironmentTokenOnExpiredSub();
                }
            }
        },
        resetRedeemTokenModalFlags() {
            this.requestedSubdomainNewEnv = '';
            this.subdomainInvalidNewEnv = false;
            this.subdomainUnavailableNewEnv = false;
            this.validSubdomainAvailableNewEnv = false;
            this.redeemTokenModalShowRedeemButton = false;
            this.redeemTokenModalShowSubdomainSection = false;
            this.redeemingTokenOnNewSubEnv = false;
            this.redeemingTokenOnExpiredSubEnv = false;
            this.redeemTokenModalShowAskWhatSubEnvSection = false;
        },
        setRedeemSubEnvTokenOnExpired() {
            this.redeemTokenModalSelectedExpiredSubEnvId = this.expiredSubEnvironmentList[0].subEnvId;
            this.redeemTokenModalShowAskWhatSubEnvSection = false;
            this.redeemingTokenOnExpiredSubEnv = true;
            this.redeemTokenModalShowRedeemButton = true;
        },
        setRedeemSubEnvTokenOnNew() {
            this.redeemTokenModalShowAskWhatSubEnvSection = false;
            this.redeemingTokenOnNewSubEnv = true;
            this.redeemTokenModalShowSubdomainSection = true;
        },
        setSelectedTokenToRedeem(et) {
            this.resetRedeemTokenModalFlags();
            this.redeemTokenSelectedToken = et;
            if (this.isEnvironmentTokenMainType(this.redeemTokenSelectedToken)) {
                if (this.hasTrialEnvironment || this.hasMainEnvironmentExpired) {
                    this.redeemTokenModalShowRedeemButton = true;
                } else {
                    this.redeemTokenModalShowSubdomainSection = true;
                }
            } else {
                if (this.hasAnySubEnvironmentExpired) {
                    this.redeemTokenModalShowAskWhatSubEnvSection = true;
                } else {
                    this.redeemingTokenOnNewSubEnv = true;
                    this.redeemTokenModalShowSubdomainSection = true;
                }
            }
            this.redeemTokenTokenSelected = true;
        },
        cancelRedeemEnvironmentToken() {
            this.redeemTokenModalActive = false;
        },
        openRedeemEnvironmentTokenModal() {
            this.redeemTokenRetMsg = '';
            this.redeemTokenSelectedToken = null;
            this.redeemTokenTokenSelected = false;
            this.redeemTokenModalBusy = false;
            this.redeemTokenModalShowRedeemButton = false;
            this.redeemTokenModalActive = true;
        },
        async isSubdomainReservedByApp(subdomain) {
            if (this.appReservedSubdomains.length <= 0) {
                this.appReservedSubdomains = await this.performGetAppReservedSubdomains();
            }
            return this.appReservedSubdomains.includes(subdomain);
        },
        async checkSubdomainAvailabilityNewEnv() {
            const rso = await this.performSubdomainAvailable(this.requestedSubdomainNewEnv);
            if (rso.status === 'ok') {
                if (!rso.isValid) {
                    this.subdomainInvalidNewEnv = true;
                    this.redeemTokenModalShowRedeemButton = false;
                } else if (!rso.isAvailable) {
                    this.subdomainUnavailableNewEnv = true;
                    this.redeemTokenModalShowRedeemButton = false;
                } else {
                    this.validSubdomainAvailableNewEnv = true;
                    this.redeemTokenModalShowRedeemButton = true;
                }
            } else {
                this.subdomainUnavailableNewEnv = true;
                this.redeemTokenModalShowRedeemButton = false;
            }
            this.validatingSubdomainNewEnv = false;
        },
        async attemptValidateSubdomainForNewEnv() {
            this.validatingSubdomainNewEnv = true;
            this.redeemTokenModalShowRedeemButton = false;
            this.requestedSubdomainNewEnv = this.requestedSubdomainNewEnv.toLowerCase().trim();
            this.subdomainInvalidNewEnv = false;
            this.subdomainUnavailableNewEnv = false;
            this.validSubdomainAvailableNewEnv = false;
            if (this.isValidSubdomain(this.requestedSubdomainNewEnv)) {
                const sdReserved = await this.isSubdomainReservedByApp(this.requestedSubdomainNewEnv);
                if (sdReserved) this.subdomainUnavailableNewEnv = true;
                else await this.checkSubdomainAvailabilityNewEnv();
            } else this.subdomainInvalidNewEnv = true;
            this.validatingSubdomainNewEnv = false;
        },
        applyReservedSubdomainsToNewEnv(subdomain) {
            this.requestedSubdomainNewEnv = subdomain;
            this.checkSubdomainEntryForNewEnv();
        },
        checkSubdomainEntryForNewEnv() {
            this.requestedSubdomainNewEnv = this.requestedSubdomainNewEnv.toLowerCase();
            this.redeemTokenModalShowRedeemButton = this.requestedSubdomainNewEnv.trim().length > 0;
        },
        getReadableEnvironmentTokenProductType(envToken) {
            if (envToken.productType) {
                if (envToken.productType.toLowerCase() === 'main') return 'Main Library';
                else return 'Library Addition';
            } else return 'unknown';
        },
        getProductNameForEnvironmentToken(envToken) {
            if (this.productMap[envToken.stripeProductId]) {
                return this.productMap[envToken.stripeProductId].name;
            } else return 'Unknown Product';
        },
        isEnvironmentTokenMainType(envToken) {
            if (envToken) {
                return (envToken.productType.toLowerCase() === 'main');
            } else return false;
        },
        verifyAllAccountDataPresent(customerAccountData) {
            if (customerAccountData.environmentTokens &&
                customerAccountData.mainEnvironments &&
                customerAccountData.reservedSubdomains &&
                customerAccountData.trialEnvironments &&
                customerAccountData.subEnvironments) {
                this.accountDataLoaded = true;
            }
        },
        determineHasEverHadTrialEnvironment() {
            this.hasEverHadTrialEnvironment = false;
            if (this.hasTrialEnvironment) this.hasEverHadTrialEnvironment = true;
            else if (this.mainEnvironment.wasEverTrialEnv) this.hasEverHadTrialEnvironment = true;
        },
        async buildProductMap() {
            this.productMap = {};
            let productList = this.$store.getters.getProducts;
            if (!productList) productList = await this.performProductSearch();
            for (const p of productList) {
                this.productMap[p.id] = p;
            }
        },
        hasSubEnvironmentExpired(subEnv) {
            return subEnv.datePeriodExpires < Date.now();
        },
        determineAuthoringSpaceAllocatedToMainEnv() {
            // If the storage ever changes from 'GB', we'll need to redo this
            if (!this.mainEnvironment.authoringStorage) this.mainEnvironmentAuthoringStorageCapacity = '0GB';
            else if (this.mainEnvironment.authoringStorage.indexOf('GB') > -1) {
                let space = parseInt(this.mainEnvironment.authoringStorage.replace('GB', ''));
                for (const se of this.subEnvironmentList) {
                    if (!this.hasSubEnvironmentExpired(se) && !se.stripeSubscriptionDeleted) {
                        space += parseInt(se.authoringStorage.replace('GB', ''));
                    }
                }
                this.mainEnvironmentAuthoringStorageCapacity = space + 'GB';
            } else if (this.mainEnvironment.authoringStorage.indexOf('MB') > -1) {
                this.mainEnvironmentAuthoringStorageCapacity = this.mainEnvironment.authoringStorage;
            }
        },
        determineAnySubEnvironmentExpired() {
            this.expiredSubEnvironmentList = [];
            this.hasAnySubEnvironmentExpired = false;
            for (const se of this.subEnvironmentList) {
                if (this.hasSubEnvironmentExpired(se)) {
                    this.hasAnySubEnvironmentExpired = true;
                    this.expiredSubEnvironmentList.push(se);
                }
            }
        },
        determineTotalNumberCurrentLearners() {
            this.totalNumberOfCurrentLearners = 0;
            if (this.hasTrialEnvironment) this.totalNumberOfCurrentLearners += this.trialEnvironment.numCurrentLearners;
            else if (this.hasMainEnvironment) {
                this.totalNumberOfCurrentLearners += this.mainEnvironment.numCurrentLearners;
                for (const se of this.subEnvironmentList) {
                    this.totalNumberOfCurrentLearners += se.numCurrentLearners;
                }
            }
        },
        buildManageLearnerAvailableEnvEntry(value, name, envId) {
            return {
                value: value,
                name: name,
                envId: envId
            };
        },
        buildManageLearnersAvailableEnvironments() {
            this.mngLrnAvailableEnv = [];
            if (this.hasTrialEnvironment) {
                this.mngLrnAvailableEnv.push(this.buildManageLearnerAvailableEnvEntry('trial', this.trialEnvironment.subdomain, this.trialEnvironment.mainEnvId));
            }
            if (this.hasMainEnvironment) {
                this.mngLrnAvailableEnv.push(this.buildManageLearnerAvailableEnvEntry('main', this.mainEnvironment.subdomain, this.mainEnvironment.mainEnvId));
            }
            for (const se of this.subEnvironmentList) {
                this.mngLrnAvailableEnv.push(this.buildManageLearnerAvailableEnvEntry('sub-' + se.subEnvId, se.subdomain, se.subEnvId));
            }
        },
        parseCustomerAccountData(customerAccountData) {
            this.reservedSubdomains = customerAccountData.reservedSubdomains;
            this.environmentTokenList = customerAccountData.environmentTokens;
            this.hasEnvironmentTokens = this.environmentTokenList.length > 0;
            this.subEnvironmentList = customerAccountData.subEnvironments;
            if (customerAccountData.mainEnvironments.length > 0) {
                this.hasMainEnvironment = true;
                this.mainEnvironment = customerAccountData.mainEnvironments[0];
                this.hasMainEnvironmentExpired = this.mainEnvironment.datePeriodExpires < Date.now();
                this.determineAuthoringSpaceAllocatedToMainEnv();
                this.hasTrialEnvironment = false;
            } else {
                this.hasMainEnvironment = false;
                this.mainEnvironment = {};
                if (customerAccountData.trialEnvironments.length > 0) {
                    this.hasTrialEnvironment = true;
                    this.trialEnvironment = customerAccountData.trialEnvironments[0];
                    this.hasTrialEnvironmentExpired = this.trialEnvironment.datePeriodExpires < Date.now();
                } else {
                    this.hasTrialEnvironment = false;
                    this.trialEnvironment = {};
                }
            }
            this.determineAnySubEnvironmentExpired();
            this.determineHasEverHadTrialEnvironment();
            this.determineTotalNumberCurrentLearners();
            this.buildManageLearnersAvailableEnvironments();
        },
        async buildCustomerAccountData() {
            this.amBusy = true;
            this.mngLrnCachedLearnerData = {};
            this.accountDataLoaded = false;
            this.profileData = this.$store.getters.getCustomerProfile;
            const customerAccountData = await this.performCustomerAccountDataSearch();
            this.verifyAllAccountDataPresent(customerAccountData);
            if (this.accountDataLoaded) {
                this.parseCustomerAccountData(customerAccountData);
                await this.buildProductMap();
            }
            this.amBusy = false;
        },
        showLastInvoice(lastInvoiceUrl) {
            if (lastInvoiceUrl) {
                window.open(lastInvoiceUrl, '_blank');
            }
        },
        async goToCustomerPortal() {
            const redirectRet = await this.performGetRedirectToCustomerPortal();
            if (redirectRet.sessionUrl) {
                window.open(redirectRet.sessionUrl, '_blank');
            }
        },
        goToPricing() {
            this.$router.push({name: 'Pricing'});
        },
        goToPricingAddons() {
            this.$router.push({name: 'Pricing', hash: '#addons'});
        },
        getLogoImage: function(url) {
            if (url === '') return require('@/assets/favicon-library.png');
            return url;
        },
        resetSectionHistory: function(newSection) {
            if (!newSection) newSection = 'account';
            this.sectionHistory = [newSection];
        },
        getCurrentSection: function() {
            return this.sectionHistory[this.sectionHistory.length - 1];
        },
        showSection: function(newSection) {
            this.sectionHistory.push(newSection);
        },
        closeSection: function() {
            this.sectionHistory.pop();
        },
        launchStudio: function(subdomain) {
            if (subdomain) window.open('https://' + subdomain + '.pebl.pro/studio', '_blank');
        },
        launchLibrary: function(subdomain) {
            if (subdomain) window.open('https://' + subdomain + '.pebl.pro', '_blank');
        },
        // launchModeration: function() {
        //     // TODO: Launch selected library to show MODERATION dashboard
        //     // REPLACE this link!
        //     // window.open('https://qa.author.pebl.pro/?', '_blank');
        //     var temp = confirm('TO DO: Launch the Moderation Dashboard for the selected library. \r\nOpen placeholder link?');
        //     if (temp) {
        //         window.open('https://qa.author.pebl.pro/?', '_blank');
        //     }
        // },
        percentUsedTagClass: function(num, max) {
            let tagClass = 'is-success';
            var warn = max * 0.6;
            var danger = max * 0.8;
            if (num > warn) tagClass = 'is-warning';
            if (num > danger) tagClass = 'is-danger';
            return tagClass;
        },
        generateLrsToken: function() {
            if (!this.configureLrsAuthUsername && !this.configureLrsAuthPassword) {
                this.configureLrsAuthToken = '';
            } else {
                this.configureLrsAuthToken = Base64.stringify(
                    Latin1.parse(this.configureLrsAuthUsername + ':' + this.configureLrsAuthPassword)
                );
            }
        },
        generateLrsUsernamePassword: function() {
            if (this.configureLrsAuthToken) {
                try {
                    const arr = atob(this.configureLrsAuthToken).split(':');
                    if (arr.length === 2) {
                        this.configureLrsAuthUsername = arr[0];
                        this.configureLrsAuthPassword = arr[1];
                    }
                } catch (e) {
                    //
                }
            }
        }
    }
};
</script>

<style scoped>
th, td {
    vertical-align: middle;
}
</style>
