/* eslint-disable */
export const purchaseApi = {
    name: 'purchaseApi',
    data: () => ({
        ACT_PROD_PRICES_SERVICE: '/get-active-products-with-prices',
        PRODUCTS_SERVICE: '/get-products',
        CHECKOUT_SERVICE: '/create-checkout-session',
        CANCEL_CHECKOUT_SERVICE: '/cancel-checkout',
        SUBDOMAIN_AVAILABLE_SERVICE: '/subdomain-available',
        LOGIN_SERVICE: '/login',
        LOGOUT_SERVICE: '/logout',
        USER_PROFILE_SERVICE: '/user/profile',
        USER_SIMPLE_PROFILE_SERVICE: '/get-simple-customer-profile',
        APP_RESERVED_SUBDOMAINS_SERVICE: '/get-app-reserved-subdomains',
        RESERVE_SUBDOMAIN_SERVICE: '/reserve-subdomain',
        TRIAL_ATTRIBUTES_SERVICE: '/get-trial-attributes',
        CUSTOMER_TRIAL_ELIGIBLE_SERVICE: '/customer-trial-eligible',
        START_TRIAL_SERVICE: '/start-trial',
        CANCEL_TRIAL_SERVICE: '/cancel-trial',
        CUSTOMER_ENVIRONMENT_SUMMARY_SERVICE: '/customer-environment-summary',
        CUSTOMER_TRIALS_SERVICE: '/get-customer-trials',
        CUSTOMER_ACCOUNT_DATA_SERVICE: '/get-customer-environments',
        REDEEM_TOKEN_ON_TRIAL_SERVICE: '/redeem-token-on-trial',
        REDEEM_TOKEN_ON_NEW_MAIN_SERVICE: '/redeem-main-env-token-new-main',
        REDEEM_TOKEN_ON_EXPIRED_MAIN_SERVICE: '/redeem-main-env-token-expired-main',
        REDEEM_TOKEN_ON_NEW_SUB_SERVICE: '/redeem-sub-env-token-new-sub',
        REDEEM_TOKEN_ON_EXPIRED_SUB_SERVICE: '/redeem-sub-env-token-expired-sub',
        CANCEL_MAIN_ENV_SUB_SERVICE: '/cancel-main-env-subscription',
        REACTIVATE_MAIN_ENV_SUB_SERVICE: '/reactivate-main-env-subscription',
        CANCEL_SUB_ENV_SUB_SERVICE: '/cancel-sub-env-subscription',
        REACTIVATE_SUB_ENV_SUB_SERVICE: '/reactivate-sub-env-subscription',
        INVITE_LEARNER_MAIN_ENV_SERVICE: '/create-learner-invites-main-env',
        INVITE_LEARNER_SUB_ENV_SERVICE: '/create-learner-invites-sub-env',
        REMOVE_LEARNER_INV_MAIN_ENV_SERVICE: '/remove-learner-invite-main-env',
        REMOVE_LEARNER_INV_SUB_ENV_SERVICE: '/remove-learner-invite-sub-env',
        REMOVE_LEARNER_MAIN_ENV_SERVICE: '/remove-learner-main-env',
        REMOVE_LEARNER_SUB_ENV_SERVICE: '/remove-learner-sub-env',
        REDIRECT_TO_CUSTOMER_PORTAL_SERVICE: '/create-customer-portal-session',
        UPDATE_MAIN_ENV_LRS_CONFIG_SERVICE: '/update-main-env-lrs-config',
        UPDATE_SUB_ENV_LRS_CONFIG_SERVICE: '/update-sub-env-lrs-config',
        UPDATE_MAIN_ENV_WHITELIST_SERVICE: '/update-main-env-whitelist',
        UPDATE_SUB_ENV_WHITELIST_SERVICE: '/update-sub-env-whitelist',
        MAIN_ENV_LEARNER_LIST_SERVICE: '/get-learners-main-env',
        SUB_ENV_LEARNER_LIST_SERVICE: '/get-learners-sub-env',
        ADMIN_ENVS_FOR_CUSTOMER_SERVICE: '/admin-get-customer-envs',
        ADMIN_CUST_FOR_SUBDOMAIN_SERVICE: '/admin-get-customers-with-subdomain',
        ADMIN_CUST_LIST_SERVICE: '/admin-get-customer-list',
        ADMIN_CUST_UPGRADE_DATA_SERVICE: '/admin-get-customer-upgrade-data',
        ADMIN_UPGRADE_CUST_ENV_SERVICE: '/admin-upgrade-customer-main-env',
        PRD_LEARNERS_METADATA_KEY: 'Learners',
        PRD_AUTHORING_STORAGE_METADATA_KEY: 'Authoring Storage',
        PRD_BOOKSHELF_STORAGE_METADATA_KEY: 'Bookshelf Storage',
        PRD_TYPE_METADATA_KEY: 'Type',
        PRD_NUM_PEBLS_METADATA_KEY: 'Number of PeBLs',
        PRD_BANDWIDTH_METADATA_KEY: 'Bandwidth',
        PRD_MOD_DASH_METADATA_KEY: 'Moderation Dashboard',
        PRD_ACT_DASH_METADATA_KEY: 'Activity Dashboard',
        // TODO these all need to be updated based on environment, etc....
        CHECKOUT_SUCCESS_URL: window.location.protocol + '//' + window.location.host + '/checkoutSuccess',
        CHECKOUT_CANCEL_URL: window.location.protocol + '//' + window.location.host + '/checkoutCanceled',
        LOGIN_REDIRECT_URL_START:  window.location.protocol + '//' + window.location.host + '/home',
        LOGIN_REDIRECT_URL_LOGIN:  window.location.protocol + '//' + window.location.host + '/login',
        LOGIN_REDIRECT_URL_PRICING:  window.location.protocol + '//' + window.location.host + '/pricing',
        LOGIN_REDIRECT_URL_CHECKOUT_SUCCESS:  window.location.protocol + '//' + window.location.host + '/checkoutSuccess',
        LOGOUT_REDIRECT_URL_HOME: window.location.protocol + '//' + window.location.host,
        CUSTOMER_PORTAL_RETURN_URL_ACCOUNT_SETTINGS:  window.location.protocol + '//' + window.location.host + '/settings',
        PUBLIC_STRIPE_KEY: process.env.VUE_APP_PUBLIC_STRIPE_KEY,
        SUBSCRIPTION_API_HOST: process.env.VUE_APP_SUBSCRIPTION_API_HOST
    }),
    methods: {
        performExternalLogin(loginRedirectUrl) {
            window.location = this.SUBSCRIPTION_API_HOST + this.LOGIN_SERVICE + "?redirectUrl=" + encodeURIComponent(loginRedirectUrl);
        },
        performExternalLogout(logoutRedirectUrl) {
            window.location = this.SUBSCRIPTION_API_HOST + this.LOGOUT_SERVICE + "?redirectUrl=" + encodeURIComponent(logoutRedirectUrl);
        },
        async getUserProfileFromApi() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.USER_PROFILE_SERVICE, {
                method: 'GET',
                credentials: 'include'
            });
            if (response.status === 200) return await response.json();
            else return null;
        },
        async getSimpleUserProfileFromApi() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.USER_SIMPLE_PROFILE_SERVICE, {
                method: 'GET',
                credentials: 'include',
                cache: 'no-store'
            });
            if (response.status === 200) {
                let jsonReturn = await response.json();
                return jsonReturn.data;
            }
            else return null;
        },
        async performGetAppReservedSubdomains() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.APP_RESERVED_SUBDOMAINS_SERVICE, {
                method: 'GET'
            });
            let jsonReturn = await response.json();
            return jsonReturn.data.appReservedSubdomains;
        },
        async performReserveSubdomain(subdomain) {
            let sd = {subdomain: subdomain.toLowerCase()};
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.RESERVE_SUBDOMAIN_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(sd),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performSubdomainAvailable(subdomain) {
            let sd = {subdomain: subdomain.toLowerCase()};
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.SUBDOMAIN_AVAILABLE_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(sd),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performProductWithPricesSearch() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.ACT_PROD_PRICES_SERVICE, {
                method: 'GET',
                credentials: 'include'
            });
            let jsonReturn = await response.json();
            return jsonReturn.data.productsWithPrices;
        },
        async performProductSearch() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.PRODUCTS_SERVICE, {
                method: 'GET',
                credentials: 'include'
            });
            let jsonReturn = await response.json();
            return jsonReturn.data.products;
        },
        async performCustomerTrialSearch() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.CUSTOMER_TRIALS_SERVICE, {
                method: 'GET',
                credentials: 'include'
            });
            let jsonReturn = await response.json();
            return jsonReturn.data.trialEnvironments;
        },
        async performTrialAttributesSearch() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.TRIAL_ATTRIBUTES_SERVICE, {
                method: 'GET',
                credentials: 'include'
            });
            let jsonReturn = await response.json();
            return jsonReturn.data.trialAttributes;
        },
        async performCustomerEligibleForTrial() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.CUSTOMER_TRIAL_ELIGIBLE_SERVICE, {
                method: 'GET',
                credentials: 'include'
            });
            let jsonReturn = await response.json();
            return jsonReturn.data.eligibleForTrial;
        },
        async performCustomerStartTrial(subdomain) {
            let sd = {subdomain: subdomain.toLowerCase()};
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.START_TRIAL_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(sd),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performCustomerCancelTrial() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.CANCEL_TRIAL_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'}
            })
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performCustomerEnvironmentSummary() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.CUSTOMER_ENVIRONMENT_SUMMARY_SERVICE, {
                method: 'GET',
                credentials: 'include'
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performCustomerAccountDataSearch() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.CUSTOMER_ACCOUNT_DATA_SERVICE, {
                method: 'GET',
                credentials: 'include'
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performRedeemTokenOnTrial(envTokenId, trialEnvId) {
            let at = {
                envTokenId: envTokenId,
                trialEnvId: trialEnvId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REDEEM_TOKEN_ON_TRIAL_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(at),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performRedeemTokenOnNewMainEnvironment(envTokenId, subdomain) {
            let at = {
                envTokenId: envTokenId,
                subdomain: subdomain
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REDEEM_TOKEN_ON_NEW_MAIN_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(at),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performRedeemTokenOnExpiredMainEnvironment(envTokenId, expiredMainEnvId) {
            let at = {
                envTokenId: envTokenId,
                expiredMainEnvId: expiredMainEnvId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REDEEM_TOKEN_ON_EXPIRED_MAIN_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(at),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performRedeemTokenOnNewSubEnvironment(envTokenId, subdomain, mainEnvId) {
            let at = {
                envTokenId: envTokenId,
                subdomain: subdomain,
                mainEnvId: mainEnvId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REDEEM_TOKEN_ON_NEW_SUB_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(at),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performRedeemTokenOnExpiredSubEnvironment(envTokenId, expiredSubEnvId) {
            let at = {
                envTokenId: envTokenId,
                expiredSubEnvId: expiredSubEnvId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REDEEM_TOKEN_ON_EXPIRED_SUB_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(at),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performCancelMainEnvironmentSubscription(mainEnvId) {
            let ct = {
                mainEnvId: mainEnvId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.CANCEL_MAIN_ENV_SUB_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(ct),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performReactivateMainEnvironmentSubscription(mainEnvId) {
            let rt = {
                mainEnvId: mainEnvId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REACTIVATE_MAIN_ENV_SUB_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(rt),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performCancelSubEnvironmentSubscription(subEnvId) {
            let ct = {
                subEnvId: subEnvId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.CANCEL_SUB_ENV_SUB_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(ct),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performReactivateSubEnvironmentSubscription(subEnvId) {
            let rt = {
                subEnvId: subEnvId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REACTIVATE_SUB_ENV_SUB_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(rt),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performLearnerListFromMainEnvironment(mainEnvId) {
            let it = {
                mainEnvId: mainEnvId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.MAIN_ENV_LEARNER_LIST_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(it),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performLearnerListFromSubEnvironment(subEnvId) {
            let it = {
                subEnvId: subEnvId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.SUB_ENV_LEARNER_LIST_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(it),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performInviteLearnersToMainEnvironment(mainEnvId, emailList) {
            let it = {
                mainEnvId: mainEnvId,
                emailList: emailList
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.INVITE_LEARNER_MAIN_ENV_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(it),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performInviteLearnersToSubEnvironment(subEnvId, emailList) {
            let it = {
                subEnvId: subEnvId,
                emailList: emailList
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.INVITE_LEARNER_SUB_ENV_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(it),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performRemoveLearnerInviteFromMainEnvironment(mainEnvId, inviteId) {
            let it = {
                mainEnvId: mainEnvId,
                inviteId: inviteId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REMOVE_LEARNER_INV_MAIN_ENV_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(it),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performRemoveLearnerInviteFromSubEnvironment(subEnvId, inviteId) {
            let it = {
                subEnvId: subEnvId,
                inviteId: inviteId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REMOVE_LEARNER_INV_SUB_ENV_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(it),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performRemoveLearnerFromMainEnvironment(mainEnvId, learnerId) {
            let it = {
                mainEnvId: mainEnvId,
                learnerId: learnerId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REMOVE_LEARNER_MAIN_ENV_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(it),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performRemoveLearnerFromSubEnvironment(subEnvId, learnerId) {
            let it = {
                subEnvId: subEnvId,
                learnerId: learnerId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REMOVE_LEARNER_SUB_ENV_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(it),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performUpdateMainEnvironmentLrsConfig(mainEnvId, lrsUrl, lrsAuthToken) {
            let lt = {
                mainEnvId: mainEnvId,
                lrsUrl: lrsUrl,
                lrsAuthToken: lrsAuthToken
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.UPDATE_MAIN_ENV_LRS_CONFIG_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(lt),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performUpdateSubEnvironmentLrsConfig(subEnvId, lrsUrl, lrsAuthToken) {
            let lt = {
                subEnvId: subEnvId,
                lrsUrl: lrsUrl,
                lrsAuthToken: lrsAuthToken
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.UPDATE_SUB_ENV_LRS_CONFIG_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(lt),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performUpdateMainEnvironmentWhitelist(mainEnvId, whitelist) {
            let lt = {
                mainEnvId: mainEnvId,
                whitelist: whitelist
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.UPDATE_MAIN_ENV_WHITELIST_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(lt),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performUpdateSubEnvironmentWhitelist(subEnvId, whitelist) {
            let lt = {
                subEnvId: subEnvId,
                whitelist: whitelist
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.UPDATE_SUB_ENV_WHITELIST_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(lt),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        async performAdminGetEnvsForCustomer(customerEmail) {
            let lt = {
                customerEmail: customerEmail
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.ADMIN_ENVS_FOR_CUSTOMER_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(lt),
            });
            if (response.status === 200) {
                let jsonReturn = await response.json();
                return jsonReturn.data;
            }
            else return null;
        },
        async performAdminGetCustomerForSubdomain(subdomain) {
            let lt = {
                subdomain: subdomain
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.ADMIN_CUST_FOR_SUBDOMAIN_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(lt),
            });
            if (response.status === 200) {
                let jsonReturn = await response.json();
                return jsonReturn.data;
            }
            else return null;
        },
        async performAdminGetUpgradeDataForCustomer(customerEmail) {
            let lt = {
                customerEmail: customerEmail
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.ADMIN_CUST_UPGRADE_DATA_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(lt),
            });
            if (response.status === 200) {
                let jsonReturn = await response.json();
                return jsonReturn.data;
            }
            else return null;
        },
        async performAdminUpgradeCustomerEnvironment(mainEnvId, envTokenId) {
            let lt = {
                mainEnvId: mainEnvId,
                envTokenId: envTokenId
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.ADMIN_UPGRADE_CUST_ENV_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(lt),
            });
            if (response.status === 200) {
                let jsonReturn = await response.json();
                return jsonReturn.data;
            }
            else return null;
        },
        async performAdminGetCustomerList() {
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.ADMIN_CUST_LIST_SERVICE, {
                method: 'GET',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'}
            });
            if (response.status === 200) {
                let jsonReturn = await response.json();
                return jsonReturn.data;
            }
            else return null;
        },
        async performGetRedirectToCustomerPortal() {
            let rp = {
                returnUrl: this.CUSTOMER_PORTAL_RETURN_URL_ACCOUNT_SETTINGS
            };
            let response = await fetch(this.SUBSCRIPTION_API_HOST + this.REDIRECT_TO_CUSTOMER_PORTAL_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(rp),
            });
            let jsonReturn = await response.json();
            return jsonReturn.data;
        },
        buildCheckoutData(simpleProduct) {
            let checkoutData = {};
            checkoutData.productId = simpleProduct.productId;
            checkoutData.priceId = simpleProduct.priceId;
            checkoutData.checkoutMode = simpleProduct.checkoutMode;
            checkoutData.successUrl = this.CHECKOUT_SUCCESS_URL;
            checkoutData.cancelUrl = this.CHECKOUT_CANCEL_URL;
            checkoutData.productType = simpleProduct.productType.toLowerCase();
            if (simpleProduct.hasCoupon && simpleProduct.couponId && simpleProduct.couponId.length > 0) {
                checkoutData.couponId = simpleProduct.couponId;
            }
            return checkoutData;
        },
        performCheckout(simpleProduct) {
            let cd = this.buildCheckoutData(simpleProduct);
            let stripe = Stripe(this.PUBLIC_STRIPE_KEY);
            fetch(this.SUBSCRIPTION_API_HOST + this.CHECKOUT_SERVICE, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cd),
            })
                .then(function(response) {return response.json();})
                .then(function(session) {return stripe.redirectToCheckout({ sessionId: session.id });})
                .then(function(result) {
                    //console.log(result);
                    // TODO modify this
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, you should display the localized error message to your
                    // customer using `error.message`.
                    if (result.error) {
                        alert(result.error.message);
                    }
                })
                .catch(function(error) {
                    // TODO modify this
                    console.error('Error:', error);
                });
        }
    },
    computed: {
    }
};
