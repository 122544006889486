<template>
    <!-- Single feature preview block.
    Feature is randomly picked unless 'id' (number) is passed via component props -->

    <div
        v-if="showFeature"
        class="feature-container link-box">
        <router-link
            :to="{ path: '/features/' + feature.url }">
            <div class="columns is-8 is-mobile">
                <div class="column">
                    <div class="feature-image-container">
                        <div
                            class="feature-image"
                            :style="{ 'background-image': 'url('+ image +')'}">
                            <div>
                                <p class="feature-title">
                                    {{ feature.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="feature-blurb">
                        {{ feature.description }}
                    </p>
                </div>
            </div>
        </router-link>
    </div>
</template>


<script>

export default {
    name: 'FeatureBlock',
    props: {
        id: Number
    },
    components: {
    },
    data: () => {
        return {
        };
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl;
        },
        showFeature() {
            if (this.$store.state.featureSpotlights.length > 0 && this.feature) return true;
            return false;
        },
        feature() {
            if (this.$store.state.featureSpotlights.length < 1) return false;
            if (this.id) {
                return this.$store.state.featureSpotlights.filter(obj => {
                    return obj.id === this.id;
                })[0];
            }
            // else pick a random feature
            const rndNum = Math.floor(Math.random() * this.$store.state.featureSpotlights.length);
            return this.$store.state.featureSpotlights[rndNum];
        },
        image() {
            // Get smaller image if available
            if (this.feature.image.formats && this.feature.image.formats.small) {
                return this.strapiApiUrl + this.feature.image.formats.small.url;
            }
            return this.strapiApiUrl + this.feature.image.url;
        }
    },
    mounted() {
        if (this.$store.state.featureSpotlights.length < 1) this.$store.dispatch('getFeatureSpotlights');
    },
    methods: {
        stripHtml(text) {
            let cleanText = text.replaceAll(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/ig, '');
            cleanText = cleanText.replaceAll('*', '');
            cleanText = cleanText.replaceAll('#', '');
            return cleanText;
        }
    }
};
</script>


