<template>
    <!-- When you need an <a> that may or may not have a link -->
    <!-- Example use:

    <optional-router-link
        :disabled="isDisabled"
        :to="whatever">
        My link
    </optional-router-link>

    -->
    <router-link
        v-if="!disabled"
        v-bind="$attrs">
        <slot />
    </router-link>

    <a
        v-else
        v-bind="$attrs">
        <slot />
    </a>
</template>

<script>
export default {
    name: 'OptionalRouterLink',

    props: {
        params: Object,
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>
