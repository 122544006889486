<template>
    <article
        id="authorBio"
        class="media">
        <figure class="media-left mr-3">
            <p class="image is-64x64">
                <slot name="authorImage" />
            </p>
        </figure>

        <div class="media-content">
            <div class="content">
                <p>
                    <span class="author-name"><slot name="authorName" /></span>
                    <span class="author-tagline"><slot name="authorTagline" /></span>
                </p>
                <p class="author-blurb">
                    <slot name="authorBlurb" />
                </p>
            </div>
            <div class="author-socials">
                <slot name="authorLinks" />
            </div>
        </div>
    </article>
</template>

<script>
export default {

};
</script>
