<template>
    <div id="app">
        <skip-links />
        <Navbar />
        <!-- <AlertBar /> -->
        <div
            id="main-content"
            class="page-content">
            <transition name="fade">
                <router-view :key="$route.fullPath" />
            </transition>
        </div>
        <big-call-to-action />
        <Footer />
        <vue-cookie-accept-decline
            :ref="'cookie-popup'"
            :element-id="'cookie-popup'"
            :debug="false"
            :position="'bottom'"
            :type="'bar'"
            :disable-decline="true"
            :transition-name="'slideFromBottom'"
            :show-postpone-button="false"
            style="z-index: 999999"
            @status="cookieStatus"
            @clicked-accept="cookieClickedAccept">
            <div
                class="container"
                slot="message">
                <!-- <div> -->
                We use cookies to improve user experience, and analyze website traffic.
                For these reasons, we may share your site usage data with our analytics partners.
                By clicking “Accept Cookies,” you consent to store on your device all the technologies described in our
                <router-link :to="{name:'Cookie Policy'}">
                    Cookie Policy.
                </router-link>
                <!-- </div> -->
            </div>
            <div slot="acceptContent">
                Accept Cookies
            </div>
        </vue-cookie-accept-decline>
    </div>
</template>

<script>
import Navbar from '@/components/navbar.vue';
// import AlertBar from '@/components/alertBar.vue';
import Footer from '@/components/footer.vue';
import SkipLinks from './components/skipLinks.vue';
import BigCallToAction from '@/components/bigCallToAction.vue';

export default {
    name: 'App',
    components: {
        SkipLinks,
        Navbar,
        // AlertBar,
        Footer,
        BigCallToAction
    },
    // data: () => {
    // },
    mounted() {
        if (this.$zendesk) {
            this.$zendesk.load('a2364dea-4458-4ea5-9475-5168d2dda258');
            this.$zendesk.$on('loaded', (event) => {
                this.$zendesk.show();
                // this.$zendesk.identify({
                //     name: 'John'
                // });
            });
        }
    },
    metaInfo: {
        // Default subtitle if no subcomponent (%s) is defined by other page views
        title: 'Designed for you',
        // all titles will be injected into this template unless template is set to null
        titleTemplate: 'PeBL Pro | %s'
    },
    methods: {
        cookieStatus(status) {
            // TODO I have no idea what to do with this, but not having it was causing errors....
        },
        cookieClickedAccept() {
            // Called when user click "Accept Cookies"
            // https://github.com/promosis/vue-cookie-accept-decline
        }
    }
};
</script>
