<template>
    <section class="hero is-light is-fullheight-with-navbar">
        <div class="hero-body">
            <div class="container has-text-centered p-6">
                <i class="fa-fw fas fa-circle-notch fa-spin" /> Verifying login status...
            </div>
        </div>
    </section>
</template>

<script>

import {purchaseApi} from '../mixins/purchaseApi';
import {localStorageManager} from '../mixins/localStorageManager';

export default {
    name: 'Login',
    mixins: [purchaseApi, localStorageManager],
    data: () => {
        return {
        };
    },
    mounted: async function() {
        // const profile = await this.getUserProfileFromApi();
        // if (profile) {
        //     this.$store.commit('setLoggedIn', true);
        //     this.$store.commit('setCustomerProfile', profile);
        //     const chosenProduct = this.getChosenProductFromLocalStorage();
        //     if (chosenProduct) {
        //         this.$store.commit('setChosenProduct', chosenProduct);
        //         this.removeChosenProductFromLocalStorage();
        //     }
        //     let afterLoginRoute = this.$store.getters.getAfterLoginRoute;
        //     if (!afterLoginRoute) afterLoginRoute = this.getAfterLoginRouteFromLocalStorage();
        //     if (!afterLoginRoute) afterLoginRoute = 'Start Screen'; // if no stored route then go to start screen
        //     this.removeAfterLoginRouteFromLocalStorage();
        //     this.$store.commit('setAfterLoginRoute', null); // clear stored route
        //     this.$router.push({name: afterLoginRoute}); // navigate to next screen (route)
        // } else {
        //     const chosenProduct = this.$store.getters.getChosenProduct;
        //     if (chosenProduct && chosenProduct.name) this.setChosenProductInLocalStorage(chosenProduct);
        //     let afterLoginRoute = this.$store.getters.getAfterLoginRoute;
        //     if (!afterLoginRoute) afterLoginRoute = 'Start Screen';
        //     this.setAfterLoginRouteInLocalStorage(afterLoginRoute);
        //     this.performExternalLogin(this.LOGIN_REDIRECT_URL_LOGIN);
        // }
        const simpleProfile = await this.getSimpleUserProfileFromApi();
        if (simpleProfile) {
            this.$store.commit('setLoggedIn', true);
            this.$store.commit('setCustomerProfile', simpleProfile);
            const chosenProduct = this.getChosenProductFromLocalStorage();
            if (chosenProduct) {
                this.$store.commit('setChosenProduct', chosenProduct);
                this.removeChosenProductFromLocalStorage();
            }
            let afterLoginRoute = this.$store.getters.getAfterLoginRoute;
            if (!afterLoginRoute) afterLoginRoute = this.getAfterLoginRouteFromLocalStorage();
            if (!afterLoginRoute) afterLoginRoute = 'Start Screen'; // if no stored route then go to start screen
            this.removeAfterLoginRouteFromLocalStorage();
            this.$store.commit('setAfterLoginRoute', null); // clear stored route
            this.$router.push({name: afterLoginRoute}); // navigate to next screen (route)
        } else {
            const profile = await this.getUserProfileFromApi();
            if (profile) {
                this.$store.commit('setLoggedIn', true);
                this.$store.commit('setCustomerProfile', profile);
                const chosenProduct = this.getChosenProductFromLocalStorage();
                if (chosenProduct) {
                    this.$store.commit('setChosenProduct', chosenProduct);
                    this.removeChosenProductFromLocalStorage();
                }
                let afterLoginRoute = this.$store.getters.getAfterLoginRoute;
                if (!afterLoginRoute) afterLoginRoute = this.getAfterLoginRouteFromLocalStorage();
                if (!afterLoginRoute) afterLoginRoute = 'Start Screen'; // if no stored route then go to start screen
                this.removeAfterLoginRouteFromLocalStorage();
                this.$store.commit('setAfterLoginRoute', null); // clear stored route
                this.$router.push({name: afterLoginRoute}); // navigate to next screen (route)
            } else {
                const chosenProduct = this.$store.getters.getChosenProduct;
                if (chosenProduct && chosenProduct.name) this.setChosenProductInLocalStorage(chosenProduct);
                let afterLoginRoute = this.$store.getters.getAfterLoginRoute;
                if (!afterLoginRoute) afterLoginRoute = 'Start Screen';
                this.setAfterLoginRouteInLocalStorage(afterLoginRoute);
                this.performExternalLogin(this.LOGIN_REDIRECT_URL_LOGIN);
            }
        }
    },
    methods: {
    }
};
</script>
