<template>
    <footer
        class="footer"
        role="contentinfo">
        <div class="columns is-centered has-text-centered-mobile">
            <div class="column mb-3 is-narrow has-text-centered">
                <div class="pb-5">
                    <span class="fa-stack">
                        <i class="far fa-square fa-stack-2x" />
                        <i class="fas fa-arrow-up fa-stack-1x" />
                    </span>
                    <a href="#top">Back to top</a>
                </div>
                <router-link
                    class="is-not-decorated"
                    :to="{name:'Home'}">
                    <img
                        aria-label="PeBL Logo"
                        style="max-width: 100px;"
                        src="@/assets/PEBL-Logo-Color-small.png">
                </router-link>
            </div>
            <div class="column is-narrow">
                <p class="mb-3 has-text-weight-semibold">
                    Resources
                </p>
                <ul class="mb-6">
                    <li>
                        <router-link :to="{name:'Training'}">
                            Training
                        </router-link>
                    </li>
                    <li>
                        <a href="https://demos.pebl.pro/">Demos</a>
                    </li>
                    <!-- <li><a href="https://peblproject.org/">Open Source</a></li> -->
                    <li>
                        <router-link :to="{name:'FAQ'}">
                            FAQ
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="column is-narrow">
                <p class="mb-3 has-text-weight-semibold">
                    Company
                </p>
                <ul class="mb-6">
                    <li>
                        <router-link :to="{name:'About Us'}">
                            About Us
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link :to="{name:'Contact Us'}">
                            Contact
                        </router-link>
                    </li>-->
                    <li>
                        <router-link :to="{name:'Newsletter'}">
                            Newsletter
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'Blog List'}">
                            Blog
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link :to="{name:'News'}">
                            News/Updates
                        </router-link>
                    </li>-->
                </ul>
            </div>
            <div class="column is-one-quarter">
                <div>
                    <p class="mb-3 has-text-weight-semibold">
                        About PeBL Pro
                    </p>
                    <p>
                        PeBL Pro allows anyone who creates training and
                        educational content to build highly interactive,
                        social learning experiences, tracked by xAPI.
                    </p>
                </div>
                <div class="field my-4 is-grouped">
                    <p class="control">
                        <a
                            href="https://twitter.com/pebl_pro"
                            title="Twitter"
                            target="_blank"
                            class="button is-rounded is-outlined">
                            <i class="fab fa-twitter" />
                        </a>
                    </p>
                    <p class="control">
                        <a
                            href="https://www.linkedin.com/company/peblpro/"
                            title="LinkedIn"
                            target="_blank"
                            class="button is-rounded is-outlined">
                            <i class="fab fa-linkedin-in" />
                        </a>
                    </p>
                    <p class="control">
                        <a
                            href="https://www.facebook.com/peblpro/"
                            title="Facebook"
                            target="_blank"
                            class="button is-rounded is-outlined">
                            <i class="fab fa-facebook-f" />
                        </a>
                    </p>
                </div>
                <p>
                    <router-link :to="{name:'Privacy Policy'}">
                        Privacy Policy
                    </router-link>|
                    <router-link :to="{name:'Terms of Use'}">
                        Terms of Use
                    </router-link>
                </p>
            </div>
        </div>
        <p class="is-size-7 has-text-centered mt-3">
            © 2023
            <a href="https://eduworks.com/">Eduworks Corporation</a>. All rights reserved.
        </p>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    components: {}
};
</script>
