<template>
    <div role="main">
        <section class="hero is-white is-fullheight-with-navbar">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-vcentered is-centered">
                        <div
                            class="column is-6-tablet is-5-desktop is-4-widescreen"
                            data-aos="fade-down">
                            <h1 class="splash-title title is-1 mb-4 ">
                                An authoring tool created by instructional designers
                            </h1>
                            <!-- https://github.com/Orlandster/vue-typed-js -->
                            <vue-typed-js
                                :strings="['creating learning experiences', 'your learners','you']"
                                :start-delay="2000"
                                :back-delay="2000"
                                :fade-out="true"
                                @preStringTyped="nextSplashImage()">
                                <h2 class="subtitle mb-6-tablet mb-2-mobile">
                                    Designed for <span class="typing" />
                                </h2>
                            </vue-typed-js>
                        </div>
                        <div
                            data-aos="fade-right"
                            class="column is-5-tablet is-4-desktop">
                            <figure class="image is-square splash-image">
                                <transition name="fade">
                                    <img
                                        class="crisp"
                                        :src="getImgUrl(splashImage)"
                                        :key="splashImage"
                                        @load="onSplashLoaded"
                                        v-show="splashLoaded">
                                </transition>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- What's New -->
        <!-- Random feature | Next webinar | Latest blog -->
        <section
            id="whatsNew"
            class="hero is-light">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-4">
                            <div class="whats-new-container">
                                <p class="whats-new-title">
                                    Feature Spotlight
                                </p>
                                <!-- Pass an id num > 0 to lock in a specific feature
                                otherwise a random feature will be shown. -->
                                <feature-block :id="0" />
                                <p class="whats-new-more has-text-centered-tablet has-text-right-mobile mt-1">
                                    <router-link
                                        :to="{name:'Features'}"
                                        class="link">
                                        More features <i class="fa-fw fas fa-angle-right" />
                                    </router-link>
                                </p>
                            </div>
                        </div>
                        <div
                            v-if="showWebinars"
                            class="column is-4">
                            <div class="whats-new-container">
                                <p class="whats-new-title">
                                    Next Webinar
                                </p>
                                <webinars
                                    :home-screen="true"
                                    :grid-view="false"
                                    :count="1" />
                                <p class="whats-new-more has-text-centered-tablet has-text-right-mobile mt-1">
                                    <router-link
                                        :to="{name:'Training'}"
                                        class="link">
                                        More training <i class="fa-fw fas fa-angle-right" />
                                    </router-link>
                                </p>
                            </div>
                        </div>
                        <div class="column is-4">
                            <div class="whats-new-container">
                                <p class="whats-new-title">
                                    Latest Post
                                </p>
                                <blog-block
                                    class="p-5-tablet p-1-mobile"
                                    style="border-radius: 0.5rem;"
                                    :id="blogs[0].id" />
                                <p class="whats-new-more has-text-centered-tablet has-text-right-mobile mt-1">
                                    <router-link
                                        :to="{name:'Blog List'}"
                                        class="link">
                                        More posts <i class="fa-fw fas fa-angle-right" />
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Webinars from '../components/webinars.vue';
import BlogBlock from '../components/blogBlock.vue';
import FeatureBlock from '../components/featureBlock.vue';

export default {
    name: 'Home',
    components: {
        Webinars,
        BlogBlock,
        FeatureBlock
    },
    data: () => {
        return {
            name: 'Home',
            splashImage: null,
            splashNum: null,
            splashLoaded: false,
            blogCount: 2
        };
    },
    computed: {
        // strapiApiUrl() {
        //     return this.$store.state.strapiApiUrl;
        // },
        showWebinars() {
            return this.$store.state.webinars.length > 0;
        },
        blogs() {
            return this.$store.state.blogs;
        }
    },
    mounted() {
        if (this.$store.state.featureSpotlights.length < 1) this.$store.dispatch('getFeatureSpotlights');
        if (this.$store.state.blogs.length < 1) this.$store.dispatch('getBlogs');
        if (this.$store.state.webinars.length < 1) this.$store.dispatch('getWebinars');
    },
    created() {
        this.splashNum = Math.floor(Math.random() * 9) + 1;
    },
    methods: {
        onSplashLoaded: function() {
            this.splashLoaded = true;
        },
        nextSplashImage: function() {
            this.splashNum++;
            if (this.splashNum > 9) this.splashNum = 1;
            this.splashImage = 'splash-image-' + this.splashNum + '.png';
        },
        notEmpty(data) {
            if (!data) return false;
            return true;
        },
        getImgUrl(pic) {
            if (!pic) return;
            return require('../assets/' + pic);
        }
    }
};
</script>

<style scoped>
#Newslettersignup.section {
    padding-left: 0;
    padding-right: 0;
}

.fade-enter-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}
</style>
