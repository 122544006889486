<template>
    <div role="main">
        <section class="hero">
            <div class="hero-body">
                <div class="container content">
                    <!-- Termly Tracking Code -->
                    <!-- <div
                        name="termly-embed"
                        data-id="f4835aa3-ccdd-4383-8f29-fea41816a8b1"
                        data-type="iframe" />
                    <div id="termlyScriptContainer" /> -->

                    <p class="has-text-centered no-indent">
                        <strong><u>EDUWORKS CORPORATION</u></strong>
                    </p>
                    <p class="has-text-centered no-indent">
                        <strong><u>TERMS OF USE FOR THE PeBL PRO</u></strong><u>&trade; </u><strong><u>APPLICATION</u></strong>
                    </p>
                    <p class="no-indent">
                        <strong>THIS TERMS OF USE </strong>(&ldquo;<strong><u>Agreement</u></strong>&rdquo;) governs the use of the <strong>PeBL Pro</strong><strong>&trade; </strong><strong>Application</strong> (as defined below), which is owned by <strong>Eduworks Corporation, </strong>an Oregon corporation (&ldquo;<strong><u>Eduworks</u></strong>&rdquo; or &ldquo;<strong><u>We</u></strong>&rdquo; or &ldquo;<strong><u>Us</u></strong>&rdquo; or &ldquo;<strong><u>our</u></strong>&rdquo; ) by the Customer and all of its Customer Users (as such terms are defined herein).
                    </p>
                    <p class="no-indent">
                        <strong>EDUWORKS PROVIDES THIS AGREEMENT TO NOTIFY ALL CUSTOMERS AND ITS CUSTOMER USERS WHO ACCESS AND USE THIS PEBL PRO APPLICATION OF EDUWORKS&rsquo; TERMS AND CONDITIONS WHICH GOVERN ALL ACCESS AND USE OF THIS PEBL PRO APPLICATION. USE OF THIS PEBL PRO APPLICATION IS CONDITIONED ON ACCEPTANCE, WITHOUT MODIFICATION, OF THIS AGREEMENT BY THE CUSTOMER. </strong>
                    </p>
                    <p class="no-indent">
                        <strong>BY CONTINUING TO USE THIS PEBL PRO APPLICATION, EACH CUSTOMER AND CUSTOMER USER HEREBY AGREES THAT SUCH CUSTOMER AND ALL SUCH CUSTOMER USERS ARE HEREBY BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT AS OF THE DATE OF THE FIRST USE OF THIS PEBL PRO APPLICATION BY THE FIRST REPRESENTATIVE OF THE CUSTOMER (THE &ldquo;<u>EFFECTIVE DATE</u>&rdquo;). </strong>
                    </p>
                    <p class="no-indent">
                        <strong>NO CHANGES (ADDITIONS OR DELETIONS) BY YOU TO THIS AGREEMENT WILL BE ACCEPTED BY EDUWORKS.</strong>
                    </p>
                    <p class="no-indent">
                        <strong>THE CUSTOMER ALSO HEREBY REPRESENTS AND WARRANTS TO EDUWORKS THAT THE INDIVIDUAL THAT HAS CONSENTED TO THIS AGREEMENT ON BEHALF OF THE CUSTOMER IS AN AUTHORIZED AGENT OR REPRESENTATIVE OF THE CUSTOMER AND, THUS, SUCH INDIVIDUAL HAS ALL REQUISITE POWER AND AUTHORITY TO BIND THE CUSTOMER TO THIS AGREEMENT. </strong>
                    </p>
                    <p class="no-indent">
                        <strong>IF YOU DO <u>NOT</u> AGREE TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, THEN THE CUSTOMER (AND ALL OF ITS CUSTOMER USERS) SHOULD <u>NOT</u> USE THIS PEBL PRO APPLICATION. </strong>
                    </p>
                    <p class="no-indent">
                        <strong>THIS AGREEMENT IS A LEGALLY BINDING CONTRACT. EACH CUSTOMER SHOULD DOWNLOAD AND PRINT THIS AGREEMENT FOR ITS RECORDS</strong>
                    </p>

                    <p><strong>1. <u>Definitions</u></strong><strong>. </strong></p>
                    <p>In addition to any other terms defined in the Agreement, the following terms have the following meanings:</p>
                    <p>1.1 &ldquo;<strong><u>Access Right</u></strong>" has the meaning set forth in Section 2 herein.</p>
                    <p>1.2 &ldquo;<strong><u>Customer</u></strong>" means the named party that has entered into the Subscription for the PeBL Pro Application as contemplated by Section 2.1 herein in order to allow the Customer and the Customer&rsquo;s User to have the right to access and use the PeBL Pro Application pursuant to this Agreement.</p>
                    <p>1.3 &ldquo;<strong><u>Customer Content</u></strong>" means any e-learning material, including without limitation any Instructional Designers Content (as defined in Section 1.9 herein), that has been independently created by a User during his/her use of the PeBL Pro Application; provided however, and for purposes of clarity, Customer Content does not include any Eduworks Material or any derivatives thereof that may be created at any time by anyone.</p>
                    <p>1.4 &ldquo;<strong><u>Customer User&rsquo;s EULA </u></strong>&rdquo; has the meaning set forth in Section 4 herein.</p>
                    <p>1.5 &ldquo;<strong><u>Eduworks IP Assets</u></strong>&rdquo; has the meaning set forth in Section 6 herein.</p>
                    <p>1.6 &ldquo;<strong><u>Eduworks Material</u></strong>" has the meaning set forth in Section 6 herein.</p>
                    <p>1.7 &ldquo;<strong><u>Eduworks PeBL Pro Policies</u></strong>&rdquo; has the meaning set forth in Section 2 herein.</p>
                    <p>1.8 &ldquo;<strong><u>Eduworks Servers</u></strong>&rdquo; or &ldquo;<strong><u>Servers</u></strong>&rdquo; means any servers, other hardware, other computer systems or other software applications which are proprietary to, or leased or otherwise used by, Eduworks to support the PeBL Pro Application.</p>
                    <p>1.9 &ldquo;<strong><u>Customer User(s)</u></strong>&rdquo; has the meaning set forth in Section 4 herein.</p>
                    <p>1.10 &ldquo;<strong><u>PeBL Pro Application</u></strong>&rdquo; means Eduworks&rsquo; proprietary PeBL Pro&trade; application, which is a web-based authoring, teaching and learning tool which, among other features and functionalities, allows:</p>
                    <div class="ml-6 mb-4">
                        <p>(a) Users that are authors (&ldquo;<strong><u>Authors</u></strong>&rdquo;) who use the PeBL Pro Application to create and/or use e-book (EPUB) based e-learning experiences, , including but not limited to creating e-learning experiences that are interactive, adaptive, collaborative, and instrumented with xAPI tracking (collectively, the &ldquo;<strong><u>e-learning Material</u></strong>&rdquo;);</p>
                        <p>(b) Other Users (such as but not limited to learners) to gain access to, and use, the e-learning Material created by Authors; and</p>
                        <p>(c) Authors to set up a library in the PeBL Pro Application which hosts his/her content (&ldquo;<strong><u>Instructional Designers Content</u></strong>&rdquo;).</p>
                    </div><p>1.11 &ldquo;<strong><u>Personalized eBook for Learning Publicly-Available Content</u></strong>&ldquo; means any open-source or other publicly-available Personalized e-Book for Learning (which may also be referred to as PeBL) material, content or code that has been made publicly available by others and which can be utilized by Users on or as part of Eduworks&rsquo; proprietary PeBL Pro&trade; Application.</p>
                    <p>1.12 &ldquo;<strong><u>Personalized eBook for Learning Publicly-Available Content License(s)</u></strong>&rdquo; means the applicable third party license governing the use of a particular Personalized eBook for Learning Publicly-Available Content (as defined in Section 1.10 herein), including without limitation any open source license which may govern any such Personalized eBook for Learning Publicly-Available Content.</p>
                    <p>1.13 &ldquo;<strong><u>Pricing Plans</u></strong>&rdquo; has the meaning set forth in Section 3 herein.</p>
                    <p>1.14 &ldquo;<strong><u>Subscription</u></strong>&rdquo; or &ldquo;<strong>level of Subscription</strong>&rdquo; has the meaning set forth in Section 3 herein.</p>
                    <p>1.15 &ldquo;<strong><u>Subscription Fee</u></strong>&rdquo; has the meaning set forth in Section 3 herein.</p>
                    <p>1.16 &ldquo;<strong><u>Subscription Period</u></strong>&rdquo; has the meaning set forth in Section 3 herein.</p>
                    <p>1.17 &ldquo;<strong><u>User(s)</u></strong>&rdquo; &ldquo;<strong><u>you</u></strong>&rdquo; or &ldquo;<strong><u>your</u></strong>&rdquo; means, collectively: (i) the Customer; and (ii) all of its Customer Users.&nbsp;</p>

                    <p><strong>2. <u>Access Right to Use PeBL Pro Application; Conditions of Use</u></strong><strong>.</strong></p>
                    <p>2.1. Subject to Customer&rsquo;s (and its Customer Users&rsquo;) strict compliance with this Agreement, including but not limited to the Customer paying the full Subscription Fee as contemplated by Section 3 of this Agreement, Eduworks grants to Customer, only during the term of the Subscription Period, a personal, limited, non-exclusive, non-transferable, non-sublicensable, non-assignable, revocable right to allow only the Customer and its Customer Users the ability to access and use the PeBL Pro Application, only in the format that the PeBL Pro Application is then being made available by Eduworks, and only the purpose of using the specific features and functionalities of the PeBL Pro Application as made available by Eduworks, but for no other uses or purposes whatsoever (hereinafter the &ldquo;<strong><u>Access Right&rdquo;</u></strong>).</p>
                    <p>2.2 Each User hereby acknowledges and agrees that, in addition to any of the other terms and condition set forth in this Agreement, each User&rsquo;s Access Right to use the PeBL Pro Application is hereby explicitly conditioned and subject to the following:</p>

                    <div class="ml-6 mb-4">
                        <p>
                            (a) User&rsquo;s strict compliance with any and all additional rules, policies, procedures, or guidelines which may be adopted by Eduworks from time to time, or at any time, which govern the permissible conduct of all Users of the PeBL Pro Application and which impose any other conditions or limitation on the permissible ways that Users are allowed to use the PeBL Pro Application (hereinafter collectively referred to as the &ldquo;<strong><u>Eduworks PeBL Pro Policies</u></strong>&rdquo;); and
                        </p><p>
                            (b) To the degree any User&rsquo;s use of the PeBL Pro Application entails the use of any Personalized eBook for Learning Publicly-Available Content (as defined herein), such User&rsquo;s strict compliance with each applicable Personalized eBook for Learning Publicly-Available Content License (as defined herein) which may govern any such Personalized eBook for Learning Publicly-Available Content.
                        </p>
                    </div>
                    <p>
                        2.3 Each User hereby acknowledges and agrees that the foregoing Access Right is further subject to and conditioned as follows:
                    </p>
                    <div class="ml-6 mb-4">
                        <p>(a) Eduworks has the right, in its sole discretion, at any time or from time to time, to change, amend, modify, suspend, discontinue, cease, or otherwise terminate any or all of the features or functionalities of the PeBL Pro Application, in whole or any parts thereof, including without limitation discontinuing Eduworks&rsquo; support of the entire PeBL Pro Application, or any portions thereof (collectively, &ldquo;<strong><u>Change or Discontinue Support of the PeBL Pro Application</u></strong>&rdquo;);</p>
                        <p>(b) Each User acknowledges and agrees that, in the event Eduworks exercises it right under this Agreement to Change or Discontinue Support of the PeBL Pro Application in any way:</p>
                        <div class="ml-6">
                            <p>(i) The PeBL Pro Application, and/or any other part of the PeBL Pro Application, may not operate or function, in whole or in part; and</p>
                            <p>(ii) Eduworks shall not have any obligation or liability to the Customer or to any of its Customers Users (including, without limitation, not having an obligation to refund any portion of the Subscription Fee) in the event Eduworks does exercise it right to Change or Discontinue Support of the PeBL Pro Application in any way or at any time (or for any reason), including without limitation if such action by Eduworks renders all or any part of the PeBL Pro Application inoperative or non-functioning; and</p>
                            <p>(iii) If Eduworks exercises its right to Change or Discontinue Support of the PeBL Pro Application, including without limitation suspending, discontinuing, ceasing or terminating the PeBL Pro Application (in whole or in part) for any reason, and after Eduworks has given notice to Customer of its plans to take such action (the format of such notice to be determined by Eduworks), if the Customer and its Customer Users fail to transfer all Customer Content by the effective date of such action by Eduworks, then Eduworks is not responsible to provide access to any such Customer Content to the Customer or any Customer User after the effective date of such action by Eduworks.</p>
                        </div>
                    </div>
                    <p>2.4 In addition to any other conditions set forth in this Agreement, each User hereby agrees to the following additional provisions (and the following provisions are further conditions on the User&rsquo;s Access Right):</p>
                    <div class="ml-6 mb-4">
                        <p>(a) For clarity, and for the avoidance of doubt:</p>
                        <div class="ml-6 mb-4">
                            <p>(i) Each User is only permitted to use the PeBL Pro Application solely in accordance with this Agreement, including without limitation the Eduworks PeBL Pro Policies; and</p>
                            <p>(ii) Without limiting the generality of the foregoing, each User is explicitly prohibited from using the PeBL Pro Application for any purpose or in any way that is prohibited by this Agreement or by the Eduworks PeBL Pro Policies.</p>
                        </div>
                        <p>(b) Users are prohibited from using any data, information, material or content (including without limitation any Customer Data) if the foregoing (in Eduworks&rsquo; determination):</p>
                        <div class="ml-6 mb-4">
                            <p>(i) Is not owned by the User or if the User does not otherwise have the absolute right to use such data, information, material or content in association with the PeBL Pro Application.</p>
                            <p>(ii) Infringes on any patent, trademark, trade secret, copyright, right of publicity, or any other intellectual property or proprietary right of any party.</p>
                            <p>(iii) Is otherwise unlawful, libelous, defamatory, an invasive of privacy or of any publicity rights, harassing, threatening, abusive, inflammatory, obscene, or otherwise objectionable.</p>
                            <p>(iv) Would violate any other rights of any party, would constitute or encourage a criminal offense, or would otherwise violate or create liability under any laws, statutes, ordinances or regulations anywhere.</p>
                        </div>
                        <p>(c) Users shall not impersonate any other person or entity or otherwise misrepresent the User&rsquo;s affiliation with a person or entity;</p>
                        <p>(d) Users will not distribute or publish unsolicited promotions, advertising, or solicitations for any goods, services or money, including junk mail and junk e-mail;</p>
                        <p>(e) User will not use the PeBL Pro Application for any other illegal purpose or any fraudulent scheme or transaction; and</p>
                        <p>(f) Users are prohibited from violating or attempting to violate the security of the PeBL Pro Applications, any other part thereof, or of any Eduworks Servers, including without limiting being prohibited from doing any of the following:</p>
                        <div class="ml-6 mb-4">
                            <p>(i) accessing data not intended for such User or logging into a server or account which the User is not authorized to access;</p>
                            <p>(ii) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization;</p>
                            <p>(iii) attempting to interfere with service to any other User, host, or network;</p>
                            <p>(iv) attempting to obtain or obtain any data or other content through any means not intentionally made available or provided for by the PeBL Pro Application;</p>
                            <p>(v) take any other action that could damage, disable, overburden, or impair the PeBL Pro Application or any Eduworks Servers; or</p>
                            <p>(vi) otherwise violate or attempt to violate any security features, protocols, systems or network security associated with the PeBL Pro Application, any part thereof, or of any Eduworks Servers.</p>
                        </div>
                    </div>
                    <p>2.5 Each User hereby agrees that Eduworks has the right to investigate occurrences that may involve any violations of this Agreement, including without limitation any matters which could involve, and cooperate with, law enforcement authorities in prosecuting Users who are involved in any violations. In addition, Eduworks reserves the right to cooperate with any and all law enforcement agencies, including complying with requests for information or disclosures or any warrants, court orders or subpoenas (from any party) and disclosing to law enforcement agencies any information about any User and anything a User does with respect to the PeBL Pro Application. <strong>BY USER&rsquo;S USE OF THE PEBL PRO APPLICATION, THE USER EXPLICITLY AUTHORIZES EDUWORKS TO TAKE SUCH ACTION.</strong></p>
                    <p>2.6 If Eduworks becomes aware of any violations or breach of this Agreement by Customer or any of its Customer Users, Eduworks has the right (in addition to any other rights or remedies available to Eduworks by law, contract or equity) to take any one or more of the following actions, in its discretion: (a) suspend or terminate access to the PeBL Pro Application to the Customer and/or to any particular Customer User, including without limitation denying access to the Customer Data to the Customer and/or to any particular Customer User; (b) terminate a particular Customer User&rsquo;s right to use the PeBL Pro Application; and/or (c) terminate this entire Agreement in accordance with Section 8 of this Agreement.</p>
                    <p>2.7 All rights granted to the User under this Agreement, including without limitation the User&rsquo;s Access Right to use the PeBL Pro Application, will immediately terminate upon the expiration, cancellation or termination of this Agreement for any reason, including without limitation any breach by any User of this Section 2 of this Agreement.&nbsp;</p>

                    <p><strong>3. <u>Subscription Process; Subscription Fees; Subscription Period; Taxes</u></strong><strong>.</strong></p>

                    <p>3.1 For purposes of this Agreement, the term &ldquo;<strong><u>Pricing Plans</u></strong>&rdquo; means Eduworks&rsquo; then current published pricing and plan terms applicable to each respective level of subscription of the PeBL Pro Application (the &ldquo;<strong><u>Subscription</u></strong>&rdquo; or &ldquo;<strong><u>level of Subscription</u></strong>&rdquo;), including without limitation the following terms:&nbsp;</p>
                    <div class="ml-6 mb-4">
                        <p>(a) The amount of fees, including but not limited to subscription, licensing and/or maintenance fees, for each respective level of Subscription (collectively &ldquo;<strong><u>Subscription Fees</u></strong>&rdquo;), and all other terms and conditions governing the Subscription Fees, including but not limited to particular payment terms, any cancellation policy, any interest payments/service charges for late payments, any non-refund policies or any rebate policies; or</p>
                        <p>(b) The time period during which the Customer and its other Customer will have the Access Right to, or any other right to use, the PeBL Pro Application under this Agreement for each respective level of Subscription (&ldquo;<strong><u>Subscription Period</u></strong>&rdquo;), and any other terms and conditions governing the Subscription Period, including without limitation the conditions for any renewal of the Subscription Period; or</p>
                        <p>(c) Any other terms related to the features or level of service for, or restrictions regarding, each respective level of Subscription.</p>
                    </div>
                    <p>3.2 An authorized representative of the Customer must select, on behalf of the Customer, the initial level of Subscription and the level of Subscription for each renewal period, all to be in accordance with the Pricing Plans in effect at such time.</p>
                    <p>3.3 As a condition to Customer&rsquo;s (and all of its Customer User&rsquo;s use) of the PeBL Pro Application (including without limitation, any continued Access Rights to all Users), the Customer hereby agrees to pay Eduworks the full Subscription Fee pursuant to the Pricing Plans in effect at the start of the initial Subscription Period and at the start of each renewal of the Subscription Period.</p>
                    <p>3.4 Any Subscription Fees or any other amounts owed to Eduworks which are not paid when due shall be subject to any applicable interest payment and/or any other service charge as set forth in the Subscription Plan Terms. If Customer fails to pay any Subscription Fee or any other amounts owed to Eduworks after thirty (30) days of the applicable due date, Eduworks has the right, in its sole discretion, to terminate this Agreement and/or to initiate any other remedies at law or equity as set forth in Section 8 of this Agreement. If Eduworks initiates any legal proceedings to collect those amounts, Eduworks shall be entitled to recover its reasonable attorney fees and costs from Customer, including those incurred prior to and at arbitration, trial or other proceeding, and in any appeal.</p>
                    <p>3.5 Eduworks will publish its then current Pricing Plans, with the understanding that Eduworks shall determine, in its discretion, the appropriate means of such publication. EDUWORKS HAS THE RIGHT TO AMEND, FROM TIME TO TIME AND AT ANY TIME, ANY OF THE PROVISIONS IN IT&rsquo;S PRICING PLANS (INCLUDING WITHOUT LIMITATION ANY SUBSCRIPTION FEES AND SUBSCRIPTION PERIODS). THE CUSTOMER HEREBY ACKNOWLEDGES AND AGREES THAT IT IS THE CUSTOMER&rsquo;S OBLIGATION TO ACCESS AND REVIEW EDUWORKS&rsquo; THEN CURRENT PUBLISHED PRICING PLANS WHICH IS IN EFFECT AT THE TIME OF THE CUSTOMER&rsquo;S INITIAL SUBSCRIPTION PERIOD AND, IN THE EVENT THE CUSTOMER&rsquo;S SUBSCRIPTION IS RENEWED, IN EFFECT AT THE TIME OF EACH RENEWAL PERIOD. THE CUSTOMER FURTHER ACKNOWLEDGES AND AGREES THAT THE CUSTOMER IS AND WILL BE BOUND BY THE THEN-CURRENT PRICING PLANS (INCLUDING BUT NOT LIMITED TO THE THEN-CURRENT SUBSCRIPTION FEE) IN EFFECT AS OF THE FOLLOWING DATES:&nbsp;</p>
                    <div class="ml-6 mb-4">
                        <p>(A) THE EFFECTIVE DATE OF THE CUSTOMER&rsquo;S INITIAL SUBSCRIPTION PERIOD; AND</p>
                        <p>(B) IN THE EVENT THE CUSTOMER&rsquo;S SUBSCRIPTION IS RENEWED, THE EFFECTIVE DATE OF EACH RENEWAL OF ITS SUBSCRIPTION.</p>
                    </div>
                    <p>3.6 Customer will pay all sales, use, value added and other taxes imposed on any Subscription Fees paid to Eduworks under this Agreement.</p>

                    <p><strong>4. <u>Authorized Use of PeBL Pro Application; Right to Terminate Access Right</u></strong><strong>. </strong></p>

                    <p>4.1 Customer shall restrict use of the PeBL Pro Application solely to those employees or other authorized users of the PeBL Pro Application who satisfy the following conditions (hereinafter &ldquo;<strong><u>Customer User(s)</u></strong>&rdquo;): (a) the individual has been given an authentic and valid invitation by Customer to gain access to and then use the Customer&rsquo;s Subscription for the PeBL Pro Application; and (b) each such individual remains in compliance with all of the terms and conditions of this Agreement, including without limitation any Eduworks PeBL Pro Policies. Without limiting the foregoing or any other provision herein in any way, Eduworks hereby reserves the right, at any time, to require each individual Customer User to consent to Eduworks&rsquo; end user license agreement document (&ldquo;<strong><u>Customer User&rsquo;s EULA</u></strong>&rdquo;) before each such Customer User will be given an Access Right to use, or will be able to continue to have an Access Right to use, the PeBL Pro Application through the Customer&rsquo;s Subscription.</p>
                    <p>4.2 Customer is solely and exclusively responsible for all uses (or unauthorized uses or any other misuses) of the PeBL Pro Application by its Customer Users and for any and all violations or breaches of this Agreement by each of its Customer Users or, if applicable, any violations or breaches of a Customer User&rsquo;s EULA by any Customer User.</p>
                    <p>4.3 Customer is required to immediately notify Eduworks of each Customer User that Customer no longer is giving access to the PeBL Pro Application in order to allow Eduworks the ability to terminate the applicable Customer User&rsquo;s access to the PeBL Pro Application.</p>
                    <p>4.4 The Customer hereby represents and warrants to Eduworks that the individual that has initially consented to this Agreement on behalf of the Customer is an authorized agent or representative of the Customer and, thus, such individual has all requisite power and authority to bind the Customer to this Agreement.</p>

                    <p><strong>5. <u>Additional Responsibilities of Customer</u></strong><strong>. </strong>Customer shall be solely responsible for: (a) procuring, at its expense, the necessary environment at the Customer&rsquo;s location(s) to use the PeBL Pro Application, including, without limitation, all computer hardware, software and equipment, Internet access and telecommunications services (&ldquo;<strong><u>Systems</u></strong>&rdquo;); (b) keeping its user names and passwords secret and confidential, and, for any communications or transactions that are made, using the same; (c) changing its user names and passwords if it believes that the same has been stolen or might otherwise be misused; (d) ensuring that each Customer User is aware of the material terms of this Agreement and the Customer User&rsquo;s EULA and that no person who is not an authorized user be allowed access to the PeBL Pro Application; (e) maintaining recommended information security tools, technologies, fire walls, antivirus, malicious software removal and detection software, etc. and to implement and maintain appropriate administrative, physical and technical safeguards to data from unauthorized access, use or alteration; and (f) complying with all other laws, rules and regulations related to the Customer&rsquo;s use of its Systems.&nbsp;</p>
                    <p><strong>6. <u>Reservation of Rights</u></strong>.</p>

                    <p>6.1 For purposes of this Agreement, the term &ldquo;<strong><u>Eduworks Material</u></strong>" means any and all non-public data, material or other information that is created by and proprietary to Eduworks which Eduworks may make available to any Users via or through the PeBL Pro Application, including without limitation the following: (a) any of Eduworks&rsquo; created vocabulary; (b) any other questions, answers, quizzes, descriptions, analytics, teaching suggestions, samples, templates, or any other commentary created by Eduworks; or (c) any of Eduworks&rsquo; trademarks, service marks, names, logos or slogans, including without limitation Eduworks&rsquo; proprietary PeBL Pro&trade; mark. Each User further acknowledges and agrees that such User shall use the Eduworks Material only through or via the PeBL Pro Application in accordance with the terms and conditions of this Agreement, including without limitation in accordance with the terms and conditions of the Access Rights set forth in Section 2 herein.&nbsp;</p>
                    <p>6.2 Subject only to the limited Access Right granted to Customer pursuant to Section 2 above, all rights, title and interest (including without limitation all global intellectual property rights) in and to the PeBL Pro Application, including without limitation the underlying proprietary code or programs that operate the PeBL Pro Application, all Eduworks Material (as defined herein) provided by Eduworks through its proprietary PeBL Pro<strong>&trade; </strong>Application, Eduworks&rsquo; PeBL Pro&trade; trademark, and all goodwill associated therewith (hereinafter collectively referred to as the &ldquo;<strong><u>Eduworks IP Assets</u></strong>&rdquo;) shall at all times remain the sole and exclusive property of Eduworks. Customer and Customer Users shall not in any manner represent that they have acquired any rights in the Eduworks IP Assets beyond or in addition to the limited Access Right expressly granted by Eduworks to the Customer pursuant to only Section 2 above.&nbsp;</p>
                    <p>6.3 Each User hereby further agrees that: (a) any and all use of the Eduworks IP Assets by Customer or its Customer User as authorized by this Agreement shall inure to the sole benefit of Eduworks; and (b) Customer and Customer Users shall not challenge Eduworks&rsquo; exclusive rights to and ownership of the Eduworks IP Assets, nor take any action inconsistent with Eduworks&rsquo; exclusive rights to and ownership of the Eduworks IP Assets.</p>

                    <p><strong>7. <u>Confidentiality</u></strong>. Each User hereby agrees that all Eduworks IP Assets (as defined above), including without limitation the underlying PeBL Pro Application and other Eduworks Material, may contain valuable trade secrets and confidential information that is owned by Eduworks. Each User shall take all commercially reasonable precautions to prevent inadvertent disclosure any of the Eduworks IP Assets, including all know how and confidential information therein. Each User shall not disclose any part of the Eduworks IP Assets to anyone for any purpose, other than to its employees or Customer Users for the sole purpose of using the PeBL Pro Application only as permitted under this Agreement.</p>
                    <p><strong>8. <u>Term and Termination</u></strong>.</p>

                    <p>8.1 This Agreement shall commence as of the Effective Date noted above and shall continue until the earlier to occur of the following: (i) any termination of this Agreement under Section 8.2 below; or (ii) the Subscription Period otherwise expires or terminates (the &ldquo;<strong><u>Term</u></strong>).</p>
                    <p>8.2 In addition to any other right of termination set forth in this Agreement, this Agreement may be terminated by Eduworks:</p>
                    <div class="ml-6 mb-4">
                        <p>(a) for convenience upon giving thirty (30) days&rsquo; notice to Customer; or</p>
                        <p>(b) immediately if any one or more of the following occurs:</p>
                        <div class="ml-6 mb-4">
                            <p>(i) if the Customer or any one Customer User is in violation of, or has otherwise breached, this Agreement, including without limitation violating or breaching any Eduworks PeBL Pro Policies; or</p>
                            <p>(ii) if Eduworks has required an individual Customer User to consent to the Customer User&rsquo;s EULA pursuant to Section 4.1 herein, if any one Customer User is in violation of, or has breached, said Customer User&rsquo;s EULA.</p>
                        </div>
                    </div><p>8.3 Upon any type of termination, expiration or cancellation of this Agreement, all rights granted to Customer (and its Customer Users) hereunder, including without limitation the Access Right, shall immediately and automatically cease. Without limiting the foregoing in any way, upon the effective date of such termination, expiration or cancellation: (a) Customer and all of its Customer Users shall cease using the PeBL Pro Application and all other Eduworks IP Assets (as defined above) that was made available through the PeBL Pro Application; and (b) Customer and all Customer Users shall return to Eduworks or destroy (and certify the destruction thereof in writing), at Eduworks&rsquo; option, all copies of all of the Eduworks IP Assets. All provisions of this Agreement that by their terms, nature or context are intended by the parties to survive the termination, expiration or cancellation of this Agreement shall do so.</p>
                    <p>8.4 Upon any type of termination, expiration or cancellation of this Agreement, the following additional terms shall govern the Customer Content:</p>
                    <div class="ml-6 mb-4">
                        <p>(a) the provisions of Section 2.3 shall govern the Customer&rsquo;s access and all Customer Users&rsquo; access to Customer Content in all instances in which Eduworks has exercised its right to Change or Discontinue Support of the PeBL Pro Application as contemplated by such Section 2.3.</p>
                        <p>(b) if Eduworks has exercised its right of termination for convenience pursuant to Section 8.2(a) above, the Customer and each of its Customer Users shall have thirty (30) calendar days after the effective date given by Eduworks for such termination for convenience in which to download copies of their Customer Content, with the understanding and agreement that after such thirty (30) day period Eduworks shall not be responsible to provide access to any such Customer Content thereafter.</p>
                        <p>(c) if a Customer has not renewed its Subscription pursuant to this Agreement, then Customer and each of its Customer Users shall have thirty (30) calendar days after the expiration date of the Subscription Period in which to download copies of their Customer Content, with the understanding and agreement that after such thirty (30) day period Eduworks shall not be responsible to provide access to any such Customer Content thereafter.</p>
                        <p>(d) except as set forth in subsections (a), (b) and (c) of this Section 8.4, Eduworks shall not have any other obligation under this Agreement to provide access to any such Customer Content after any type of termination, expiration or cancellation of this Agreement.</p>
                    </div>

                    <p><strong>9. Links to Third Party Sites. </strong>As a convenience to Customer and Customer Users, Eduworks may now, or in the future, provide links to other sources of information or other internet web sites that are not owned by Eduworks and are not under Eduworks&rsquo; control (&ldquo;<strong><u>Third Party Sources</u></strong>&rdquo;). Eduworks does not control the Third Party Sources and is not responsible for the data, material, recommendations or any other information included in any Third Party Sources, including without limitation any subsequent links contained within a linked web site, or any changes or updates to a linked web site. Any reference from the Eduworks&rsquo; PeBL Pro Application to any entity, product, service or information provided through a Third Party Source does not constitute an endorsement or recommendation by Eduworks. No Third Party Source is authorized or permitted to make any representations or warranties on Eduworks&rsquo; behalf. Your use or visit to any Third Party Source, or Your act of providing any of your personal information to the owner of any Third Party Source constitutes Your automatic agreement that Your action using such Third Party Source (including without limitation any information provided through any such Third Party Source) shall be subject to and governed by the terms and conditions of such Third Party Source, including without limitation the privacy policies of any such Third Party Source.</p>
                    <p><strong>10. <u>Disclaimer of Warranty</u></strong><strong>. </strong></p>

                    <p class="no-indent">
                        CUSTOMER AND EACH CUSTOMER USER ACKNOWLEDGES AND AGREES THAT THE PEBL PRO APPLICATION (INCLUDING WITHOUT LIMITATION THE EDUWORKS MATERIAL OR ANY OTHER EDUWORKS IP ASSETS WHICH MAY BE PROVIDED THROUGH THE PEBL PRO APPLICATION) ARE PROVIDED TO CUSTOMER AND ITS CUSTOMER USERS ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS.
                    </p>
                    <p class="no-indent">
                        EDUWORKS MAKE NO, AND HEREBY EXPLICITLY DISCLAIMS ALL, REPRESENTATIONS, WARRANTIES AND/OR CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY AND ALL IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, ACCURACY OF INFORMATION CONTENT, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT.
                    </p>
                    <p class="no-indent">
                        CUSTOMER AND CUSTOMER USERS ASSUME THE ENTIRE RISK REGARDING THEIR USE OF, AND THE RESULTS AND PERFORMANCE ARISING OUT OF, THE ILS TRAINING PORTAL, INCLUDING WITHOUT LIMITATION THE SSI COURSE MATERIAL OR ANY OTHER SSI IP ASSETS WHICH MAY BE PROVIDED THROUGH THE ILS TRAINING PORTAL.
                    </p>
                    <p class="no-indent">
                        EDUWORKS DOES NOT REPRESENT OR WARRANT THAT THE PEBL PRO APPLICATION, THE EDUWORKS MATERIAL OR ANY OTHER EDUWORKS IP ASSETS WHICH MAY BE MADE AVAILABLE TO A USER WILL OPERATE OR FUNCTION UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVER MAKING THE PEBL PRO APPLICATION OR ANY OF THE OTHER FOREGOING IS FREE OF COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS
                    </p>
                    <p class="no-indent">
                        CUSTOMER AND ALL CUSTOMER USERS EXPLICITLY ACKNOWLEDGE AND AGREE THAT:
                    </p>
                    <div class="ml-6 mb-4">
                        <p>(A) EDUWORKS IS NOT RESPONSIBLE FOR ANY STATEMENT, OPINION, OR ADVICE MADE BY ANYONE (INCLUDING WITHOUT LIMITATION ANY CUSTOMER CONTENT OF, OR STATEMENT, OPINION OR ADVICE PROVIDED BY, ANY CUSTOMER OR CUSTOMER USER) OR ANY DAMAGES ARISING FROM THE FOREGOING, OTHER THAN AN AUTHORIZED STATEMENT MADE BY AN AUTHORIZED REPRESENTATIVE OF EDUWORKS HEREINAFTER AN &ldquo;<strong><u>EDUWORKS AUTHORIZED REPRESENTATIVE</u></strong>&rdquo;) SPEAKING ONLY IN HIS/HER OFFICIAL CAPACITY AS SUCH EDUWORKS AUTHORIZED REPRESENTATIVE; AND</p>
                        <p>(B) EDUWORKS DOES NOT ENDORSE OR VERIFY THE ACCURACY OR RELIABILITY OF ANY STATEMENT, OPINION, OR ADVICE MADE BY ANYONE (INCLUDING WITHOUT LIMITATION ANY CUSTOMER CONTENT OF, OR STATEMENT, OPINION OR ADVICE PROVIDED BY, ANY CUSTOMER OR CUSTOMER USER), OTHER THAN AN AUTHORIZED STATEMENT MADE BY AN EDUWORKS AUTHORIZED REPRESENTATIVE SPEAKING ONLY IN HIS/HER OFFICIAL CAPACITY AS SUCH EDUWORKS AUTHORIZED REPRESENTATIVE.</p>
                    </div>
                    <p><strong>11. <u> Limitation of Liability</u>.</strong> IN NO EVENT SHALL EDUWORKS, ITS AFFILIATES, OR ANY OF THEIR OFFICES, DIRECTORS, EMPLOYEES, AGENTS OR REPRESENTATIVES BE LIABLE TO ANY USER OR TO ANY THIRD PARTY FOR ANY DIRECT, SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM INCONVENIENCE, DOWNTIME LOSS, OR LOSS OF USE, DATA, REVENUE OR PROFITS, WHETHER OR NOT EDUWORKS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE PEBL PRO APPLICATION, ANY EDUWORKS MATERIAL, OR ANY OTHER EDUWORKS IP ASSETS,. NOTWITHSTANDING ANY PROVISION TO THE CONTRARY, THE PARTIES AGREE THAT EDUWORKS&rsquo; TOTAL AGGREGATE LIABILITY FOR ANY CLAIMS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, AND REGARDLESS OF THE FORM OR CAUSE OF ACTION OR THE ALLEGED BASIS OF THE CLAIM, WILL NOT EXCEED THE LESSER OF ONE THOUSAND US DOLLARS (US$1,000) OR THE TOTAL AMOUNT OF SUBSCRIPTION FEES ACTUALLY PAID BY THE CUSTOMER TO EDUWORKS IN THE PRECEDING TWELVE (12) MONTH PERIOD.</p>
                    <p><strong>12. <u>Indemnification</u>. </strong>Upon a request by Eduworks, the Customer and the applicable breaching Customer User each hereby agree to defend, indemnify, and hold Eduworks, its affiliates and their officers, directors, employees and agents and representatives harmless from all liabilities, claims, and expenses, including attorney&rsquo;s fees, that arise out of, or is related to each User&rsquo;s:</p>

                    <div class="ml-6 mb-4">
                        <p>(a) violation or breach of this Agreement, including without limitation any such User&rsquo;s breach or violation of the Eduworks PeBL Pro Policies;</p>
                        <p>(b) violation or breach of the Customer User&rsquo;s EULA; or</p>
                        <p>(c) any use, misuse or unpermitted use of the PeBL Pro Application, any Eduworks Material or any other Eduworks IP Assets; or</p>
                        <p>(d) any infringement by any User of any intellectual property or other rights of any person or entity.</p>
                    </div>
                    <p class="no-indent">
                        Eduworks reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by any User under this provision, in which event the User will cooperate with Eduworks in that action.
                    </p>

                    <p><strong>13. <u> Change in the Terms of this Agreement</u>. </strong>ONCE THE FIRST CUSTOMER USER OF THE CUSTOMER BEGINS TO USE THIS PEBL PRO APPLICATION, EDUWORKS MAY MODIFY THIS AGREEMENT AT ANY TIME AND FROM TIME TO TIME (&ldquo;<strong><u>CHANGES</u></strong>&rdquo;), AFTER GIVING REASONABLE NOTICE TO THE CUSTOMER. CUSTOMER AND CUSTOMER USERS HEREBY AGREE THAT SUCH REASONABLE NOTICE MAY INCLUDE, BUT IS NOT LIMITED TO, A GENERAL NOTICE ON THE EDUWORKS PEBL PRO APPLICATION OR ON ANY EDUWORKS SITE. ONCE EDUWORKS PROVIDES SUCH NOTICE OF SAID CHANGE, THE CUSTOMER AND EACH OF ITS CUSTOMER USERS AUTOMATICALLY CONSENT THEREAFTER BE BOUND BY THE VERSION OF THIS AGREEMENT THAT IS IN EFFECT THE NEXT TIME THE FIRST CUSTOMER USER OF THE CUSTOMER VISITS THE EDUWORKS PEBL PRO APPLICATION. ANY USE OF THE PEBL PRO APPLICATION THEREAFTER SHALL BE DEEMED TO CONSTITUTE ACCEPTANCE BY CUSTOMER AND ALL OF ITS CUSTOMER USERS OF ALL SUCH CHANGES.</p>
                    <p><strong>14. <u> Certain Permitted Uses of Customer Content by Eduworks</u></strong>. Each User hereby agrees that Eduworks has, and each such User hereby explicitly grants to Eduworks, a non-exclusive, worldwide, perpetual, assignable, sub-licensable, transferable, fully paid up right and license to use any Customer Content in the normal course of Eduworks&rsquo; business, including without limitation for the following purposes: (i) for statistical analysis, industry trend analysis, and/or evaluating the efficiency of the features and functionalities of the PeBL Application or any other applications developed by Eduworks; (ii) for any marketing, advertising or other methods of promoting (in digital, print or in any other media) the PeBL Application, any other products or services of Eduworks; and/or (iii) for any other purposes that support and promote Eduworks&rsquo; overall business operations (collectively, the &ldquo;<strong><u>Permitted Use(s)</u></strong>&rdquo;). When using the Customer Content for any of the Permitted Uses, Eduworks shall normally use such Customer Content in a de-identified format; provided, however, Customer Content does not have to be de-identified in those instances in which Eduworks determines that use in a de-identified format is not reasonable in order to carry out the applicable Permitted Use.</p>
                    <p><strong>15. <u>General Provisions</u></strong></p>

                    <p><strong>15.1 <u>Governing Law</u>. </strong>The laws of the State of Oregon, USA shall govern this Agreement for all purposes, without regard to the conflicts of laws principles thereof or the United Nations Convention on the International Sales of Goods. Each User agrees and hereby submits to the exclusive personal jurisdiction and venue by any court of competent jurisdiction within the State of Oregon with respect to such matters.</p>
                    <p><strong>15.2 <u>Remedies</u></strong>. Each User acknowledges and agrees that monetary damages may not be a sufficient remedy for unauthorized use of the PeBL Pro Application, any Eduworks Material, or any other Eduworks IP Assets, and therefore each User hereby agrees that Eduworks shall be entitled, without waiving any other rights or remedies, to such injunctive or equitable relief as may be deemed proper by a court or arbitration panel of competent jurisdiction without necessity of posting a bond and without having to plead and prove lack of an adequate remedy at law.</p>
                    <p><strong>15.3 <u>Attorney Fees</u></strong>. If any suit or action is filed by any party to enforce this Agreement or otherwise with respect to the subject matter of this Agreement, the prevailing party shall be entitled to recover reasonable attorney fees incurred in preparation or in prosecution or defense of such suit or action as fixed by the trial court and, if any appeal is taken from the decision of the trial court, reasonable attorney fees as fixed by the appellate court.</p>
                    <p><strong>15.4 <u>Notices</u>. </strong>Notices to Eduworks must be sent to the Company at <a href="mailto:support@pebl.pro?subject=Terms%20of%20Use%20Notice">support@pebl.pro</a>. Except as set forth in Section 13 of this Agreement (&ldquo;Change in the Terms of this Agreement&rdquo;), notices to a User may be sent by Eduworks to the email address or any other contact address that the User may have supplied at any time to Eduworks. In addition, Eduworks may broadcast notices or messages through the PeBL Pro Application or through any Eduworks website to inform each User of changes to the PeBL Pro Application or this Agreement or other matters of importance, and such broadcasts shall constitute notice by Eduworks to each User at the time of sending. Regardless of the method of sending the notice, all notices send to User by Eduworks shall be deemed to be given and deemed to be effective as of the date sent or posted by Eduworks.</p>
                    <p><strong>15.5 <u>Binding Effect; No Assignment by Users; Permissible Assignment by Eduworks</u>.</strong> This Agreement shall be binding upon and inure to the benefit of each party&rsquo;s respective successors and lawful assigns; <em>provided, however</em>, that Users may not assign this Agreement, in whole or in part. Any purported assignment by a User in violation of this Section shall be void. Eduworks shall have the right to assign this Agreement, or any part of it, in its sole discretion to any party, and all covenants and agreements hereunder shall inure to the benefit of and be enforceable by such successors and assigns.</p>
                    <p><strong>15.6 <u>Entire Agreement</u>. </strong>This Agreement constitutes the entire agreement and understanding between the parties concerning the subject matter of this Agreement and supersedes all prior agreements and understandings of the parties with respect to that subject matter. Without limiting Eduworks rights in Section 13 of this Agreement (&ldquo;Change in the Terms of this Agreement&rdquo;), each user agrees that this Agreement may not be altered, supplemented, or amended by any User without the prior written consent of Eduworks.</p>
                    <p><strong>15.7 <u>Enforcemen</u>t</strong>. None of the provisions of this Agreement shall be deemed to have been waived by any act or acquiescence on the part of Eduworks, its agents, or employees, except upon an instrument in writing signed by an authorized employee of Eduworks. No waiver of any provision of this Agreement shall constitute a waiver of any other provision(s) or of the same provision on another occasion. If any part of this Agreement is determined to be invalid or unenforceable pursuant to applicable law, then the remainder of the Agreement shall continue in effect.</p>
                    <p><strong>15.8 <u>Force Majeure</u></strong>. The Company shall not be liable for any delay or failure in performance, nor shall the Company be deemed to be in default of any provision of this Agreement, due to any Force Majeure, which shall include without limitation acts of God, disease, earthquake, weather conditions, labor disputes, changes in law, regulation or government policy, terrorist acts, riots, war, fire, epidemics, acts or omissions of vendors or suppliers, equipment failures, transportation difficulties, malicious or criminal acts of third parties, or other occurrences which are beyond the Company&rsquo;s reasonable control.</p>
                    <p><strong>15.9 <u>Contact Information</u>. </strong>If you have any questions about this Agreement, or about the PeBL Pro Application, please email Eduworks Corporation at <a href="mailto:support@pebl.pro?subject=PeBL%20Pro%20Inquiry">info@eduworks.com</a>.</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'TermsOfUse',
    data: () => {
        return {
            name: 'TermsOfUse'
        };
    },
    metaInfo: {
        title: 'Terms of Use'
    },
    mounted: function() {
        // const tscDiv = document.getElementById('termlyScriptContainer');
        // const tscScript = document.createElement('script');
        // tscScript.id = 'termlyScript';
        // tscScript.type = 'text/javascript';
        // tscDiv.appendChild(tscScript);
        // const js = document.createElement('script');
        // js.id = 'termly-jssdk';
        // js.src = 'https://app.termly.io/embed-policy.min.js';
        // document.head.appendChild(js);
    }
};
</script>

<style scoped>
p {
    margin-left: 1.75rem;
    text-indent: -1.75rem;
}
p.no-indent {
    margin-left: 0;
    text-indent: 0;
}
</style>
