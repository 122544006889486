/* eslint-disable */

import moment from 'moment';

export const appUtil = {
    name: 'appUtil',
    data: () => ({
        SUBDOMAIN_MIN: 3,
        SUBDOMAIN_MAX: 60
    }),
    methods: {
        utilDateTimeStringFromMilliseconds(ms) {
            let d = new Date(ms);
            return (moment(d)).format('MMMM Do YYYY, h:mm:ss a');
        },
        utilDateStringFromMilliseconds(ms) {
            let d = new Date(ms);
            return (moment(d)).format('MMMM Do YYYY');
        },
        isValidSubdomain(subdomain) {
            if (!subdomain || subdomain.trim().length === 0 || subdomain.trim().length < this.SUBDOMAIN_MIN || subdomain.trim().length > this.SUBDOMAIN_MAX) return false;
            if (subdomain.startsWith('-')) return false;
            if (subdomain.indexOf('.') > -1) return false;
            const re = /^([a-zA-Z0-9][a-zA-Z0-9]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-]*[[a-zA-Z0-9]+$/igm;
            if (re.test(subdomain)) return true;
            return false;
        },
        isValidWhitelistEntry(wlEntry) {
            if (!wlEntry || wlEntry.trim().length === 0) return false;
            if (wlEntry.startsWith('-')) return false;
            const re = /^([a-zA-Z0-9][a-zA-Z0-9]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-]*[[a-zA-Z0-9]+$/igm;
            if (re.test(wlEntry)) return true;
            return false;
        },
        isValidEmail(email) {
            if (!email || email.trim().length === 0) return false;
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        isValidUrl(url) {
            if (!url || url.trim().length === 0) return false;
            const re = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
            return re.test(String(url).toLowerCase());
        },
        getDuplicateItems(itemList) {
            let nonUniqueDupes = itemList.filter((item, index) => itemList.indexOf(item) != index);
            return Array.from(new Set(nonUniqueDupes));
        },
    },
    computed: {
    }
};


