import { render, staticRenderFns } from "./blogTemplate.vue?vue&type=template&id=84a20cea&"
import script from "./blogTemplate.vue?vue&type=script&lang=js&"
export * from "./blogTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports